import { useMutation, useQuery } from "@apollo/react-hooks";
import { createMuiTheme, Typography } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@material-ui/lab/Alert";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ClearIcon from "@material-ui/icons/Clear";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import PublishIcon from "@material-ui/icons/Publish";
import { gql } from "apollo-boost";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { FormattedMessage, useIntl } from "react-intl";
import NumberFormat from "react-number-format";
import queryPublicAccountId from "../api/queryPublicAccountId";
import defaultMessage from "../components/Message";

import { getURLParameter } from "../utils";
import styles from "./General.module.css";

const _ = require("lodash");

const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

export const theme = createMuiTheme({
  typography: {
    fontFamily: "Homemade Apple, cursive",
  },
});

const config = require("../config");
const apiURL = (process.env.REACT_APP_DCR_API_BASE_SERVER_URL || "https://api.dev.dcrportal.com") + "/v1/graphql";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

function ApplicantsTaskPage(props) {
  const lang = useIntl();

  const UPLOAD_FILE = `
    mutation($input: UpsertDocumentInput!, $file: Upload) {
      upsertDocument(input: $input, file: $file) 
    }
  `;

  const QUERY_TASK = gql`
    query ($taskId: ID!) {
      publicTask(taskId: $taskId) {
        taskId
        vendorOpportunityId
        regarding
        status
        priority
        createdBy
        createdDateTime
        assignedByUser
        assignedByAccount
        assignedToUser
        assignedToAccount
        assignedToAccountId
        lastReminderSent
        nextReminder
        taskItems {
          taskItemId
          type
          docType
          taskAssignorNotes
          taskAssigneeNotes
          documents {
            docName
            documentId
          }
        }
        vendorOpportunity {
          equipmentDescription
          dynamicsVendorOpportunityId
          salesRepresentative {
            firstName
            lastName
            dynamicsContactId
            fullName
            title
          }
        }
      }
    }
  `;

  const UPDATE_TASK = gql`
    mutation ($taskId: ID!, $status: String!) {
      updateTaskStatus(taskId: $taskId, status: $status)
    }
  `;
  const REMOVE_DOCUMENT_TASK_ITEM = gql`
    mutation RemoveTaskItemDocument($documentId: ID!, $taskItemId: ID!) {
      removeTaskItemDocument(documentId: $documentId, taskItemId: $taskItemId)
    }
  `;
  const REMOVE_ALL_DOCUMENT_TASK_ITEM = gql`
    mutation RemoveAllTaskItemDocument($taskItemId: ID!) {
      removeAllTaskItemDocument(taskItemId: $taskItemId)
    }
  `;
  const [logo, setLogo] = useState();
  const task = getURLParameter("taskId");
  const [fileList, setFileList] = useState([]);

  const fields = {};
  const setFields = {};
  const [taskList, setTaskList] = useState();

  const [assignedByUser, setAssignedByUser] = useState("");
  const [assignedToUser, setAssignedToUser] = useState("");
  const [buttonDisplayPartialSubmissionLabel, setButtonDisplayPartialSubmissionLabel] = useState(false);
  const [buttonDisplayCompleteSubmissionLabel, setButtonDisplayCompleteSubmissionLabel] = useState(false);
  const [submitIsDisabled, setSubmitIsDisabled] = useState(true);
  const [taskCompleted, setTaskCompleted] = useState(false);

  const [vendorGUID, setVendorGUID] = useState();
  const [vendorObj, setVendorObj] = useState({});
  const [loadingScreen, setLoadingScreen] = useState(false);

  const useStyles = makeStyles(theme => ({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflowY: "auto",
    },
    highlightDocType: {
      fontWeight: "bolder",
      textTransform: "capitalize",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    uploadMoreLink: {
      color: "blue",
      textDecoration: "underline",
      display: "block",
      textAlign: "center",
      cursor: "pointer",
      margin: "5px 0 0 0",
    },
    submitLabelDisclaimer: {
      color: "green",
    },
    fileNameColumn: {
      minWidth: "150px",
    },
    fileCheckIconBox: {
      top: "5px",
      position: "relative",
    },
    removeFabLabel: {
      fontSize: 10,
    },
  }));

  const {
    data: taskData,
    loading,
    refetch,
  } = useQuery(QUERY_TASK, {
    variables: {
      taskId: task,
    },
    fetchPolicy: "network-only",
    onCompleted: data => {
      try {
        window.waitForGlobal("_mfq", function () {
          const process = () => {
            try {
              window._mfq.push(["setVariable", "ASSIGNED_BY_USER", _.get(taskData, "publicTask[0].assignedByUser", "")]);
              window._mfq.push(["setVariable", "ASSIGNED_TO_USER", _.get(taskData, "publicTask[0].assignedToUser", "")]);
              window._mfq.push(["setVariable", "TASK_REGARDING", _.get(taskData, "publicTask[0].regarding", "")]);
              window._mfq.push(["setVariable", "STATUS", _.get(taskData, "publicTask[0].status", "")]);
              window._mfq.push(["setVariable", "VENDOR_GUID", _.get(taskData, "publicTask[0].assignedToAccountId", "")]);
              window._mfq.push(["setVariable", "VENDOR_GUID", _.get(taskData, "publicTask[0].assignedToAccountId", "")]);
              window._mfq.push(["setVariable", "ASSIGNED_TO_ACCOUNT_NAME", _.get(taskData, "publicTask[0].assignedToAccount", "")]);
              window._mfq.push(["setVariable", "ASSIGNED_BY_ACCOUNT_NAME", _.get(taskData, "publicTask[0].assignedByAccount", "")]);
              window._mfq.push(["setVariable", "CREATED_BY", _.get(taskData, "publicTask[0].createdBy", "")]);
              console.log("Data attached to mouseflow");
            } catch (error) {}
          };

          window.setTimeout(process, 200);
        });
      } catch (error) {
        console.log(error);
      }
    },
  });

  const [updateTask, { data: updateTaskResponseData, loading: loadingUpdateTaskResponse }] = useMutation(UPDATE_TASK);
  const [removeDocumentTaskItem, { data: removedDocumentTaskItem }] = useMutation(REMOVE_DOCUMENT_TASK_ITEM);
  const [removeAllDocumentTaskItem, { data: removedAllDocumentTaskItem }] = useMutation(REMOVE_ALL_DOCUMENT_TASK_ITEM);

  useEffect(() => {
    const taskId = _.get(taskList, "publicTask[0].taskId", false);
    const taskStatus = _.get(taskList, "publicTask[0].status", false);
    setTaskCompleted(false);
    if (taskStatus === "complete") {
      setTaskCompleted(true);
      return;
    }

    let status = "in_progress";

    if (taskId) {
      if (buttonDisplayCompleteSubmissionLabel) {
        status = "complete";
      }

      if (taskStatus === status) {
        return;
      }

      updateTask({
        variables: {
          taskId,
          status,
        },
      });
      console.log(`INFO: task has been marked as ${status}`);
    }
  }, [taskList, buttonDisplayCompleteSubmissionLabel]);

  useEffect(() => {
    setTaskList(taskData);
    setButtonDisplayPartialSubmissionLabel(false);
    setButtonDisplayCompleteSubmissionLabel(false);
    setSubmitIsDisabled(true);
    if (_.get(taskData, "publicTask[0].taskId", false)) {
      setAssignedByUser(taskData.publicTask[0].assignedByUser);
      setAssignedToUser(taskData.publicTask[0].assignedToUser);
      setPage(taskData.publicTask[0].status);
      setVendorGUID(taskData.publicTask[0].assignedToAccountId);
      let fileListByDocuments = [];
      _.get(taskData, "publicTask[0].taskItems", []).map(taskItem => {
        if (_.size(taskItem.documents)) {
          setButtonDisplayPartialSubmissionLabel(true);
          setSubmitIsDisabled(false);
        }
        fileListByDocuments.push({
          target: taskItem.documents.map(doc => {
            return { name: doc.docName, id: doc.documentId };
          }),
          taskItemId: taskItem.taskItemId,
        });
      });

      setFileList(fileListByDocuments);
      queryPublicAccountId(taskData.publicTask[0].assignedToAccountId.toLowerCase()).then(response => {
        if (_.get(response, "data.data.publicAccount.vendorProfile.styles")) {
          const vendorObj = {
            ...response.data.data.publicAccount.vendorProfile,
            accountName: response.data.data.publicAccount.name,
            color: response.data.data.publicAccount.vendorProfile.styles.color,
            secColor: response.data.data.publicAccount.vendorProfile.styles.secColor
              ? response.data.data.publicAccount.vendorProfile.styles.secColor
              : response.data.data.publicAccount.vendorProfile.styles.color,
            textColor: response.data.data.publicAccount.vendorProfile.styles.secColor ? response.data.data.publicAccount.vendorProfile.styles.secColor : "	#ffffff",
          };
          setVendorObj(vendorObj);
          setLoadingScreen(false);
          setLogo(response.data.data.publicAccount.vendorProfile.logo);
        } else {
          const vendorObj = {
            color: "#1473e6",
            secColor: "#1473e6",
            textColor: "#ffffff",
          };
          setVendorObj(vendorObj);
          setLoadingScreen(false);
        }
      });

      const taskCanBeCompleted = _.every(_.get(taskData, "publicTask[0].taskItems", []), ti => {
        return _.size(ti.documents) > 0;
      });

      setButtonDisplayCompleteSubmissionLabel(taskCanBeCompleted || taskCompleted);
    }
  }, [taskData]);

  [fields.file, setFields.file] = useState({
    value: null,
    validations: [{ type: "required" }],
  });
  [fields.logo, setFields.logo] = useState({ value: null });
  const [submitting, setSubmitting] = useState(false);
  const [page, setPage] = useState();

  const classes = useStyles();

  function uploadButtonHandler(id) {
    const file = document.getElementById(id);
    file.value = "";
    file.click();
  }
  const uploadFiles = async (targets, taskItemId) => {
    try {
      const taskItem = _.find(_.get(taskList, "publicTask[0].taskItems", null), { taskItemId });
      const vendorOpportunityId = _.get(taskList, "publicTask[0].vendorOpportunityId", null);
      const dynamicsVendorOpportunityId = _.get(taskList, "publicTask[0].vendorOpportunity.dynamicsVendorOpportunityId", null);
      const fileType = taskItem.docType;
      await asyncForEach(targets, async file => {
        const formData = new FormData();
        formData.append(
          "operations",
          JSON.stringify({
            query: UPLOAD_FILE,
            variables: {
              input: {
                vendorOpportunityId,
                source: "Task Documents Page",
                createdBy: "Applicant",
                docDescription: assignedToUser,
                docType: fileType,
                docName: fileType,
                dynamicsVendorOpportunityId,
                taskItemId,
              },
              file,
            },
          })
        );
        if (file) {
          formData.append(
            "map",
            JSON.stringify({
              0: ["variables.file"],
            })
          );
          formData.append("0", file);
        }

        const response = await fetch(apiURL, {
          method: "POST",
          body: formData,
        });
        const documentId = _.get(await response.json(), "data.upsertDocument.document");
      });
    } catch (error) {
      window.NREUM.noticeError(error);
    }
  };
  const uploadFileChanged = async event => {
    setLoadingScreen(true);
    const taskItemId = _.get(event, "target.id", null);
    const target = _.get(event, "target.files", null);
    await uploadFiles(target, taskItemId);
    refetch();
    setLoadingScreen(false);
  };

  const handleReplaceFromFileList = async (taskItemId, documentId) => {
    setLoadingScreen(true);
    //First upload the new document
    await uploadButtonHandler(taskItemId);

    const result = await removeDocumentTaskItem({
      variables: {
        taskItemId,
        documentId,
      },
    });

    refetch();
    setLoadingScreen(false);
  };
  const handleRemoveAll = async taskItemId => {
    setLoadingScreen(true);
    const result = await removeAllDocumentTaskItem({
      variables: {
        taskItemId,
      },
    });

    refetch();
    setLoadingScreen(false);
  };

  if (!_.get(taskList, "publicTask[0].taskId", false)) {
    return <p>No task found with the provided URL</p>;
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={loadingScreen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <>
        {logo ? (
          <div className={styles.vendorLogoWrapper}>
            <img
              src={logo && logo.includes("/") ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${logo}` : `https://credit-app-images.s3-us-west-2.amazonaws.com/${logo}`}
              alt="Vendor Logo"
            />
          </div>
        ) : (
          ""
        )}
        <>
          <Grid container spacing={4} alignItems="center" alignContent="center">
            <Grid item xl={12} lg={12} md={12} sm={12}>
              <hr />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12}>
              <Typography variant="h6">
                Hi <b>{assignedToUser}</b>, <b>{assignedByUser}</b> required the following items:
              </Typography>
            </Grid>
            {_.get(taskList, "publicTask[0].taskItems", []).map((taskItem, index) => (
              <Grid item xl={12} lg={12} md={12} sm={12} key={`itemList${index}`}>
                <Grid container spacing={3}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="h6">
                      {index + 1}) <span className={classes.highlightDocType}>{taskItem.docType}</span>
                    </Typography>
                  </Grid>
                  {taskItem.taskAssignorNotes ? (
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <u>Assignor Notes</u>: {taskItem.taskAssignorNotes}
                    </Grid>
                  ) : null}
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Grid container spacing={3}>
                      <input id={`${taskItem.taskItemId}`} multiple type="file" style={{ display: "none" }} onChange={uploadFileChanged} />
                      <Grid item lg={4} md={5} sm={5} xs={5}>
                        <Grid container spacing={3}>
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Tooltip title="You can select multiple files" arrow>
                              <Button
                                startIcon={<CloudUploadIcon />}
                                className="formBtn"
                                color="primary"
                                fullWidth
                                variant="outlined"
                                disabled={!_.isEmpty(_.get(_.find(fileList, { taskItemId: taskItem.taskItemId }), "target", []))}
                                onClick={e => {
                                  e.preventDefault();
                                  uploadButtonHandler(taskItem.taskItemId);
                                }}>
                                UPLOAD
                              </Button>
                            </Tooltip>
                            {_.size(_.get(_.find(fileList, { taskItemId: taskItem.taskItemId }), "target", [])) ? (
                              <span
                                onClick={e => {
                                  e.preventDefault();
                                  uploadButtonHandler(taskItem.taskItemId);
                                }}
                                className={classes.uploadMoreLink}>
                                Upload more
                              </span>
                            ) : null}
                          </Grid>
                          {/* 
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            {!_.isEmpty(_.get(_.find(fileList, { taskItemId: taskItem.taskItemId }), "target", [])) ? (
                              <Button
                                onClick={() => {
                                  handleRemoveAll(taskItem.taskItemId);
                                }}
                                variant="outlined"
                                color="secondary"
                                fullWidth
                                size="small"
                                startIcon={<DeleteForeverIcon />}>
                                REMOVE ALL FILES ({_.size(_.get(_.find(fileList, { taskItemId: taskItem.taskItemId }), "target", []))})
                              </Button>
                            ) : null}
                          </Grid> */}
                        </Grid>
                      </Grid>
                      <Grid item lg={8} md={7} sm={7} xs={7}>
                        {_.get(_.find(fileList, { taskItemId: taskItem.taskItemId }), "target", false) ? (
                          <Grid container spacing={1}>
                            {Object.values(_.find(fileList, { taskItemId: taskItem.taskItemId })["target"]).map((fileObject, idx) => (
                              <Grid item lg={12} md={12} sm={12} xs={12} key={`${taskItem.taskItemId}_fileManager_${idx}`}>
                                <Grid container spacing={1}>
                                  <Grid item className={classes.fileNameColumn}>
                                    <Grid container alignItems="flex-end">
                                      <Grid item>
                                        <CheckCircleIcon fontSize="small" color={"primary"} className={classes.fileCheckIconBox} />
                                      </Grid>
                                      <Grid item> {fileObject.name}</Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item>
                                    <Fab
                                      color="secondary"
                                      className={classes.removeFabLabel}
                                      variant="extended"
                                      size="small"
                                      onClick={() => handleReplaceFromFileList(taskItem.taskItemId, fileObject.id)}>
                                      <RemoveCircleIcon fontSize="small" />
                                      Replace
                                    </Fab>
                                  </Grid>
                                </Grid>
                              </Grid>
                            ))}
                            <hr />
                          </Grid>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <br />
              </Grid>
            ))}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              {buttonDisplayCompleteSubmissionLabel || taskCompleted ? (
                <Alert severity="success">All set! You have completed all tasks!</Alert>
              ) : buttonDisplayPartialSubmissionLabel ? (
                <Alert severity="info">One or more documents have been uploaded</Alert>
              ) : null}
            </Grid>
          </Grid>
        </>
      </>
    </>
  );
}
export default ApplicantsTaskPage;

import axios from "axios";
import { apiURL } from "../config";

const queryPartner = partnerId => {
  return axios.post(apiURL, {
    operationName: null,
    variables: {},
    query: `{
  partner(dynamicsId: "${partnerId}") {
    partnerProfileId
    partnerDynamicsProfileId
    partnerAccountId
    partnerAccountDynamicsId
    parentAccountId
    parentPartnerDynamicsId
    name
    partnerLogo
    psChannel
    styles
  }
}
`,
  });
};

export default queryPartner;

import React, { useState, useEffect, createContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import { IntlProvider } from "react-intl";
import enFlag from "../assets/images/en.png";
import spFlag from "../assets/images/sp.png";

import English from "../i18n/en.json";
import Spanish from "../i18n/es.json";

const useStyles = makeStyles(theme => ({
  langSelector: {
    position: "fixed",
    top: "3px",
    right: "10px",
    padding: "5px",
    background: "#FFF",
    borderRadius: "15px",
    "@media (max-width: 768px)": {
      position: "absolute",
      borderRadius: "0",
      top: "20px",
      right: "20px",
      zIndex: "2",
    },
  },
  langBtn: {
    margin: "0 5px",
  },
}));

const LanguageProvider = props => {
  const classes = useStyles();
  const { vendorProfile, partnerId } = props;

  const [enabled, setEnabled] = useState(false);
  const savedLangChoice = localStorage.getItem("lang");
  const browserLang = navigator.language.split("-")[0];
  const [locale, setLocale] = useState(browserLang); //english default
  const [lang, setLang] = useState(English);

  useEffect(() => {
    if (vendorProfile.altLanguageOca == "show" || vendorProfile.altLanguageOca == "trial") {
      setEnabled(true);
    }

    if (partnerId) {
      setEnabled(false);
      return;
    }

    if (!enabled) return;
    //Ask first if we have saved user's choice
    if (savedLangChoice) {
      if (savedLangChoice == "es") {
        setLang(Spanish);
        setLocale("es");
      } else {
        setLang(English);
        setLocale("en");
      }
      return;
    }
    //fallback to english
    localStorage.setItem("lang", "en");

    //unless spanish is the browser's choice
    if (browserLang == "es") {
      setLang(Spanish);
      setLocale(browserLang);
      localStorage.setItem("lang", "es");
    }
  }, [vendorProfile, enabled]);

  const handleChange = langSelected => {
    setLocale(langSelected);
    switch (langSelected) {
      case "en":
        setLang(English);
        localStorage.setItem("lang", "en");
        break;
      case "es":
        setLang(Spanish);
        localStorage.setItem("lang", "es");
        break;
    }
  };

  return (
    <IntlProvider locale={locale} messages={lang}>
      {enabled ? (
        <div className={classes.langSelector}>
          <Fab key="eng_btn" color={locale == "en" ? "primary" : null} size="small" className={classes.langBtn} aria-label="english" onClick={() => handleChange("en")}>
            <img src={enFlag} />
          </Fab>
          <Fab key="spa_btn" color={locale == "es" ? "primary" : null} size="small" className={classes.langBtn} aria-label="spanish" onClick={() => handleChange("es")}>
            <img src={spFlag} />
          </Fab>
        </div>
      ) : null}
      {props.children}
    </IntlProvider>
  );
};

export default LanguageProvider;

import React, { useContext } from "react";
import FooterStyles from "./Footer.module.css";
import Container from "@material-ui/core/Container";
import VendorContext from "../../../../vendorContext";
import { FormattedMessage } from "react-intl";
import { getContrastColor } from "../../../../utils/colorUtils";
import defaultMessage from "../../../../components/Message";

const Footer = props => {
  const { color: vendorColor } = useContext(VendorContext);
  const footerTextColor = getContrastColor(vendorColor);

  return (
    <div className={FooterStyles.footer} style={{ backgroundColor: vendorColor }}>
      <Container maxWidth="lg">
        <div className={FooterStyles.footerFlex}>
          <span style={{ color: footerTextColor }}>
            © Copyright {new Date().getFullYear()} Trnsact Technologies. <FormattedMessage id="allRightsReserved" defaultMessage={defaultMessage.allRightsReserved} />
          </span>
          <br />
          <span style={{ color: footerTextColor }}>
            <a href="https://dcr.ai/terms-of-use" target="_blank" style={{ color: footerTextColor, textDecoration: "none" }}>
              <FormattedMessage id="termsOfUse" defaultMessage={defaultMessage.termsOfUse} />
            </a>{" "}
            |{" "}
            <a href="https://dcr.ai/privacy/" target="_blank" style={{ color: footerTextColor, textDecoration: "none" }}>
              <FormattedMessage id="privacyPolicy" defaultMessage={defaultMessage.privacyPolicy} />
            </a>
          </span>
        </div>
      </Container>
    </div>
  );
};

export default Footer;

import * as yup from "yup";

export const contactFormPartnerValidator = yup.object({
  contactFirstName: yup.string("Enter your first name").required("Required"),
  contactLastName: yup.string("Enter your last name").required("Requiered"),
  contactEmail: yup.string("Enter your email").email("Enter a valid email").required("Required"),
  businessName: yup.string("Enter your bussines name").required("Required"),
  contactPhone: yup.string("Enter your phone").required("Required").min(10, "Please enter the correct phone number"),
  contactQuestion: "",
});

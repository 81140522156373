import React, { useContext, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import styles from "./ConfirmationModal.module.css";
import HeaderCard from "./HeaderCard";
import fonts from "../../fonts.module.css";
import VendorContext from "../../vendorContext";
import { formatCurrency } from "../../utils/stringFormatters";
import gql from "graphql-tag";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import LoadButton from "../../components/LoadButton";
import { Grid, Typography } from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import getSigningTask from "../../api/getSigningTask";
import _ from "lodash";
import { getContrastColor } from "../../utils/colorUtils";

const updateProposal = gql`
  mutation ($dynamicsId: ID, $id: ID, $dynamicsObj: updateProposalCRMFields, $fieldsObj: updateProposalFields) {
    updateProposal(dynamicsId: $dynamicsId, id: $id, dynamicsObj: $dynamicsObj, fieldsObj: $fieldsObj)
  }
`;

// const GET_SIGNING_URL = gql`
// query ($VOId: ID!) {
//   signingTask(VOId: $VOId) {
//     url
//   }
// }
// `;

const LenderOptionConfirmationModal = props => {
  const {
    closeModal,
    loanAmount,
    maxApproved,
    customerBusiness,
    termLength,
    monthlyPayment,
    setFormSubmit,
    formSubmitted,
    vendorGUID,
    propId,
    interestRate,
    docFee,
    rate,
    advancePayment,
    creditProduct,
    proposalId,
    paymentId,
    redirectToSigning,
    voId,
    amountDueProposalV2,
    lenderType = { lenderType },
    menuOption,
    isQuote,
    formId,
  } = props;
  const [amountDueDisplay, setAmountDueDisplay] = useState("");
  const [postPaymentSelection, { loading }] = useMutation(updateProposal, {
    onCompleted: () => {
      setFormSubmit(true);
    },
  });
  let urlParams = null;
  if (window) {
    urlParams = new URLSearchParams(window.location.search);
  }

  console.log("SELECT PROPS ::", props);

  // const [signingPage, signingPageResponse] = useLazyQuery(GET_SIGNING_URL, {
  //   variables: { VOId: voId },
  // });
  const { color: vendorColor } = useContext(VendorContext);
  let monthPay = monthlyPayment.paymentAmountPerPeriod >= 0 ? monthlyPayment.paymentAmountPerPeriod : monthlyPayment;
  const paymentSelectionObj = {
    monthlyPayment: isNaN(monthPay) ? parseFloat(monthPay.replace(/,/g, "")) : monthPay,
    dcrStage: "Accepted",
    status: "Accepted",
    paymentId: paymentId,
    termLength: termLength + "",
    fastTrackStatusId: 220,
  };
  try {
    if (!_.isEmpty(menuOption)) {
      paymentSelectionObj.paymentCriteriaSelected = {
        name: creditProduct.paymentCriteria.menu.name,
        description: creditProduct.paymentCriteria.menu.description,
        menuOption: {
          name: menuOption.name,
          titleBgColor: menuOption.titleBgColor,
          titleColor: menuOption.titleColor,
          payment: {
            paymentAmountPerPeriod: menuOption.payment.paymentAmountPerPeriod,
          },
          packages: menuOption.packages.map(p => {
            return {
              name: p.name,
              products: p.products.map(product => {
                return {
                  title: product.title,
                  cost: product.cost,
                  retailCost: product.retailCost,
                  proposalProductId: product.proposalProductId,
                };
              }),
            };
          }),
        },
      };
    }
  } catch (e) {
    console.log(`Issue selecting menu option: ${e.message}`);
  }

  const redirectToOCA = () => {
    window.location.href = `/oca/?vendorGUID=${vendorGUID}&formId=${formId}`;
  };
  const postPayment = async () => {
    if (propId === "5eecc379-1434-ea11-8114-005056b05a0f") {
      if (!vendorGUID) {
        return (window.location.href = "/confirmed");
      }
      return (window.location.href = "/confirmed?&vendorGUID=" + vendorGUID);
    }
    if (propId) {
      postPaymentSelection({
        variables: {
          dynamicsId: propId,
          dynamicsObj: paymentSelectionObj,
        },
      })
        .then(resp => {
          if (!vendorGUID) {
            return (window.location.href = "/confirmed");
          }
          return (window.location.href = "/confirmed?&vendorGUID=" + vendorGUID);
        })
        .catch(error => {
          window.NREUM.noticeError(error);
          console.error(error);
          if (!vendorGUID) {
            return (window.location.href = "/posterror");
          }
          return (window.location.href = "/posterror?&vendorGUID=" + vendorGUID);
        });
    }
    if (proposalId && redirectToSigning) {
      postPaymentSelection({
        variables: {
          id: proposalId,
          fieldsObj: paymentSelectionObj,
        },
      })
        .then(resp => {
          if (redirectToSigning) {
            let retry = (() => {
              let count = 0;
              return (max, timeout, next) => {
                getSigningTask(voId).then((response, error, body) => {
                  if (error || response.status !== 200 || !response.data.data.signingTask) {
                    console.log("pending...");
                    if (count++ < max) {
                      return setTimeout(() => {
                        retry(max, timeout, next);
                      }, timeout);
                    } else {
                      console.log("pending error");
                      if (!response.data.data.signingTask) {
                        if (!vendorGUID) {
                          return (window.location.href = "/lenderConfirmed");
                        }
                        return (window.location.href = "/lenderConfirmed?&vendorGUID=" + vendorGUID);
                      }
                      return next(new Error("max retries reached"));
                    }
                  }
                  console.log("success");
                  next(response, body);
                });
              };
            })();
            const maxTries = 10;
            const timeInterval = 5000;
            retry(maxTries, timeInterval, function (response, body) {
              let { url } = response.data.data.signingTask;
              if (url) {
                return window.location.replace(url);
              } else {
                if (!vendorGUID) {
                  return (window.location.href = "/lenderConfirmed");
                }
                return (window.location.href = "/lenderConfirmed?&vendorGUID=" + vendorGUID);
              }
            });
          }
        })
        .catch(error => {
          window.NREUM.noticeError(error);
          console.error(error);
          console.log("error", error);
          if (!vendorGUID) {
            return (window.location.href = "/posterror");
          }
          return (window.location.href = "/posterror?&vendorGUID=" + vendorGUID);
        });
    } else if (proposalId && !redirectToSigning) {
      postPaymentSelection({
        variables: {
          id: proposalId,
          fieldsObj: paymentSelectionObj,
        },
      })
        .then(resp => {
          if (!vendorGUID) {
            return (window.location.href = "/lenderConfirmed");
          }
          return (window.location.href = "/lenderConfirmed?&vendorGUID=" + vendorGUID);
        })
        .catch(error => {
          window.NREUM.noticeError(error);
          console.error(error);
          if (!vendorGUID) {
            return (window.location.href = "/posterror");
          }
          return (window.location.href = "/posterror?&vendorGUID=" + vendorGUID);
        });
    }
  };

  console.log("urlParams", urlParams);

  useEffect(() => {
    if (typeof amountDueProposalV2 === "number") {
      if (lenderType === "CLICK_LEASE") {
        setAmountDueDisplay("$199.00");
      } else if (amountDueProposalV2 === 0) {
        setAmountDueDisplay("$0.00");
      } else {
        setAmountDueDisplay(formatCurrency(amountDueProposalV2));
      }
    }
  });

  let primaryColor = menuOption && menuOption.titleBgColor ? menuOption.titleBgColor : vendorColor;
  primaryColor = primaryColor.includes("#") ? primaryColor : `#${primaryColor}`;
  let textColor = menuOption && menuOption.titleColor ? (menuOption.titleColor.includes("#") ? menuOption.titleColor : `#${menuOption.titleColor}`) : getContrastColor(vendorColor);

  return (
    <div className={_.isEmpty(menuOption) ? styles.modalCard : styles.modalCardGBB}>
      <p
        style={{
          color: !_.isEmpty(menuOption) ? textColor : "#000",
          backgroundColor: !_.isEmpty(menuOption) ? primaryColor : "#fff",
          margin: "0 0 1rem",
          padding: "1rem 0",
          borderRadius: "8px 8px 0 0",
          fontSize: 48,
        }}
        className={[fonts.h3, styles.defaultTextColor].join(" ")}>
        {menuOption && menuOption.name ? menuOption.name : "Confirm Selection"}
      </p>
      {_.isEmpty(menuOption) ? (
        <div className={styles.headerCardWrapper}>
          <HeaderCard loanAmount={loanAmount} type="CL" maxApproved={maxApproved} />
        </div>
      ) : (
        <div className={styles.headerCardWrapper} style={{ display: "flex", flexDirection: "column", maxWidth: 300, margin: "0 auto" }}>
          <p style={{ fontSize: 20 }}>
            <b>Packages Included</b>
          </p>
          {menuOption.packages.length > 0 &&
            menuOption.packages.map(pp => (
              <div key={pp.proposalPackageId}>
                <div style={{ display: "flex", fontSize: 16, marginBottom: 8 }}>
                  <CheckCircle style={{ color: primaryColor, marginRight: 4, fontSize: 20 }} />
                  <p style={{ color: "rgba(0,0,0,0.85)", margin: "2px 0 0 4px", textAlign: "start" }}>{pp.name}</p>
                </div>

                {_.some(pp.products) && (
                  <Grid container xs={12}>
                    <ul style={{ color: "rgba(0,0,0,0.85)", textAlign: "start", marginTop: 0 }}>
                      {_.map(pp.products, p => (
                        <li key={p.proposalProductId}>
                          <Typography variant="body2">
                            {p.title} {formatCurrency(p.retailCost)}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </Grid>
                )}
              </div>
            ))}
        </div>
      )}

      <div style={{ height: "36px" }} />
      <Grid container alignItems="stretch" style={{ display: "flex", padding: !_.isEmpty(menuOption) ? "0 20px" : "" }} spacing={2}>
        <Grid item xs={12} lg={4} xl={4}>
          <p>Amount Due Today</p>
          <hr />
          <p>
            <span className={fonts.h4}>{amountDueDisplay}</span>
          </p>
          <div style={{ height: "6px" }} />
          {lenderType === "CLICK_LEASE" && (
            <p style={{ fontSize: "10px", color: "gray", marginBottom: "0px" }}>
              Actual ACH date may vary based on equipment delivery/availability. Amount includes advance payment(s), down payment (if applicable), and a documentation fee.
            </p>
          )}
        </Grid>
        <Grid item xs={12} lg={4} xl={4}>
          <p>Monthly Payment</p>
          <hr />
          <p style={{ display: "flex", flexDirection: "column" }}>
            <span className={fonts.h4}>{formatCurrency(monthPay)}</span>
            <span>per month</span>
            {rate && lenderType === "CLICK_LEASE" && <span> at {rate}%</span>}
          </p>
          <br />
          {lenderType === "CLICK_LEASE" && <p style={{ fontSize: "10px", color: "gray" }}>Plus Applicable Taxes</p>}
        </Grid>
        <Grid item xs={12} lg={4} xl={4}>
          <p>Term Length</p>
          <hr />
          <p style={{ display: "flex", flexDirection: "column" }}>
            <span className={fonts.h4}>{termLength}</span>
            <span>months</span>
          </p>
          <br />
          {lenderType === "CLICK_LEASE" && <p style={{ fontSize: "10px", color: "gray" }}>Including Purchase Option</p>}
        </Grid>
      </Grid>
      <div style={{ height: "50px" }} />
      <div className={styles.buttonsBoxWrapper} style={!_.isEmpty(menuOption) ? { padding: "0 20px 20px" } : {}}>
        <div className={styles.buttonsBox}>
          <Button
            type="button"
            variant="outlined"
            style={{
              color: !_.isEmpty(menuOption) ? "rgba(0,0,0,0.85)" : vendorColor,
              borderColor: !_.isEmpty(menuOption) ? "rgba(0,0,0,0.85)" : vendorColor,
            }}
            onClick={closeModal}>
            Cancel
          </Button>
          {!urlParams ||
            (!urlParams.has("viewOnly") &&
              (isQuote ? (
                <LoadButton
                  style={{
                    backgroundColor: !_.isEmpty(menuOption) ? "rgba(0,0,0,0.85)" : vendorColor,
                    borderColor: !_.isEmpty(menuOption) ? "rgba(0,0,0,0.85)" : vendorColor,
                    color: getContrastColor(!_.isEmpty(menuOption) ? "rgba(0,0,0,0.85)" : vendorColor),
                  }}
                  type="submit"
                  variant="contained"
                  onClick={() => redirectToOCA()}>
                  Apply Now
                </LoadButton>
              ) : (
                <LoadButton
                  style={{
                    backgroundColor: !_.isEmpty(menuOption) ? "rgba(0,0,0,0.85)" : vendorColor,
                    borderColor: !_.isEmpty(menuOption) ? "rgba(0,0,0,0.85)" : vendorColor,
                    color: getContrastColor(!_.isEmpty(menuOption) ? "rgba(0,0,0,0.85)" : vendorColor),
                  }}
                  disabled={loading || formSubmitted}
                  loading={loading}
                  type="submit"
                  variant="contained"
                  onClick={() => postPayment()}>
                  {formSubmitted ? "Submitted" : "Submit"}
                </LoadButton>
              )))}
        </div>
      </div>
    </div>
  );
};

export default LenderOptionConfirmationModal;

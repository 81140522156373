import axios from "axios";
import { apiURL } from "../config";

const queryPublicAccountId = id => {
  return axios.post(apiURL, {
    operationName: null,
    variables: {},
    query: `{
  publicAccount(id: "${id}") {
    name
    dynamicsAccountId
    vendorProfile {
      id
      dynamicsId
      paymentSelectorAutomatic
      industryGroupType
      logo
      styles
      ocaForm
      disclosureLanguage
      dcrDisclosureLanguage
      dcrDisclosureTerms
      dcrDisclosureLanguageSpanish
      dcrDisclosureTermsSpanish
      ownershipRequirement
      dlRequired
      dlUploadRequired
      amountRequested
      altLanguageOca
      showConsumerCreditApp
      ocaDocUpload
      reqDocsLanguage
      showIndustryOptionset
      psChannel
    }
    primaryContact {
      fullName
      phoneNumber
      email
      availability
      mugshot
      vendorContactRole
      title
    }
    salesReps {
      fullName
      dynamicsContactId
      phoneNumber
      email
      availability
      mugshot
      title
    }
  }
}`,
  });
};

export default queryPublicAccountId;

import React, { useCallback } from "react";
import { TextField } from "@material-ui/core";

export const FieldInput = ({ formik, name, ...rest }) => {
  const upperCaseFirstLetters = value => {
    let formattedValue = value.split("");

    formattedValue = formattedValue.map((letter, i) => {
      if (i === 0 || formattedValue[i - 1] === " " || formattedValue[i - 1] === ".") {
        return letter.toUpperCase();
      }

      return letter.toLowerCase();
    });
    formattedValue = formattedValue.join("");

    return formattedValue;
  };

  const handleChange = useCallback(e => {
    if (name === "contactFirstName" || name === "contactLastName") {
      const upperCasedValue = upperCaseFirstLetters(e.target.value);
      e.target.value = upperCasedValue;
    }
    formik.handleChange(e);
  });

  return (
    <TextField
      InputLabelProps={{
        shrink: true,
      }}
      fullWidth={true}
      name={name}
      value={formik.values[name]}
      helperText={formik.touched[name] && formik.errors[name]}
      error={Boolean(formik.touched[name] && formik.errors[name])}
      onChange={handleChange}
      onBlur={formik.handleBlur}
      variant="outlined"
      {...rest}
    />
  );
};

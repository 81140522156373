export const parseCurrency = value => {
  if (value) {
    let requiredValue = value.replace(/[^0-9.]/g, "");

    const maxAmount = 1000000000;

    if (!isNaN(parseInt(requiredValue))) {
      requiredValue = requiredValue > maxAmount ? requiredValue.slice(0, -1) : requiredValue;
    }

    return requiredValue;
  }
};

export const formatCurrency = value => {
  if (!!value || value === 0) {
    if (value === 0) return "$0.00";

    if (typeof value !== "string") {
      value = value.toString();
    }

    let formattedValue = value && value.replace(/[^\d.]/g, "");
    if (typeof formattedValue === "number") formattedValue = formattedValue.toString();
    if (formattedValue && formattedValue !== "$" && formattedValue !== "0") {
      // Remove commas and dollar sign
      formattedValue = formattedValue.replace(/\$/g, "");
      formattedValue = formattedValue.replace(/,/g, "");
      if (formattedValue.includes(".")) {
        const decimalLocation = formattedValue.indexOf(".");

        // Add thousands seperators
        let digitsBeforeDecimal = formattedValue.slice(0, decimalLocation);
        digitsBeforeDecimal = Number(digitsBeforeDecimal).toLocaleString();

        const trailingDigits = formattedValue.slice(decimalLocation + 1);
        if (trailingDigits.length <= 2) {
          return `$${digitsBeforeDecimal}.${trailingDigits.padEnd(2, "0")}`;
        } else {
          // Truncate third trailing digit
          return `$${digitsBeforeDecimal}.${trailingDigits.slice(0, 2)}`;
        }
      } else {
        // Add thousands seperator
        const valueWithCommas = Number(formattedValue).toLocaleString();
        return `$${valueWithCommas}`;
      }
    } else return "";
  }

  return "";
};

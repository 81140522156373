import { getURLParameter } from "../utils";

const uuidValidate = require("uuid-validate");
const _ = require("lodash");

//Set TTL for localstorage for a week
const daysToSaveData = 7;
const ttlSavedData = 1000 * 60 * 60 * 24 * daysToSaveData;

export const getValidFormId = () => {
  const formId = getURLParameter("formId");
  return uuidValidate(formId, 4) ? formId : false;
};

export const destroyLocalStorage = formId => {
  localStorage.removeItem(`savedForm_${formId}`);
};

export const markAsAlreadySubmitted = formId => {
  const key = `savedForm_${formId}`;
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);

  item.alreadySubmitted = true;

  localStorage.setItem(`savedForm_${getValidFormId()}`, JSON.stringify(item));
};

export const getLocalStorageWithExpiry = formId => {
  const key = `savedForm_${formId}`;
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.values;
};

export const restoreSaveFormData = (initialValues, formId) => {
  const ccg_transaction_equipmentdescription = getURLParameter("equipmentDescription");

  const amountRequested = getURLParameter("price");

  const valuesFromUrl = ["firstName", "lastName", "phone", "businessName"];

  const localStoragePrev = getLocalStorageWithExpiry(formId);

  valuesFromUrl.map(urlKey => {
    const urlValue = getURLParameter(urlKey);
    if (urlValue) {
      _.merge(localStoragePrev, { [urlKey]: urlValue });
    }
  });

  if (ccg_transaction_equipmentdescription) {
    _.merge(localStoragePrev, {
      ccg_transaction_equipmentdescription,
      amountRequested,
    });
  }

  //Final merge
  _.merge(initialValues, { ...localStoragePrev });
};

export const setLocalStorageWithExpiry = (values, formId) => {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    values,
    expiry: now.getTime() + ttlSavedData,
  };
  localStorage.setItem(`savedForm_${formId}`, JSON.stringify(item));
};

import React, { useContext, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import styles from "./General.module.css";
import { getURLParameter } from "../utils";
import { Form } from "react-final-form";
import { gql } from "apollo-boost";
import { useMutation, useQuery } from "@apollo/react-hooks";
import TextField from "@material-ui/core/TextField";
import { createMuiTheme } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ReactHtmlParser from "react-html-parser";
import { FormattedMessage, useIntl } from "react-intl";
import defaultMessage from "../components/Message";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";
import { formatCurrency } from "../utils/stringFormatters";
import queryPublicAccountId from "../api/queryPublicAccountId";
import Modal from "@material-ui/core/Modal";

const _ = require("lodash");

export const theme = createMuiTheme({
  typography: {
    fontFamily: "Homemade Apple, cursive",
  },
});

const config = require("../config");
const apiURL = (process.env.REACT_APP_DCR_API_BASE_SERVER_URL || "https://api.dev.dcrportal.com") + "/v1/graphql";
// const apiURL = "http://localhost:3001/v1/graphql";

const WaiverComponent = ({ text }) => {
  return <>{ReactHtmlParser(text)}</>;
};

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

function InvoiceTaskPage(props) {
  const lang = useIntl();

  const UPLOAD_FILE = `
    mutation($input: UpsertDocumentInput!, $file: Upload) {
      upsertDocument(input: $input, file: $file) 
    }
  `;

  const QUERY_TASK = gql`
    query ($taskId: ID!) {
      publicTask(taskId: $taskId) {
        taskId
        vendorOpportunityId
        regarding
        status
        assignedToUserProfileId
        assignedToAccountId
        assignedByUserProfileId
        assignedByAccountId
        notes
        priority
        createdBy
        createdDateTime
        assignedByUser
        assignedByAccount
        assignedToUser
        assignedToAccount
        notifyByEmail
        notifyByText
        lastReminderSent
        nextReminder
        taskItems {
          type
          docType
          taskAssignorNotes
          taskAssigneeNotes
          url
          doc {
            documentId
            vendorOpportunityId
            source
            docType
            docDescription
            createdBy
            docName
          }
        }
        proposal {
          proposalId
          amountRequested
          maxApprovedAmount
          firstName
          lastName
          businessName
        }
        vendorOpportunity {
          equipmentDescription
          dynamicsVendorOpportunityId
          salesRepresentative {
            firstName
            lastName
            dynamicsContactId
            fullName
            title
          }
        }
      }
    }
  `;

  const UPDATE_TASK = gql`
    mutation ($taskId: ID!, $status: String!) {
      updateTaskStatus(taskId: $taskId, status: $status)
    }
  `;

  const [logo, setLogo] = useState();
  const task = getURLParameter("taskId");
  const [file, setFile] = useState();
  const fields = {};
  const setFields = {};
  const [taskList, setTaskList] = useState();
  const [vsp, setVsp] = useState();
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [fileType, setFileType] = useState();
  const [vendorGUID, setVendorGUID] = useState();
  const [vendorObj, setVendorObj] = useState({});
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [selectedSalesRep, setSelectedSalesRep] = useState();
  const [availableSalesReps, setAvailableSalesRep] = useState();
  const [showModal, setShowModal] = useState(false);
  const [acknowledgeRep, setAcknowledgeRep] = useState(false);

  const closeModal = () => {
    setAcknowledgeRep(true);
    setShowModal(false);
  };

  const useStyles = makeStyles(theme => ({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflowY: "auto",
    },
  }));

  const { data: taskData } = useQuery(QUERY_TASK, {
    variables: {
      taskId: task,
    },
  });

  const [updateTask, { data: updateTaskResponseData, loading: loadingUpdateTaskResponse }] = useMutation(UPDATE_TASK);

  const setRepArray = reps => {
    let updateReps;
    if (reps) {
      updateReps = reps.map(item => {
        try {
          item.value = item.dynamicsContactId;
          if (item.title == null) {
            item.label = item.fullName;
          } else {
            item.label = item.fullName + ", " + item.title;
          }
          return item;
        } catch (err) {
          window.NREUM.noticeError(err);
          console.error(err);
          return null;
        }
      });
      return updateReps;
    }
  };

  useEffect(() => {
    setTaskList(taskData);
    if (!!taskData) {
      setPage(taskData.publicTask[0].status);
      setVendorGUID(taskData.publicTask[0].assignedToAccountId);
      queryPublicAccountId(taskData.publicTask[0].assignedToAccountId.toLowerCase()).then(response => {
        if (!!taskData.publicTask[0].vendorOpportunity.salesRepresentative) {
          const updateSalesRep = {
            ...taskData.publicTask[0].vendorOpportunity.salesRepresentative,
          };
          updateSalesRep.value = updateSalesRep.dynamicsContactId;
          updateSalesRep.label = `${updateSalesRep.fullName}, ${updateSalesRep.title}`;
          setSelectedSalesRep(taskData.publicTask[0].vendorOpportunity.salesRepresentative.dynamicsContactId);
        }
        const updatedReps = setRepArray(response.data.data.publicAccount.salesReps);
        setAvailableSalesRep(updatedReps);

        if (_.get(response, "data.data.publicAccount.vendorProfile.styles")) {
          const vendorObj = {
            ...response.data.data.publicAccount.vendorProfile,
            accountName: response.data.data.publicAccount.name,
            color: response.data.data.publicAccount.vendorProfile.styles.color,
            secColor: response.data.data.publicAccount.vendorProfile.styles.secColor
              ? response.data.data.publicAccount.vendorProfile.styles.secColor
              : response.data.data.publicAccount.vendorProfile.styles.color,
            textColor: response.data.data.publicAccount.vendorProfile.styles.secColor
              ? response.data.data.publicAccount.vendorProfile.styles.secColor
              : "	#ffffff",
          };
          setVendorObj(vendorObj);
          setLoadingScreen(false);
          setLogo(response.data.data.publicAccount.vendorProfile.logo);
        } else {
          const vendorObj = {
            color: "#1473e6",
            secColor: "#1473e6",
            textColor: "#ffffff",
          };
          setVendorObj(vendorObj);
          setLoadingScreen(false);
        }
      });
    }
  }, [taskData]);

  [fields.file, setFields.file] = useState({
    value: null,
    validations: [{ type: "required" }],
  });
  [fields.logo, setFields.logo] = useState({ value: null });
  const [submitting, setSubmitting] = useState(false);
  const [page, setPage] = useState();

  function setValue(value, fieldName) {
    const field = {};
    if (!!fields[fieldName].format && typeof fields[fieldName].format === "function") {
      field.format = fields[fieldName].format;
      value = field.format(value);
    }
    field.value = value;
    setFields[fieldName](field);
  }

  const [invoiceQuoteNum, setInvoiceQuoteNum] = useState();
  const [invoiceTotal, setInvoiceTotal] = useState();

  const [invoiceTotalError, setInvoiceTotalError] = useState(false);
  const [invoiceTotalErrorMessage, setInvoiceTotalErrorMessage] = useState("");

  const checkInvoiceTotal = maxApprovedAmount => {
    if (parseFloat(invoiceTotal) > maxApprovedAmount) {
      setInvoiceTotalError(true);
      setInvoiceTotalErrorMessage(
        `Finance Amount may not be more than the max approved amount of ${formatCurrency(maxApprovedAmount)}`
      );
    } else {
      setInvoiceTotalError(false);
      setInvoiceTotalErrorMessage("");
    }
  };

  const handleSetInvoiceQuoteNum = e => {
    setInvoiceQuoteNum(e.target.value);
  };

  const handleSetInvoiceTotal = (e, maxApprovedAmount) => {
    setInvoiceTotal(e.target.value);
    checkInvoiceTotal(maxApprovedAmount);
  };

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      width: "400px",
      border: "2px solid #000",
      height: "500px",
      padding: "16px 32px 24px",
      position: "absolute",
      boxShadow:
        "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)",
      overflowY: "auto",
      backgroundColor: "#fff",
    };
  }

  const classes = useStyles();

  function uploadButtonHandler(event) {
    const file = document.getElementById("file");
    file.click();
  }

  const fileTypesAvailable = [
    {
      value: "invoice",
      label: "Invoice",
    },
    {
      value: "quote",
      label: "Quote",
    },
  ];

  return (
    <>
      {page === "open" ? (
        <>
          {logo ? (
            <div className={styles.vendorLogoWrapper}>
              <img
                src={
                  logo && logo.includes("/")
                    ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${logo}`
                    : `https://credit-app-images.s3-us-west-2.amazonaws.com/${logo}`
                }
                alt="Vendor Logo"
              />
            </div>
          ) : (
            ""
          )}
          <>
            <div>
              <h3>
                Transaction Details
                <br />
                <br />
                <Grid container spacing={10}>
                  <Grid style={{ paddingBottom: "20px" }} item l={6} m={6} s={6}>
                    Customer:
                  </Grid>
                  <Grid
                    style={{
                      paddingBottom: "20px",
                      paddingLeft: "75px",
                      fontWeight: "normal",
                    }}
                    item
                    l={6}
                    m={6}
                    s={6}>
                    {taskList.publicTask[0].proposal.businessName}
                  </Grid>
                </Grid>
                <Grid container spacing={10}>
                  <Grid item style={{ paddingBottom: "20px", paddingTop: "20px" }} l={6} m={6} s={6}>
                    Contact:
                  </Grid>
                  <Grid
                    item
                    style={{
                      paddingBottom: "20px",
                      paddingTop: "20px",
                      fontWeight: "normal",
                      paddingLeft: "92px",
                    }}
                    l={6}
                    m={6}
                    s={6}>
                    {`${taskList.publicTask[0].proposal.firstName} ${taskList.publicTask[0].proposal.lastName}`}
                  </Grid>
                </Grid>
                <Grid container spacing={5}>
                  <Grid item l={6} m={6} s={6}>
                    Amount Requested:
                  </Grid>
                  <Grid item style={{ fontWeight: "normal" }} l={6} m={6} s={6}>
                    {`${formatCurrency(taskList.publicTask[0].proposal.amountRequested)}`}
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item l={6} m={6} s={6}>
                    Max Approved Amount:
                  </Grid>
                  <Grid item l={6} m={6} s={6} style={{ fontWeight: "normal" }}>
                    {`${formatCurrency(taskList.publicTask[0].proposal.maxApprovedAmount)}`}
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item l={6} m={6} s={6}>
                    Equipment Description:
                  </Grid>
                  <Grid style={{ fontWeight: "normal" }} item l={6} m={6} s={6}>
                    {`${taskList.publicTask[0].vendorOpportunity.equipmentDescription}`}
                  </Grid>
                </Grid>
                <br />
                <br />
                <u>
                  <FormattedMessage
                    id="invoiceTaskOverview"
                    defaultMessage={"Please review the below outstanding items required"}
                  />
                </u>
                <h4>1. Please select a sales rep</h4>
                {!!availableSalesReps && availableSalesReps.length && (
                  <Grid container container spacing={0} style={{ paddingLeft: "20px" }}>
                    <Grid item l={5} m={5} s={5}>
                      <TextField
                        id="select-sales-rep"
                        select
                        label="Select Sales Rep"
                        value={selectedSalesRep}
                        onChange={e => setSelectedSalesRep(e.target.value)}
                        required
                        variant="outlined"
                        fullWidth
                        style={{ minWidth: "210px" }}>
                        {availableSalesReps.map(({ value, label }, i) => (
                          <MenuItem key={i} value={value} label={label}>
                            {label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                )}
                <h4>
                  2. <FormattedMessage id="invoiceTaskItem1" defaultMessage={"Upload a copy of the Invoice/Quote"} />
                </h4>
                <Grid container spacing={0} style={{ paddingLeft: "20px" }}>
                  <Grid item l={5} m={5} s={5}>
                    <TextField
                      id="select-doc-type"
                      select
                      label="Select Doc Type"
                      value={fileType}
                      onChange={e => setFileType(e.target.value)}
                      required
                      variant="outlined"
                      fullWidth
                      style={{ minWidth: "210px" }}>
                      {fileTypesAvailable.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item l={1} m={1} s={1}>
                    <div style={{ width: "24px" }}></div>
                  </Grid>
                  <Grid item l={5} m={5} s={5}>
                    <Button
                      startIcon={<CloudUploadIcon />}
                      className="formBtn"
                      color="primary"
                      variant="outlined"
                      // style={{ marginRight: '8px' }}
                      onClick={uploadButtonHandler}>
                      <FormattedMessage id="uploadInvQuote" defaultMessage={"Upload Document"} />
                      &nbsp;
                    </Button>
                  </Grid>
                  <Grid item l={1} m={1} s={1}>
                    <Tooltip title={<h3>If your invoice is not finalized, you may upload a quote.</h3>}>
                      <HelpIcon />
                    </Tooltip>
                  </Grid>
                </Grid>
                <h4>
                  {" "}
                  3.{" "}
                  <FormattedMessage
                    id="invoiceTaskItem2"
                    defaultMessage={"Enter the Invoice/Quote # and Enter Invoice Total (Financed Amount)"}
                  />
                </h4>
              </h3>
              <Grid container spacing={0} style={{ paddingLeft: "20px" }}>
                <Grid item l={5} m={5} s={5}>
                  <TextField
                    name="Invoice/Quote #"
                    placeholder={"Invoice/Quote #"}
                    InputLabelProps={{ style: { pointerEvents: "auto" } }}
                    label={"Invoice/Quote #"}
                    required={true}
                    value={invoiceQuoteNum}
                    onChange={handleSetInvoiceQuoteNum}
                    type="string"
                    variant="outlined"
                  />
                </Grid>
                <Grid item l={1} m={1} s={1}>
                  <Tooltip
                    title={<h3>If your invoice is not finalized, you may enter your quote or estimate number.</h3>}>
                    <HelpIcon />
                  </Tooltip>
                </Grid>
                <Grid item l={5} m={5} s={5} style={{ minWidth: "280px" }}>
                  <TextField
                    name="Finance Amount"
                    placeholder={"Finance Amount"}
                    label={"Finance Amount"}
                    required={true}
                    value={invoiceTotal}
                    onChange={e => handleSetInvoiceTotal(e, taskList.publicTask[0].proposal.maxApprovedAmount)}
                    type="string"
                    variant="outlined"
                    fullWidth
                    error={invoiceTotalError}
                    helperText={invoiceTotalErrorMessage}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>
                <Grid item l={1} m={1} s={1}>
                  <Tooltip
                    title={
                      <h3>
                        The amount we will be funding for the transaction. This amount should exclude sales tax and not
                        include more than 20% of soft costs.
                      </h3>
                    }>
                    <HelpIcon />
                  </Tooltip>
                </Grid>
                <Grid item l={5} m={5} s={5} style={{ minWidth: "280px" }}>
                  <TextField
                    name="Sales Tax"
                    placeholder={"Sales Tax"}
                    label={"Sales Tax"}
                    disabled
                    value="Exclude Sales Tax"
                    type="string"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </div>
          </>
          <br />
          <br />
          <Modal
            open={showModal}
            onClose={closeModal}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <div
              style={{
                padding: "20px",
                width: "800px",
                backgroundColor: "white",
                borderRadius: "8px",
                textAlign: "center",
              }}>
              <p>"Please identify the Sales Representative for this Transaction (if known)."</p>
              <Button color="primary" onClick={closeModal} variant="outlined">
                Close
              </Button>
            </div>
          </Modal>
          <Form
            onSubmit={async values => {
              if (!selectedSalesRep && !acknowledgeRep) {
                setShowModal(true);
              } else {
                if (fields.file.value) {
                  const formData = new FormData();
                  formData.append(
                    "operations",
                    JSON.stringify({
                      query: UPLOAD_FILE,
                      variables: {
                        input: {
                          vendorOpportunityId: taskList.publicTask[0].vendorOpportunityId,
                          source: "OCA Invoice Upload",
                          createdBy: "testing",
                          docType: fileType,
                          docName: fileType,
                          invoiceTotal: parseFloat(invoiceTotal),
                          invoiceQuoteNumber: invoiceQuoteNum,
                          dynamicsVendorOpportunityId:
                            taskList.publicTask[0].vendorOpportunity.dynamicsVendorOpportunityId,
                          dynamicsSalesRepId: selectedSalesRep,
                        },
                        file: fields.file.value,
                      },
                    })
                  );
                  if (fields.file.value) {
                    formData.append(
                      "map",
                      JSON.stringify({
                        0: ["variables.file"],
                      })
                    );
                    formData.append("0", fields.file.value);
                  }

                  return new Promise(async (resolve, reject) => {
                    fetch(apiURL, {
                      method: "POST",
                      body: formData,
                    })
                      .then(response => response.json())
                      .then(async response => {
                        response && response.data && response.data.upsertFile && setFile(response.data.upsertFile);
                        try {
                          await updateTask({
                            variables: {
                              taskId: task,
                              status: "complete",
                            },
                          });
                          setSubmitting(true);
                          setPage("complete");
                        } catch (err) {
                          window.NREUM.noticeError(err);
                          console.error(err);
                        }
                      })
                      .catch(err => {
                        window.NREUM.noticeError(err);
                        console.log(err);
                      });
                  });
                }
              }
            }}
            render={({ handleSubmit }) => {
              function uploadFileChanged(event) {
                setValue(event.target.files[0], "file");
                const reader = new FileReader();
                reader.onload = e => {
                  const avatar = document.getElementById("avatar");
                  avatar.src =
                    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAaVBMVEX///8hISGQkJAODg4VFRVwcHBzc3MAAAD39/ciIiIYGBgcHBwNDQ2FhYUJCQlQUFDx8fG5ublhYWHr6+vf3995eXkqKipWVlY5OTnIyMidnZ2ZmZmtra3Y2NjBwcE0NDRERETPz89eXl6D155KAAADkElEQVR4nO3da2/aMBjFcdwYDM4FKBtQtlHG9/+QyyaiXZ6g5uLHPp7O/8W0N8P81CZBR+q6WDzrsK+3R6PX+3L/9Owo1c7ZUhFojHfV14TAV6eqe+Q+p/Ktb1UMYEs8pQHujjYO0JjivEshXEX6Cv7M+0/xgeso12BX6d6iC9+iCtuLMfot9dJ0Z/umUEhc5O4lsvCluwy90p1OfI+4V52DnvVSPA62K5XX77nOm+1a5agn/RYuVV6/705mjweVs/pLITSl/aZyWG9JhO1T46pyWl9phO395qJyXE+phMbVKufJkgmjPTXSCU11i/LUSCg0tozxQTylsL2lflc59K+SClui/n6TVtjeb76oHPtHqYX6801yofp8k16oPd8ACI23mk8NBKHufAMhVJ1vQISK8w2K0Did84GEavMNjlBrvgESGl9ozDdIQp35BkqoMt+ACRXmGzRh+PkGThh8vsETGrsJ+tQAFAaebxCFYecbSGHQ+QZUGHC+QRWGm29ghcHmG1xh+0E8yHwDLAw03yALw8w32MIQ8w24MMB8gy6cP9/AC419n/dBHF9ofDNrvslAOHO+yUE4b77JQzhnvslEaNx96jvIRTh9vslGaKyZ9tTIR2jKZtJ8k5Fw4nyTk3DafJOXcMp8k5lwwnyTm9AU55FPjeyEo394Kj/h2PkmQ+HI+SZL4aj5Jk/hmKdGpsIR36i5Co37/4VDl41shc3Qj6jZCouht1MKZ3erdP4/Chzh7v7r5TfdH/P+sgEUBm5lKfw3CtGiUEYhWhTKKESLQhmFaFEooxAtCmUUokWhjEK0KJRRiBaFMgrRolBGIVoUyihEi0IZhWhRKKMQLQplFKJFoYxCtCiUUYgWhTIK0aJQRiFaFMooRItCGYVoUSijEC0KZRSiRaGMQrQolFGIFoUyCtGiUEYhWhTKKESLQhmFaFEooxAtCmUUokWhjEK0KJRRiBaFMgrRolBGIVoUyihEi0IZhWhRKKMQLQplFKJFoYxCtCiUUYgWhTIK0aJQRiFaFMooRItCGYVoUSijEK05wpXqOwvVBGH1+Bf+pPrOQnXyj/dbDRVeGtMRmwK/pgOa5jJQeNX63efauetAodpvd9fOrQcKF8vi41cDrBh+6z94//HrweX9brBwsTvn91UsziOAbffcrkV3H+Vrq52zZeq3PbDSOlePBbYX477eHlO/90Edt/X+8NTxAzN/SyQXBz8WAAAAAElFTkSuQmCC";
                  setValue(e.target.result, "logo");
                };
                reader.readAsDataURL(event.target.files[0]);
              }
              checkInvoiceTotal(taskList.publicTask[0].proposal.maxApprovedAmount);
              return (
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    if (!selectedSalesRep && !acknowledgeRep) {
                      setShowModal(true);
                    } else {
                      handleSubmit();
                    }
                  }}>
                  <input id="file" type="file" style={{ display: "none" }} onChange={uploadFileChanged} />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}>
                    <img
                      id="avatar"
                      alt="Uploaded Doc"
                      style={
                        fields.file.value
                          ? {
                            marginRight: "32px",
                            height: "64px",
                            width: "64px",
                          }
                          : {
                            height: "1x",
                            width: "1px",
                          }
                      }
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAaVBMVEX///8hISGQkJAODg4VFRVwcHBzc3MAAAD39/ciIiIYGBgcHBwNDQ2FhYUJCQlQUFDx8fG5ublhYWHr6+vf3995eXkqKipWVlY5OTnIyMidnZ2ZmZmtra3Y2NjBwcE0NDRERETPz89eXl6D155KAAADkElEQVR4nO3da2/aMBjFcdwYDM4FKBtQtlHG9/+QyyaiXZ6g5uLHPp7O/8W0N8P81CZBR+q6WDzrsK+3R6PX+3L/9Owo1c7ZUhFojHfV14TAV6eqe+Q+p/Ktb1UMYEs8pQHujjYO0JjivEshXEX6Cv7M+0/xgeso12BX6d6iC9+iCtuLMfot9dJ0Z/umUEhc5O4lsvCluwy90p1OfI+4V52DnvVSPA62K5XX77nOm+1a5agn/RYuVV6/705mjweVs/pLITSl/aZyWG9JhO1T46pyWl9phO395qJyXE+phMbVKufJkgmjPTXSCU11i/LUSCg0tozxQTylsL2lflc59K+SClui/n6TVtjeb76oHPtHqYX6801yofp8k16oPd8ACI23mk8NBKHufAMhVJ1vQISK8w2K0Did84GEavMNjlBrvgESGl9ozDdIQp35BkqoMt+ACRXmGzRh+PkGThh8vsETGrsJ+tQAFAaebxCFYecbSGHQ+QZUGHC+QRWGm29ghcHmG1xh+0E8yHwDLAw03yALw8w32MIQ8w24MMB8gy6cP9/AC419n/dBHF9ofDNrvslAOHO+yUE4b77JQzhnvslEaNx96jvIRTh9vslGaKyZ9tTIR2jKZtJ8k5Fw4nyTk3DafJOXcMp8k5lwwnyTm9AU55FPjeyEo394Kj/h2PkmQ+HI+SZL4aj5Jk/hmKdGpsIR36i5Co37/4VDl41shc3Qj6jZCouht1MKZ3erdP4/Chzh7v7r5TfdH/P+sgEUBm5lKfw3CtGiUEYhWhTKKESLQhmFaFEooxAtCmUUokWhjEK0KJRRiBaFMgrRolBGIVoUyihEi0IZhWhRKKMQLQplFKJFoYxCtCiUUYgWhTIK0aJQRiFaFMooRItCGYVoUSijEC0KZRSiRaGMQrQolFGIFoUyCtGiUEYhWhTKKESLQhmFaFEooxAtCmUUokWhjEK0KJRRiBaFMgrRolBGIVoUyihEi0IZhWhRKKMQLQplFKJFoYxCtCiUUYgWhTIK0aJQRiFaFMooRItCGYVoUSijEK05wpXqOwvVBGH1+Bf+pPrOQnXyj/dbDRVeGtMRmwK/pgOa5jJQeNX63efauetAodpvd9fOrQcKF8vi41cDrBh+6z94//HrweX9brBwsTvn91UsziOAbffcrkV3H+Vrq52zZeq3PbDSOlePBbYX477eHlO/90Edt/X+8NTxAzN/SyQXBz8WAAAAAElFTkSuQmCC"
                    />
                  </div>
                  <Button
                    className="formBtn"
                    color="primary"
                    variant="contained"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={
                      !fields.file.value ||
                      !fileType ||
                      !invoiceTotal ||
                      !invoiceQuoteNum ||
                      invoiceTotalError ||
                      submitting
                    }>
                    <FormattedMessage id="submit" defaultMessage={defaultMessage.submit} />
                  </Button>
                </form>
              );
            }}
          />
        </>
      ) : (
        <h3>
          {" "}
          <FormattedMessage id="uploadThankYou" defaultMessage={defaultMessage.uploadThankYou} />
        </h3>
      )}
    </>
  );
}
export default InvoiceTaskPage;

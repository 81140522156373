import React, { useContext } from "react";
import VendorContext from "../vendorContext";
import styles from "./components/General.module.css";

const config = require("../config");

const PSFail = () => {
  const { logo } = useContext(VendorContext);
  return (
    <React.Fragment>
      {logo ? (
        <div className={styles.vendorLogoWrapper}>
          <img
            src={logo && logo.includes("/") ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${logo}` : `https://credit-app-images.s3-us-west-2.amazonaws.com/${logo}`}
            alt="Vendor Logo"
          />
        </div>
      ) : (
        ""
      )}
      <br></br>
      <span className={styles.cardTitle}>Payment Options failed to load.</span>
      <p>Our apologies but there seems to have been an error retrieving your payment options. </p>
      <p>Please give us a call.</p>
    </React.Fragment>
  );
};

export default PSFail;

import axios from "axios";
import { apiURL } from "../config";

const queryGetContact = dynamicsId => {
  return axios
    .post(apiURL, {
      operationName: null,
      variables: {},
      query: `{
  contact(dynamicsId: "${dynamicsId}") {
    email
      firstName
      lastName
      fullName
      title
      phoneNumber
      vendorContactRole
      mugshot
      accountId
  }
}`,
    })
    .then(response => {
      const id = response.data.data.contact ? response.data.data.contact.accountId : null;
      return axios.post(apiURL, {
        operationName: null,
        variables: {},
        query: `{
  publicAccount(id: "${id}") {
    name
    dynamicsAccountId
    vendorProfile {
      paymentSelectorAutomatic
      industryGroupType
      logo
      styles
      ocaForm
      disclosureLanguage
      dcrDisclosureLanguage
      dcrDisclosureLanguageSpanish
      dcrDisclosureTermsSpanish
      dcrDisclosureTerms
      ownershipRequirement
      dlRequired
      dlUploadRequired
      amountRequested
      altLanguageOca
      showConsumerCreditApp
      ocaDocUpload
      reqDocsLanguage
      showIndustryOptionset
      psChannel
    }
    primaryContact {
      fullName
      phoneNumber
      email
      availability
      mugshot
      vendorContactRole
      title
    }
    locations {
      locationId
      locationName
      category
      accountDynamicsId
      logo
      ocaContactId
      colorSchema
      ocaContact {
        email
        firstName
        lastName
        fullName
        title
        phoneNumber
        vendorContactRole
        mugshot
        availability
        accountId
        dynamicsContactId
        id
        lastLogin
        invitationCode
        invitationDatetime
        status
      }
      contactIds
      contactsInfo {
        email
        fullName
        title
        phoneNumber
        vendorContactRole
        mugshot
        availability
        dynamicsContactId
      }
      locationParentId
      notificationAppReceipt
    }
  }
}`,
      });
    });
};

export default queryGetContact;

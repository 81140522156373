import React, { useContext } from "react";
import styles from "./General.module.css";
import VendorContext from "../vendorContext";

const config = require("../config");

const DeclinePrequal = () => {
  const { logo } = useContext(VendorContext);

  return (
    <div className="row cardHeader">
      <div className="col">
        {logo ? (
          <div className={styles.vendorLogoWrapper}>
            <img
              src={logo && logo.includes("/") ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${logo}` : `https://credit-app-images.s3-us-west-2.amazonaws.com/${logo}`}
              alt="Vendor Logo"
            />
          </div>
        ) : (
          ""
        )}
        <span className={styles.cardTitle}>We Cannot Pre-Qualify You At This Time</span>
        <p>
          Unfortunately, after reviewing your application we are unable to pre-qualify you for financing. An account executive will be reaching out to you to discuss in more detail, and may require
          additional information in order to continue review.
        </p>
      </div>
    </div>
  );
};

export default DeclinePrequal;

import React, { useContext } from "react";
import styles from "./HighlightCard.module.css";
import fonts from "../../fonts.module.css";
import VendorContext from "../../vendorContext";

const HighlightCard = ({ label, payment, months, rate, handleClick, rateCheck, thisProposalIsFastTracked, paymentSelectorOptionsForThisCard }) => {
  const { color: vendorColor } = useContext(VendorContext);
  const showInterestRate = paymentSelectorOptionsForThisCard ? paymentSelectorOptionsForThisCard.showInterestRate : false;

  // console.log(`HighLightCard paymentSelectorOptions: ${JSON.stringify(paymentSelectorOptionsForThisCard)}`);
  // console.log(`HighlightCard: showInterestRate: ${showInterestRate}`)

  return rateCheck > 15 ? (
    <div className={styles.card} style={{ backgroundColor: vendorColor }} onClick={handleClick}>
      <p className={styles.label}>{label}</p>
      <p className={fonts.h4}>{`$${payment}/mo.`}</p>
      <p>for {months} months</p>
      <p>
        <strong>See Details</strong>
      </p>
    </div>
  ) : (
    <div className={styles.card} style={{ backgroundColor: vendorColor }} onClick={handleClick}>
      <p className={styles.label}>{label}</p>
      <p className={fonts.h4}>{`$${payment}/mo.`}</p>
      <p>
        for {months} months {!showInterestRate ? "" : `at ${rate}%`}
      </p>
      <p>
        <strong>See Details</strong>
      </p>
    </div>
  );
};

export default HighlightCard;

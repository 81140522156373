import React, { useEffect, useState, useContext } from "react";
import formatString from "format-string-by-pattern";
import SidebarStyles from "./Sidebar.module.css";
import AdjSidebarStyles from "./AdjustedSidebar.module.css";
import fonts from "../../../../fonts.module.css";
import { getURLParameter } from "../../../../utils";
import { getContrastColor } from "../../../../utils/colorUtils";
import VendorContext from "../../../../vendorContext";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import queryPublicAccount from "../../../../api/queryPublicAccount";
import { FormattedMessage } from "react-intl";
import defaultMessage from "../../../../components/Message";
import queryPartnerLink from "../../../..//api/queryPartnerLink";

const _ = require("lodash");

const config = require("../../../../config");

const GET_CONTACT = gql`
  query contact($dynamicsId: ID!) {
    contact(dynamicsId: $dynamicsId) {
      email
      firstName
      lastName
      fullName
      title
      phoneNumber
      mugshot
      availability
      vendorContactRole
    }
  }
`;

const GET_TASK_CREATOR = gql`
  query ($taskId: ID!) {
    publicTask(taskId: $taskId) {
      createdByUserProfile {
        lastName
        firstName
        fullName
        email
        phoneNumber
        mugshot
      }
    }
  }
`;

const Sidebar = props => {
  let contactIdProps = null;
  let taskCreator = null;
  const { repData, setRepData } = props;
  const [contactId] = useState(getURLParameter("vendorcontactId"));
  const [taskId] = useState(getURLParameter("taskId"));

  if (contactId) {
    const getContact = useQuery(GET_CONTACT, {
      variables: { dynamicsId: contactId },
    });
    if (getContact.loading) {
      return null;
    }
    if (!getContact.data) {
      console.log("no data");
    }
    if (getContact.data) {
      contactIdProps = getContact.data.contact;
    }
  }

  if (taskId) {
    const getTaskCreator = useQuery(GET_TASK_CREATOR, {
      variables: { taskId: taskId },
    });

    if (getTaskCreator.loading) {
      return null;
    }

    if (getTaskCreator.data) {
      taskCreator = getTaskCreator.data;
    }
  }

  return <SidebarRender contactId={contactIdProps} taskCreator={taskCreator} setRepData={setRepData} repData={repData} />;
};
export default Sidebar;

const SidebarRender = props => {
  const contactId = props.contactId;
  const repData = props.repData;
  const setRepData = props.setRepData;
  const { color: vendorColor, panelTextColor } = useContext(VendorContext);
  const linkTextColor = getContrastColor(vendorColor);
  const [taskCreator, setTaskCreator] = useState(null);

  const pathName = window.location.pathname;

  const [isQueryPublicAccount, setQueryPublicAccount] = useState(false);
  const vendorGUID = getURLParameter("vendorGUID");
  const repId = getURLParameter("repId");
  const partnerDealerId = getURLParameter("partnerDealerId");
  const locationId = getURLParameter("locationId");
  const proposalId = getURLParameter("proposalId");

  useEffect(() => {
    setRepData(repDataStore["default"]);
    if (vendorGUID && !isQueryPublicAccount && !repDataStore[repId] && !contactId && !locationId && !proposalId) {
      setQueryPublicAccount(true);
      queryPublicAccount(vendorGUID.toLowerCase()).then(response => {
        try {
          if (response.data.data.publicAccount && response.data.data.publicAccount.primaryContact) {
            setRepData({
              officePhone: response.data.data.publicAccount.primaryContact.phoneNumber,
              email: response.data.data.publicAccount.primaryContact.email,
              name: response.data.data.publicAccount.primaryContact.fullName,
              availability: response.data.data.publicAccount.primaryContact.availability || "Monday-Friday, 8am-5pm PST",
              mugshot: response.data.data.publicAccount.primaryContact.mugshot,
            });
          }
        } catch (error) {
          window.NREUM.noticeError(error);
          console.error(error);
        }
      });
    }
    if (contactId) {
      setRepData({
        officePhone: contactId.phoneNumber,
        mugshot: contactId.mugshot,
        email: contactId.email,
        name: contactId.fullName,
        availability: contactId.availability || "Monday-Friday, 8am-5pm PST",
      });
    }
    if (!contactId && repId) {
      if (repId && repDataStore[repId]) {
        setRepData(repDataStore[repId]);
      }
    }
    if (!contactId && !repId) {
      setRepData(repDataStore["default"]);
    }
    if (!vendorGUID && !isQueryPublicAccount && !repDataStore[repId] && !contactId && partnerDealerId) {
      setQueryPublicAccount(true);
      queryPartnerLink(partnerDealerId).then(response => {
        if (_.get(response, "dealerContact")) {
          setRepData({
            officePhone: response.dealerContact.phoneNumber,
            email: response.dealerContact.email,
            name: response.dealerContact.fullName,
            availability: response.dealerContact.availability || "Monday-Friday, 8am-5pm PST",
            mugshot: response.dealerContact.mugshot,
          });
        } else if (!_.get(response, "dealerContact") && _.get(response, "data.data.publicAccount.primaryContact")) {
          setRepData({
            officePhone: response.data.data.publicAccount.primaryContact.phoneNumber,
            email: response.data.data.publicAccount.primaryContact.email,
            name: response.data.data.publicAccount.primaryContact.fullName,
            availability: response.data.data.publicAccount.primaryContact.availability || "Monday-Friday, 8am-5pm PST",
            mugshot: response.data.data.publicAccount.primaryContact.mugshot,
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    if (_.get(props.taskCreator, "publicTask[0].createdByUserProfile")) {
      setTaskCreator(props.taskCreator.publicTask[0].createdByUserProfile);
    }
  }, [props.taskCreator]);

  const { availability, email, mugshot, name, officePhone } = repData;

  let mugshotSource = "https://credit-app-images.s3-us-west-2.amazonaws.com/avatar-placeholder.png";

  if (taskCreator && taskCreator.mugshot && taskCreator.mugshot.includes("/")) {
    mugshotSource = `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${taskCreator.mugshot}`;
  } else if (mugshot && mugshot.includes("/")) {
    mugshotSource = `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${mugshot}`;
  }

  return (
    <div className={pathName === `/bankUpload/` ? AdjSidebarStyles.root : SidebarStyles.root} style={{ backgroundColor: vendorColor }}>
      <div className={pathName === `/bankUpload/` ? AdjSidebarStyles.body : SidebarStyles.body}>
        <div className={pathName === `/bankUpload/` ? AdjSidebarStyles.mdColumn : SidebarStyles.mdColumn}>
          <h3 className={fonts.h3} style={{ color: panelTextColor, margin: "15px" }}>
            <FormattedMessage id="needHelp" defaultMessage={defaultMessage.needHelp} />
          </h3>
          <p style={{ color: panelTextColor, textAlign: "center" }}>
            <FormattedMessage id="representativesHelp" defaultMessage={defaultMessage.representativesHelp} />
          </p>
        </div>
        <div className={pathName === `/bankUpload/` ? AdjSidebarStyles.mdColumn : SidebarStyles.mdColumn}>
          <img src={mugshotSource} alt="agent" className={pathName === `/bankUpload/` ? AdjSidebarStyles.photo : SidebarStyles.photo} />
        </div>
        <div className={pathName === `/bankUpload/` ? AdjSidebarStyles.mdColumn : SidebarStyles.mdColumn}>
          <h3 className={fonts.h5} style={{ color: panelTextColor }}>
            {taskCreator && taskCreator.fullName ? taskCreator.fullName : name}
          </h3>
          <a href={`mailto:${email}`} style={{ color: linkTextColor, textDecoration: "none", wordBreak: "break-word" }}>
            {taskCreator && taskCreator.email ? taskCreator.email : email}
          </a>
          <p style={{ color: panelTextColor }}>{formatString("(999) 999-9999", taskCreator && taskCreator.phoneNumber ? taskCreator.phoneNumber : officePhone)}</p>
          {/* <p style={{color: textColor}}>{availability}</p> */}
        </div>
      </div>
    </div>
  );
};

const repDataStore = {
  "2F0610CC-E91B-E811-80EA-005056B05A0F": {
    email: "m.whittier@Dealercreditresources.com",
    mugshot: "michael-whittier.jpeg",
    name: "Michael Whittier",
    officePhone: "7146899573",
    availability: "Monday-Friday, 8am-5pm PST",
  },
  "2A61AAB9-1EE1-E811-80FA-005056B05A0F": {
    name: "Ross Stites",
    email: "RStites@dealercreditresources.com",
    officePhone: "7146899417",
    mugshot: "ross.png",
    availability: "Monday-Friday, 8am-5pm PST",
  },
  "B3468F86-B4D5-E011-BC70-005056A20000": {
    name: "Ross Stites",
    email: "RStites@dealercreditresources.com",
    officePhone: "7146899417",
    mugshot: "ross.png",
    availability: "Monday-Friday, 8am-5pm PST",
  },
  "2067558D-EE75-E011-94BF-005056A20000": {
    name: "Beckham Thomas",
    email: "BThomas@dealercreditresources.com",
    mugshot: "beckham-thomas.jpg",
    officePhone: "7146899554",
    availability: "Monday-Friday, 8am-5pm PST",
  },
  "5e2e05a9-3873-e911-8108-005056b05a0f": {
    name: "Pond Charan",
    email: "PCharan@mainstbusiness.capital",
    mugshot: "pond-charan.png",
    officePhone: "7146899414",
    availability: "Monday-Friday, 8am-5pm PST",
  },
  "B69F6A03-5302-E611-80C5-005056B05A0F": {
    name: "Pond Charan",
    email: "PCharan@mainstbusiness.capital",
    mugshot: "pond-charan.png",
    officePhone: "7146899414",
    availability: "Monday-Friday, 8am-5pm PST",
  },
  "5b1ed757-3b5d-e911-8108-005056b05a0f": {
    name: "Chris Martin",
    email: "CMartin@mainstbusiness.capital",
    mugshot: "chris-martin.png",
    officePhone: "7146899562",
    availability: "Monday-Friday, 8am-5pm PST",
  },
  "280aae84-7e84-e211-95fd-005056a20000": {
    name: "Colleen Janis",
    email: "CJanis@mainstbusiness.capital",
    mugshot: "avatar-placeholder.png",
    officePhone: "7146899560",
    availability: "Monday-Friday, 8am-5pm PST",
  },
  default: {
    mugshot: "avatar-placeholder.png",
    name: <FormattedMessage id="customerService" defaultMessage={defaultMessage.customerService} />,
    email: "info@dealercreditresources.com",
    officePhone: "7146899573",
    availability: "Monday-Friday, 8am-5pm PST",
  },
};

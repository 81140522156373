import React, { useContext, useState, useEffect } from "react";
import styles from "./CreditApplicationWrapper.module.css";
import VendorContext from "../vendorContext";
import { FormattedMessage } from "react-intl";
import defaultMessage from "../components/Message";
import { emitGAEvent } from "../utils";
const _ = require("lodash");

const config = require("../config");

const ProcessingScreen = ({ vendorProfile }) => {
  const { logo, partnerLogo, vendorColor } = useContext(VendorContext);
  const [customPageLayout, setCustomPageLayout] = useState();

  useEffect(() => {
    emitGAEvent("submittal_received", {});
  }, []);

  useEffect(() => {
    if (!vendorProfile) {
      return;
    }
    const ocaTemplates = _.get(vendorProfile, "ocaTemplate", null);
    const activeTemplate = _.find(ocaTemplates, "active");
    setCustomPageLayout(_.get(activeTemplate, "jsonSettings.confirmationPage.layout", null));
  }, [vendorProfile]);

  return (
    <React.Fragment>
      {partnerLogo ? (
        // <div className={styles.vendorLogoWrapper}>
        <>
          <div className={`${styles.vendorLogoWrapper} ` + styles.partnerLogoBg} style={{ backgroundColor: vendorColor }}>
            <img
              src={
                partnerLogo && partnerLogo.includes("/")
                  ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${partnerLogo}`
                  : `https://credit-app-images.s3-us-west-2.amazonaws.com/${partnerLogo}`
              }
              alt="Partner Logo"
            />
          </div>
          {logo ? (
            // <div className={styles.vendorLogoWrapper}>
            <div className={styles.vendorLogoWrapper}>
              <img
                src={logo && logo.includes("/") ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${logo}` : `https://credit-app-images.s3-us-west-2.amazonaws.com/${logo}`}
                alt="Vendor Logo"
                className={styles.highwayLogo}
                style={{ maxHeight: "200px", height: "200px", marginRight: "20px" }}
              />
            </div>
          ) : null}
        </>
      ) : null}
      {logo && !partnerLogo ? (
        // <div className={styles.vendorLogoWrapper}>
        <div className={styles.vendorLogoWrapper}>
          <img
            src={logo && logo.includes("/") ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${logo}` : `https://credit-app-images.s3-us-west-2.amazonaws.com/${logo}`}
            alt="Vendor Logo"
          />
        </div>
      ) : null}
      {customPageLayout ? (
        <div
          dangerouslySetInnerHTML={{
            __html: customPageLayout,
          }}></div>
      ) : (
        <>
          <span className={styles.cardTitle} data-test-id="submittal-received-title">
            <FormattedMessage id="thankYou" defaultMessage={defaultMessage.thankYou} />
          </span>
          <p>
            <FormattedMessage id="thankYou2" defaultMessage={defaultMessage.thankYou2} />
          </p>
          <br />
        </>
      )}
    </React.Fragment>
  );
};

export default ProcessingScreen;

import ApolloClient from "apollo-boost";

const config = require("dotenv");

// by default we are loading dev env variables

config.config();
//console.log(`APP_ENV: ${process.env.REACT_APP_ENV}`);
export const APP_ENV = process.env.REACT_APP_ENV;

export const captchaSiteKey = process.env.REACT_PUBLIC_RECAPTCHA_SITE_KEY || "6Ldfd2glAAAAAMLxfkRXhLUJlLAdzQZVPlxS6xN6";

// export const apiURL = 'http://localhost:3001/v1/graphql';
export const apiURL = (process.env.REACT_APP_DCR_API_BASE_SERVER_URL || "https://api.dev.dcrportal.com") + "/v1/graphql";

export const client = new ApolloClient({
  uri: apiURL,
});
export const S3_BUCKET_NAME = process.env.REACT_APP_S3_BUCKET_NAME || "dcr-dev-api";

export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || ""; // the token is set in the prod env only
export const GTM_ID = process.env.REACT_GTM_ID || "GTM-MT5TVS2";
// Task Definitions:
// credit-app-v2-dev-definition:7
// credit-app-v2-prod-definition:6
// export const DD_ENV = process.env.NODE_ENV || "dev";
// export const DD_ENV_LOGS = process.env.REACT_APP_DD_LOGS_ENV || "localhost";

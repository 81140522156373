import React, { useContext, useEffect } from "react";
import styles from "./General.module.css";
import VendorContext from "../vendorContext";
import { FormattedMessage } from "react-intl";
import defaultMessage from "../components/Message";
import { emitGAEvent } from "../utils";

const config = require("../config");

const SubmittalFail = () => {
  const { logo } = useContext(VendorContext);

  useEffect(() => {
    emitGAEvent("submittal_failed", {});
  }, []);

  return (
    <React.Fragment>
      {logo ? (
        <div className={styles.vendorLogoWrapper}>
          <img
            src={logo && logo.includes("/") ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${logo}` : `https://credit-app-images.s3-us-west-2.amazonaws.com/${logo}`}
            alt="Vendor Logo"
          />
        </div>
      ) : (
        ""
      )}
      <br></br>
      <span className={styles.cardTitle}>
        <FormattedMessage id="apologies" defaultMessage={defaultMessage.apologies} />
      </span>
      <p>
        <FormattedMessage id="apologies1" defaultMessage={defaultMessage.apologies1} />{" "}
      </p>
      <p>
        <FormattedMessage id="apologies2" defaultMessage={defaultMessage.apologies2} />
      </p>
    </React.Fragment>
  );
};

export default SubmittalFail;

import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Footer from "./components/Footer/Footer";
import FormWindow from "./components/FormWindow/FormWindow";
import Sidebar from "./components/Sidebar/Sidebar";
import styles from "./Main.module.css";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import backgroundImage from "../../assets/images/iframeBackground.png";

const useStyles = makeStyles(() => ({
  iframeMode: {
    padding: 0,
    overflowX: "hidden",
  },
  financialProposal: {
    paddingTop: "20px",
    paddingBottom: "12px",
    color: "white",
    backgroundImage: `url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    borderRadius: "5px",
  },
  financialProposalText: {
    fontWeight: "300",
    fontSize: "16px",
    lineHeight: "19px",
    marginBottom: "10px",
  },
  financialProposalHeader: {
    fontWeight: "300",
    fontSize: "40px",
    lineHeight: "47px",
  },
  height: {
    height: "20px",
  },
}));

const Main = props => {
  const classes = useStyles();
  const { children, repData, setRepData } = props;
  const pathName = window.location.pathname;
  const location = window.location.href;
  const params = new URLSearchParams(window.location.search);
  const isIframe = location.includes("iframeApp=true");
  const isIframeChat = location.includes("chatWithDealer");
  const isPartner = location.includes("/contact/");
  const monthlyPaymentAmount = params.get("monthlyPaymentAmount");

  console.log(monthlyPaymentAmount);

  return (
    <>
      {!isIframeChat && (
        <>
          <Container maxWidth="lg" className={isIframe ? classes.iframeMode : ""}>
            {isIframe && monthlyPaymentAmount && (
              <Grid container justify="center" alignItems="center" className={classes.financialProposal}>
                <Grid item xs={12}>
                  <Grid container justify="center" alignItems="center">
                    <Typography component="h6" variant="h6" className={classes.financialProposalText}>
                      Finance it for only
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container justify="center" alignItems="center">
                    <Typography component="h5" variant="h5" className={classes.financialProposalHeader}>
                      $<b>{monthlyPaymentAmount}</b> / Mo.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <div className={isIframe ? "" : classes.height} />
            <Grid container justify={isPartner && "center"} spacing={2}>
              <Grid item xs={12} lg={12} xl={pathName === `/bankUpload/` ? 12 : 9} className={styles.sameHeight}>
                <FormWindow isIframe={isIframe} setRepData={setRepData}>
                  {children}
                </FormWindow>
              </Grid>
              <Grid item xs={12} lg={12} xl={pathName === `/bankUpload/` ? 12 : !isPartner ? 3 : 0} className={styles.sameHeight}>
                {isIframe ? (
                  <div style={{ display: "none" }}>
                    <Sidebar repData={repData} setRepData={setRepData} />
                  </div>
                ) : !isPartner ? (
                  <Sidebar repData={repData} setRepData={setRepData} />
                ) : null}
              </Grid>
            </Grid>
            <div className={classes.height} />
          </Container>
          <Footer />
        </>
      )}
    </>
  );
};

export default Main;

import React, { useEffect, useState } from "react";
import { Button, Grid, InputAdornment, Slide, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TrnsactLogo from "../../../assets/images/trnsact-logo-light.png";
import DoneIcon from "@material-ui/icons/Done";
import formatString from "format-string-by-pattern";
import { getURLParameter } from "../../../utils";
import queryPartnerLink from "../../../api/queryPartnerLink";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import helloEmoji from "../../../assets/images/hey.png";
import mixpanel from "../../../mixpanel";
const config = require("../../../config");

const useStyles = makeStyles(theme => ({
  chatContainer: {
    width: "320px",
    height: "570px",
    backgroundColor: "#f2f2f2",
    borderRadius: "7px",
    position: "absolute",
    right: props => (props.isIframeChat ? 0 : "17%"),
    boxShadow: "0px 1px 9px rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      right: "0 !important",
      bottom: "0 !important",
    },
    [theme.breakpoints.up("sm")]: {
      top: props => (props.isIframeChat ? 0 : "65%"),
    },
  },
  chatHeaderContainer: {},
  chatHeader: {
    backgroundColor: "white",
    padding: 20,
    borderTopRightRadius: 7,
    borderTopLeftRadius: 7,
  },
  headerText: {
    color: "#3785F4",
    fontWeight: "400",
    fontSize: 16,
  },
  helloEmoji: {
    maxHeight: 20,
    maxWidth: 20,
    paddingRight: 5,
  },
  closeButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "16px !important",
    height: "16px !important",
    borderRadius: "50%",
    boxShadow: "0px 0px 5px 2px rgba(0, 0, 0, 0.12)",
    cursor: "pointer",
  },
  chatMainContent: {
    padding: 20,
    position: "relative",
    height: "calc(100% - 100px)",
  },
  chatMessages: {},
  chatFooter: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
  companyLogoContainer: {
    padding: 10,
    backgroundColor: "#fff",
    borderBottomRightRadius: 7,
    borderBottomLeftRadius: 7,
  },
  companyLogo: {
    width: 37,
    height: 15,
  },
  message: {
    padding: 10,
    borderRadius: 7,
    position: "relative",
    marginBottom: 15,
  },
  firstMessage: {
    animation: "$newMessage 2s",
  },
  secondMessage: {
    animation: "$newMessage 3s",
  },
  lastMessage: {
    animation: "$newMessage 1s",
  },
  "@keyframes newMessage": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  dealerMessage: {
    marginRight: 70,
    backgroundColor: "#dbdbdb",
    "&::after": {
      content: '""',
      display: "block",
      position: "absolute",
      left: 0,
      bottom: -5,
      borderLeft: "10px solid #dbdbdb",
      borderBottom: "10px solid transparent",
      borderBottomRightRadius: 10,
    },
  },
  userMessage: {
    marginLeft: 70,
    backgroundColor: "#fff",
    "&::after": {
      right: 0,
      bottom: -5,
      borderBottom: "10px solid transparent",
      borderBottomLeftRadius: 10,
      content: '""',
      display: "block",
      position: "absolute",
      borderRight: "10px solid #fff",
    },
  },
  messageText: {
    fontSize: "12px",
    fontWeight: 400,
    margin: 0,
  },
  chatMainContentFooter: {
    position: "absolute",
    bottom: 0,
    marginBottom: 20,
    paddingRight: 20,
  },
  mainContentText: {
    margin: "0 0 20px 0",
    fontWeight: 300,
    fontSize: 12,
    color: "#000",
  },
  sendButton: {
    backgroundColor: "#3785F4",
    fontSize: 13,
    fontWeight: 700,
    color: "#fff",
    width: 120,
  },
  inputField: {
    fontSize: 12,
    color: "#000",
    "& .MuiFormLabel-root": {
      fontSize: 12,
      color: "#3785F4",
    },
    "& .MuiInputBase-root": {
      fontSize: 12,
    },
  },
  phoneInput: {
    margin: "29px 0",
    "& .MuiFormHelperText-root": {
      position: "absolute",
      bottom: -20,
    },
  },
  messageCount: {
    paddingTop: 5,
    textAlign: "end",
    margin: 0,
    fontSize: 12,
    fontWeight: 300,
    color: props => (props.message.length > props.maxMessageLength ? "red" : "#818181"),
  },
  messageLabel: {
    margin: 0,
    marginBottom: 5,
    color: "#3785F4",
    fontSize: 12,
  },
  doneIcon: {
    fontSize: 16,
    color: "#176F30",
  },
}));

const CREATE_PARTNER_CHAT = gql`
  mutation CreatePartnerLinkChat($input: CreatePartnerLinkChat!) {
    createPartnerLinkChat(input: $input) {
      sid
      phoneNumber
      error
    }
  }
`;

export const ChatWithDealer = ({ open, setOpen }) => {
  const location = window.location.href;
  const [userName, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [showFooter, setShowFooter] = useState(false);
  const [isSentMessage, setSentMessage] = useState(false);
  const [showDealerMessage, setShowDealerMessage] = useState(false);
  const [partnerInfo, setPartnerInfo] = useState("");
  const [dealerPhoneNumber, setDealerPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [dealerName, setDealerName] = useState("");
  const maxMessageLength = 320;

  const isIframeChat = location.includes("chatWithDealer");
  const partnerDealerId = getURLParameter("partnerDealerId");
  const partnerId = getURLParameter("partnerId");
  const equipmentDescription = getURLParameter("equipmentDescription");
  const machineURL = getURLParameter("machineURL");

  const classes = useStyles({ message, maxMessageLength, isIframeChat });

  const [createChat] = useMutation(CREATE_PARTNER_CHAT, {
    context: { authRequired: true },
  });

  const getDealerPhoneNumber = async () => {
    try {
      mixpanel.track("CONVERSATIONS: TEXT DEALER SEND BUTTON CLICKED", { send_immediately: true });
    } catch (error) {}
    setSentMessage(true);
    try {
      await createChat({
        variables: {
          input: {
            partnerLinkId: partnerInfo.partnerLinkId,
            dealerContactUserProfileId: partnerInfo.dealerContactId,
            applicantFullName: userName,
            applicantMobilePhoneNumber: phoneNumber,
            applicantsMessage: message,
            sourceURL: machineURL,
            equipmentDescription: equipmentDescription,
          },
        },
      }).then(response => {
        if (response.data.createPartnerLinkChat && !response.data.createPartnerLinkChat.error) {
          try {
            mixpanel.track("CONVERSATIONS: TEXT DEALER ACTION COMPLETED", { send_immediately: true });
          } catch (error) {}
          setDealerPhoneNumber(response.data.createPartnerLinkChat.phoneNumber);
        } else {
          setError("Whoops! Service unavailable, please try again later");
        }
      });
    } catch (e) {
      setError("Whoops! Service unavailable, please try again later");
      console.log(e);
    }
    setShowDealerMessage(true);
  };

  const isValidPhoneNumber = value => value && value.replace(/[^\d]/g, "").length >= 10;

  const upperCaseFirstLetters = value => {
    let formatedValue = value.split("");

    formatedValue = formatedValue.map((letter, i) => {
      if (i === 0 || formatedValue[i - 1] === " " || formatedValue[i - 1] === ".") {
        return letter.toUpperCase();
      }

      return letter.toLowerCase();
    });
    formatedValue = formatedValue.join("");

    return formatedValue;
  };

  const isValidValues = () => {
    if (userName && phoneNumber && message && isValidPhoneNumber(phoneNumber)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (userName || phoneNumber || message) {
      setShowFooter(true);
      isValidValues();
    } else {
      setShowFooter(false);
    }
  }, [userName, phoneNumber, message]);

  useEffect(() => {
    if (partnerDealerId) {
      queryPartnerLink(partnerDealerId).then(response => {
        if (response.dealerContact.id && response.partnerLinkId) {
          setDealerName(response.name);
          setPartnerInfo({ partnerLinkId: response.partnerLinkId, dealerContactId: response.dealerContact.id });
          try {
            mixpanel.register({
              partnerDealerId,
              dealerName: response.name,
              partnerId,
              environment: config.APP_ENV,
            });
          } catch (error) {
            console.log(error);
          }
        }
      });
    }
  }, []);

  return (
    <Slide direction="up" in={open} mountOnEnter unmountOnExit className={classes.chatContainer}>
      <Grid>
        <Grid item xs={12} className={classes.chatHeaderContainer}>
          <Grid container justify="space-between" alignItems="center" className={classes.chatHeader}>
            <Typography component="h6" variant="h6" className={classes.headerText}>
              {isSentMessage ? (
                "We texted you!"
              ) : (
                <Grid container alignItems="center">
                  <img src={helloEmoji} className={classes.helloEmoji} />
                  "Hi there! We’ll text you."
                </Grid>
              )}
            </Typography>
            {isIframeChat || (
              <Grid className={classes.closeButton} onClick={() => setOpen(false)}>
                <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.316374 0.316374L0 0.632748L1.68357 2.31638L3.36714 4L1.68357 5.68362L0 7.36725L0.316374 7.68363L0.632748 8L2.31638 6.31643L4 4.63286L5.68362 6.31643L7.36725 8L7.68363 7.68363L8 7.36725L6.31643 5.68362L4.63286 4L6.31643 2.31638L8 0.632748L7.68363 0.316374L7.36725 0L5.68362 1.68357L4 3.36714L2.31638 1.68357L0.632748 0L0.316374 0.316374Z"
                    fill="#818181"
                  />
                </svg>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.chatMainContent}>
          <Grid className={classes.chatMessages}>
            <Grid item className={`${classes.message} ${classes.dealerMessage} ${classes.firstMessage}`}>
              <p className={classes.messageText}>Enter your information below, and one of our representatives will contact you soon.</p>
            </Grid>
            <Grid item className={`${classes.message} ${classes.userMessage} ${classes.secondMessage}`}>
              {isSentMessage ? (
                <div>
                  <p className={classes.messageLabel}>{phoneNumber}</p>
                  <Grid container alignItems="center" justify="space-between">
                    <span className={classes.messageText}>{message}</span>
                    <DoneIcon className={classes.doneIcon} />
                  </Grid>
                </div>
              ) : (
                <div>
                  <TextField
                    fullWidth
                    className={classes.inputField}
                    label={userName ? "Name" : "Enter your Name"}
                    value={userName}
                    onChange={e => {
                      const value = upperCaseFirstLetters(e.target.value);
                      setUserName(value);
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{userName && <DoneIcon className={classes.doneIcon} />}</InputAdornment>,
                    }}
                  />
                  <TextField
                    fullWidth
                    className={`${classes.inputField} ${classes.phoneInput}`}
                    label={phoneNumber ? "Mobile Phone" : "Enter your Mobile Phone"}
                    value={phoneNumber}
                    helperText={phoneNumber && !isValidPhoneNumber(phoneNumber) ? "Phone number is invalid." : null}
                    onChange={e => {
                      const value = e.target.value.replace(/[^\d-() ]/, "");
                      setPhoneNumber(formatString("(999) 999-9999", value));
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{isValidPhoneNumber(phoneNumber) && <DoneIcon className={classes.doneIcon} />}</InputAdornment>,
                    }}
                  />
                  <TextField
                    fullWidth
                    className={classes.inputField}
                    label={message ? "Your Message" : "Type your Message"}
                    value={message}
                    onChange={e => setMessage(e.target.value)}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{message && <DoneIcon className={classes.doneIcon} />}</InputAdornment>,
                    }}
                  />
                  <p className={classes.messageCount}>
                    {message.length}/{maxMessageLength}
                  </p>
                </div>
              )}
            </Grid>
            {showDealerMessage && (
              <Grid item className={`${classes.message} ${classes.dealerMessage} ${classes.lastMessage}`}>
                <p className={classes.messageText}>
                  {error ? (
                    error
                  ) : (
                    <span>
                      <b>Message received!</b> You will receive a text from:<b> {formatString("+1(999) 999-9999", dealerPhoneNumber)}</b>
                    </span>
                  )}
                </p>
              </Grid>
            )}
          </Grid>
          {showFooter && (
            <Grid item className={classes.chatMainContentFooter}>
              <Grid container justify="center">
                <Grid item xs={12}>
                  <p className={classes.mainContentText}>
                    By submitting, you authorize {dealerName} to send you text messages with offers & information to the number you provided. Consent is not a condition of purchase.
                  </p>
                </Grid>
                {isSentMessage || (
                  <Button color="primary" className={classes.sendButton} disabled={!isValidValues()} onClick={getDealerPhoneNumber}>
                    Send
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} className={classes.chatFooter}>
          <Grid container justify="center" alignItems="center" className={classes.companyLogoContainer}>
            <a href="http://dcr.ai/" target="_blank">
              <img src={TrnsactLogo} alt="Logo" className={classes.companyLogo} />
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Slide>
  );
};

import React from "react";
import { FormattedMessage } from "react-intl";
import defaultMessage from "./components/Message";
import _ from "lodash";

export const getURLParameter = (paramKey, defaultValue) => {
  const paramValue = new URL(window.location.href).searchParams.get(paramKey);
  if (paramValue) return paramValue;
  if (defaultValue) return defaultValue;
  return undefined;
};

export const hexToRgb = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export function DeltaTimer(render, interval) {
  var timeout;
  var lastTime;

  this.start = start;
  this.stop = stop;

  function start() {
    timeout = setTimeout(loop, 0);
    lastTime = +new Date();
    return lastTime;
  }

  function stop() {
    clearTimeout(timeout);
    return lastTime;
  }

  function loop() {
    var thisTime = +new Date();
    var deltaTime = thisTime - lastTime;
    var delay = Math.max(interval - deltaTime, 0);
    timeout = setTimeout(loop, delay);
    lastTime = thisTime + delay;
    render(thisTime);
  }
}

export const delay = (millisecs = 1000) => {
  return new Promise(resolve => {
    setTimeout(resolve, millisecs);
  });
};

export function loadJSorCSSfile(fileName, fileType, isAsync) {
  let fileref;
  if (fileType === "js") {
    //if filename is a external JavaScript file
    fileref = document.createElement("script");
    fileref.setAttribute("type", "text/javascript");
    fileref.setAttribute("src", fileName);
    if (isAsync) {
      fileref.setAttribute("async", "");
    }
  } else if (fileType === "css") {
    //if filename is an external CSS file
    fileref = document.createElement("link");
    fileref.setAttribute("rel", "stylesheet");
    fileref.setAttribute("type", "text/css");
    fileref.setAttribute("href", fileName);
    if (isAsync) {
      fileref.setAttribute("async", "");
    }
  }
  if (typeof fileref != "undefined") document.getElementsByTagName("head")[0].appendChild(fileref);
}

export const autoFillValues = [
  { field: "amountRequested", value: "10000" },
  { field: "firstName", value: "Jonathan" },
  { field: "lastName", value: "Consumer" },
  { field: "email", value: "jconsumer@dealercreditresources.com" },
  { field: "phone", value: "111-111-1111" },
  { field: "businessName", value: "Testing Business" },
  { field: "address", value: "123 Jamboree" },
  { field: "city", value: "Irvine" },
  { field: "state", value: "CA" },
  { field: "postalCode", value: "92602" },
  { field: "legalStructure", value: "803370000" },
  { field: "tib", value: "803370007" },
  { field: "owners[0].firstName", value: "Henry" },
  { field: "owners[0].lastName", value: "Consumer" },
  { field: "owners[0].address", value: "12 Elm Street" },
  { field: "owners[0].city", value: "Dallas" },
  { field: "owners[0].state", value: "TX" },
  { field: "owners[0].postalCode", value: "75202" },
  { field: "owners[0].email", value: "hconsumer@dealercreditresources.com" },
  { field: "owners[0].percOwner", value: "100" },
  { field: "owners[0].ssn", value: "111-22-3333" },
];

export const requiredDOB = value => {
  if (value) {
    if (value.length !== 10) {
      return <FormattedMessage id="validDateFormat" defaultMessage={defaultMessage.validDateFormat} />;
    }
    var matches = /^(\d{1,2})[-\/](\d{1,2})[-\/](\d{4})$/.exec(value); //https://stackoverflow.com/questions/276479/javascript-how-to-validate-dates-in-format-mm-dd-yyyy
    if (matches == null) return false;
    var d = matches[2];
    var m = matches[1] - 1;
    var y = matches[3];

    const minimumYear = new Date().getFullYear() - 100;
    const maximumYear = new Date().getFullYear();

    var composedDate = new Date(y, m, d);

    const isValidRange = y >= minimumYear && y <= maximumYear;

    if (isValidRange && composedDate.getDate() == d && composedDate.getMonth() == m && composedDate.getFullYear() == y) {
      //Date is less then current year and greater then current year - 100 years is valid value
      return undefined;
    } else {
      return <FormattedMessage id="validDate" defaultMessage={defaultMessage.validDate} />;
    }
  } else {
    return <FormattedMessage id="validDate" defaultMessage={defaultMessage.validDate} />;
  }
};

export const removeNonEnglishChars = payload => {
  return JSON.parse(
    JSON.stringify(payload)
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
  );
};

export const emitGAEvent = (eventName, eventDetails) => {
  if (window.gtag !== undefined) {
    window.gtag("event", eventName, eventDetails);
  } else {
    // may be gtag was not loaded yet? wait 5 seconds and try again
    setTimeout(() => {
      if (window.gtag !== undefined) {
        window.gtag("event", eventName, eventDetails);
      }
    }, 5 * 1000);
  }
};

export const convertToDate = dateString => {
  let newDate = new Date(dateString);
  let dd = newDate.getDate();
  let mm = newDate.getMonth() + 1;
  let yyyy = newDate.getFullYear();
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;
  return `${mm}-${dd}-${yyyy}`;
};

export const requiredDLExp = value => {
  if (value) {
    if (value.length !== 10) {
      return <FormattedMessage id="validDateFormat" defaultMessage={defaultMessage.validDateFormat} />;
    }
    var matches = /^(\d{1,2})[-\/](\d{1,2})[-\/](\d{4})$/.exec(value); //https://stackoverflow.com/questions/276479/javascript-how-to-validate-dates-in-format-mm-dd-yyyy
    if (matches == null) return false;
    var d = matches[2];
    var m = matches[1] - 1;
    var y = matches[3];

    const minimumYear = new Date().getFullYear() - 100;
    const maximumYear = new Date().getFullYear() + 100;

    var composedDate = new Date(y, m, d);

    const isValidRange = y >= minimumYear && y <= maximumYear;

    if (isValidRange && composedDate.getDate() == d && composedDate.getMonth() == m && composedDate.getFullYear() == y) {
      //Date is less then current year and greater then current year - 100 years is valid value
      return undefined;
    } else {
      return <FormattedMessage id="validDate" defaultMessage={defaultMessage.validDate} />;
    }
  } else {
    return <FormattedMessage id="validDate" defaultMessage={defaultMessage.validDate} />;
  }
};

export const sanitizeString = str => {
  str = str.replace(/[^a-z0-9áéíóúñü \.,_-]/gim, "");
  return str.trim();
};

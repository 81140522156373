/** @jsx jsx */
import React, { useContext } from "react";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { jsx, css } from "@emotion/core";
import VendorContext from "../../vendorContext";

const BlueFormControl = withStyles({
  root: {
    "& .MuiInputLabel-root": {
      color: "#1473E6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#1473E6",
        opacity: "0.23",
      },
    },
    "& .MuiMenu-paper": {
      maxHeight: "150px",
    },
  },
})(FormControl);

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuPaper: {
    maxHeight: 200,
  },
}));

const FormHelperTextWrapper = ({ input: { name, value, onChange, ...restInput }, meta, label, formControlProps, ...rest }) => {
  const { secColor: vendorColor } = useContext(VendorContext);
  const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;
  const classes = useStyles();

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  return (
    <BlueFormControl
      css={css`
        .MuiInputLabel-root {
          color: ${vendorColor};
        }
        ,
        .MuiOutlinedInput-root > fieldset {
          border-color: ${vendorColor};
        }
        ,
      `}
      variant="outlined"
      {...formControlProps}
      error={showError}>
      <InputLabel ref={inputLabel} htmlFor={name}>
        {label}
      </InputLabel>

      <Select {...rest} name={name} onChange={onChange} inputProps={restInput} value={value} labelWidth={labelWidth} MenuProps={{ classes: { paper: classes.menuPaper } }} />

      {showError && <FormHelperText>{meta.error || meta.submitError}</FormHelperText>}
    </BlueFormControl>
  );
};

export default FormHelperTextWrapper;

import React, { useEffect, useRef, useState } from "react";
import "react-google-places-autocomplete/dist/index.min.css";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  googlePlacesStyle: {
    "& .google-places-autocomplete__input": {
      display: "block",
      borderRadius: 4,
      border: "1px solid #1473e640",
      height: "calc(1.1875em + 37px)",
      padding: "18.5px 14px",
      fontSize: 16,
      boxShadow: "none",
      boxSizing: "border-box",
      width: "100%",
      "&::-webkit-input-placeholder": {
        color: "#1473e6",
      },
    },
    "& .google-places-autocomplete__suggestions-container": {
      backgroundColor: "#fff",
      boxShadow: "0 2px 6px rgba(0,0,0,.3)",
      position: "absolute",
      zIndex: 10,
      padding: 15,
    },
    "& .google-places-autocomplete__suggestion ": {
      padding: "10px 0",
      cursor: "pointer",
    },
    "& .MuiInputLabel-root": {
      color: "#1473E6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#1473E6",
        opacity: "0.23",
      },
    },
    "& legend": {
      padding: "0",
      textAlign: "left",
      transition: "width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      lineHeight: "11px",
    },
    "& .PrivateNotchedOutline-root": {
      top: -5,
      left: 0,
      right: 0,
      bottom: 0,
      margin: 0,
      padding: 0,
      position: "absolute",
      transition: "padding-left 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms," + "border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms," + "border-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      borderStyle: "solid",
      borderWidth: 1,
      borderRadius: 4,
      pointerEvents: "none",
    },
  },
  dropdownList: {
    position: "absolute",
    width: "100%",
    backgroundColor: "#fff",
    top: "100%",
    borderRadius: 4,
    zIndex: 2,
    boxShadow: "0 2px 6px rgba(0,0,0,.5)",
  },
  dropdownListItem: {
    padding: 5,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ddd",
    },
  },
});

function useOutsideAlerter(ref, func) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        func();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export const GooglePlacesAutocompleteBlock = props => {
  const classes = useStyles();
  const { setCity, setCounty, setState, setPostalCode, setAddress, labelText, fieldName } = props;
  const [onlyOne, setOnlyOne] = useState(false);
  const [labelShrink, setLabelShrink] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownList, setDropdownList] = useState([]);
  const [appliedAddress, setAppliedAddress] = useState();
  const [currentAddress, setCurrentAddress] = useState("");

  let autocompleteService;
  let autocompleteCallback;
  let fetchPredictions;

  const setPlace = ({ address, setCity, setCounty, setState, setPostalCode, setAddress }) => {
    const link = "https://maps.googleapis.com/maps/api/geocode/json?address=" + address + "&key=AIzaSyC_qXNn05VHZnHJJwnX6m3Sg-Q3fBwvOdA&token=52992&types=postal_code";
    axios
      .get(link)
      .then(response => {
        const addressInfo = response.data.results[0].address_components;
        addressInfo.forEach(item => {
          item.types.forEach(type => {
            switch (type) {
              case "locality":
                setCity(item.long_name);
                break;
              case "administrative_area_level_1":
                setState(item.short_name);
                break;
              case "administrative_area_level_2":
                setCounty(item.long_name);
                break;
              case "postal_code":
                setPostalCode(item.long_name);
                break;
              default:
                break;
            }
          });
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  const clearFields = () => {
    setCity("");
    setState("");
    setCounty("");
    setPostalCode("");
  };

  const handleSelect = item => {
    clearFields();
    setPlace({ address: item.description, setAddress, setCity, setCounty, setPostalCode, setState });
    setAppliedAddress(item.structured_formatting.main_text);
    setCurrentAddress(item.structured_formatting.main_text);
    document.getElementById("autocomplete").value = item.structured_formatting.main_text;
    setShowDropdown(false);
  };

  const initAddressInput = () => {
    autocompleteService = new window.google.maps.places.AutocompleteService(document.getElementById("autocomplete"), {});
    autocompleteCallback = function (predictions, status) {
      if (status === "OK") {
        clearFields();
        if (predictions.length < 2) {
          const address = predictions[0].description;
          setPlace({ address, setAddress, setCity, setCounty, setPostalCode, setState });
          if (!onlyOne) {
            document.getElementById("autocomplete").value = predictions[0].structured_formatting.main_text;
            setAppliedAddress(predictions[0].structured_formatting.main_text);
            setCurrentAddress(predictions[0].structured_formatting.main_text);
          }
          setOnlyOne(true);
          setShowDropdown(false);
        } else {
          setOnlyOne(false);
          if (predictions.length) {
            setDropdownList(predictions);
            setShowDropdown(true);
          }
        }
      }
    };
    fetchPredictions = function (value) {
      if (value.length) {
        setLabelShrink(true);
        autocompleteService.getPlacePredictions({ input: value }, autocompleteCallback);
      } else {
        setLabelShrink(false);
        setOnlyOne(false);
      }
    };
  };

  useEffect(() => {
    initAddressInput();
  }, []);

  const hideList = () => {
    setShowDropdown(false);
  };

  const labelLength = labelText.length * 6 + 10;
  const labelClass = "MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-outlined";

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, hideList);

  return (
    <div className={classes.googlePlacesStyle} ref={wrapperRef}>
      <div className={"MuiFormControl-root MuiTextField-root MuiFormControl-fullWidth"}>
        <label className={labelClass + (labelShrink ? " MuiInputLabel-shrink" : "")}>{labelText}</label>
        <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl">
          <input
            className="MuiInputBase-input MuiOutlinedInput-input"
            name={fieldName}
            type="text"
            id="autocomplete"
            onChange={e => {
              setAddress(e.target.value);
              setCurrentAddress(e.target.value);
              if (typeof fetchPredictions !== "function") {
                initAddressInput();
              }
              fetchPredictions(e.target.value);
            }}
          />
          <fieldset className="PrivateNotchedOutline-root MuiOutlinedInput-notchedOutline" style={{ paddingLeft: 8 }}>
            <legend className="" style={{ width: labelShrink ? labelLength : 0 }}>
              <span>&#8203;</span>
            </legend>
          </fieldset>
        </div>
        <div className={classes.dropdownList}>
          {appliedAddress !== currentAddress &&
            showDropdown &&
            dropdownList.length &&
            dropdownList.map((item, index) => (
              <div className={classes.dropdownListItem} key={index} onClick={e => handleSelect(item)}>
                {item.description}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

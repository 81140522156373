import React, { useContext, useEffect, useState } from "react";
import { getURLParameter, convertToDate } from "../utils";
import { useQuery, useMutation } from "@apollo/react-hooks";
import Grid from "@material-ui/core/Grid";
import gql from "graphql-tag";
import { calculateRatesAndPayments } from "../utils/paymentSelectorMath";

const config = require("../config");

const GET_PROPOSAL = gql`
  query proposal($dynamicsId: ID, $id: ID) {
    proposal(dynamicsId: $dynamicsId, id: $id) {
      productId
      rate
      riskTier
      amountRequested
      markupInternal
      markupDealer
      bizName
      proposalStage
      businessName
      creditProductIds
      vendorOpportunityId
      accountId
      status
      createdDateTime
      gbbCalculatedOptions
      gbbProposedTerms
      residual
      type
      equipmentId
      quantity
      newUsed
      year
      make
      model
      additionalDescription
      equipmentPictureUrl
      serialNumberVin
      stockId
      mileage
      gbbProposedApr
      createdDateTime
      lenderType
      externalLinkStepOne
    }
  }
`;

const ClickLeaseBank = () => {
  const [propId] = useState(getURLParameter("propId"));
  const [proposalId] = useState(getURLParameter("proposalId"));
  const [vendorGUID] = useState(getURLParameter("vendorGUID"));
  const proposalProps = useQuery(GET_PROPOSAL, { variables: { dynamicsId: propId, id: proposalId } });

  if (proposalProps.loading) {
    return null;
  }
  if (!propId && !proposalId) {
    return (window.location.href = "/bankUploadError/");
  }

  if (proposalProps.loading) {
    return null;
  }
  if (proposalProps.error) {
    return (window.location.href = "/bankUploadError/");
  }
  if (!proposalProps.data) {
    return (window.location.href = "/bankUploadError/");
  }
  if (!proposalProps.data.proposal) {
    return (window.location.href = "/bankUploadError/");
  }

  return (
    <Grid container>
      <iframe src={proposalProps.data.proposal.externalLinkStepOne} height="900" max-height="1300px" width="100%" frameBorder="0"></iframe>
    </Grid>
  );
};
export default ClickLeaseBank;

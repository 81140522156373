import React, { useContext } from "react";
import styles from "./OptionCard.module.css";
import fonts from "../../fonts.module.css";
import { hexToRgb } from "../../utils";
import VendorContext from "../../vendorContext";

const OptionCard2 = ({ payment, term, rate, handleClick, rateCheck, thisProposalIsFastTracked, paymentSelectorOptionsForThisCard }) => {
  const { color: vendorColor } = useContext(VendorContext);
  let rgbColor = null;
  const rgbObj = hexToRgb(vendorColor);
  if (!!vendorColor) {
    rgbColor = `rgba(${rgbObj.r}, ${rgbObj.g}, ${rgbObj.b}, 0.3)`;
  }
  const showInterestRate = paymentSelectorOptionsForThisCard ? paymentSelectorOptionsForThisCard.showInterestRate : false;

  // console.log(`Option2Card: rate: ${rate} | rateCheck: ${rateCheck} | paymentSelectorOptionsForThisCard: ${JSON.stringify(paymentSelectorOptionsForThisCard)}`);

  return (
    <React.Fragment>
      {rateCheck > 15 ? (
        <div className={styles.card} style={{ backgroundColor: rgbColor }} onClick={handleClick}>
          <p className={fonts.h4}>${payment}/mo.</p>
          <p className={styles.greyText}>for {term} months</p>
          <p>
            <strong>See Details</strong>
          </p>
        </div>
      ) : (
        <div className={styles.card} style={{ backgroundColor: rgbColor }} onClick={handleClick}>
          <p className={fonts.h4}>${payment}/mo.</p>
          <p className={styles.greyText}>
            for {term} months {thisProposalIsFastTracked || !showInterestRate ? "" : `at ${rate}%`}
          </p>
          <p>
            <strong>See Details</strong>
          </p>
        </div>
      )}
    </React.Fragment>
  );
};

export default OptionCard2;

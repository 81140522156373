import React, { useContext } from "react";
import styles from "./CreditApplicationWrapper.module.css";
import VendorContext from "../vendorContext";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import fonts from "../fonts.module.css";

const config = require("../config");

const stepLabels = ["Credit Application", "Payment Selection", "Confirmation"];

const Confirmed = () => {
  const { logo } = useContext(VendorContext);

  return (
    <React.Fragment>
      {logo ? (
        <div className={styles.vendorLogoWrapper}>
          <img
            src={logo && logo.includes("/") ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${logo}` : `https://credit-app-images.s3-us-west-2.amazonaws.com/${logo}`}
            alt="Vendor Logo"
          />
        </div>
      ) : (
        ""
      )}
      <p className={[fonts.pGrey, fonts.h5, styles.stepperTitle].join(" ")} style={{ textAlign: "center" }}>
        Modern Finance Process
      </p>
      <Stepper activeStep={2} alternativeLabel>
        {stepLabels.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div style={{ height: "28px" }} />
      <div style={{ textAlign: "center" }}>
        {/* Term Selection Complete
        <br></br> */}
        Your term selection has been received.
        <br></br>
      </div>
      {/* <br />
      <div style={{ textAlign: "center" }}>Below is an example of the documents you will receive.</div>
      <div style={{ height: "28px" }} />
      <img
        style={{ border: "1px solid #021a40", padding: "1px" }}
        src={`https://credit-app-images.s3-us-west-2.amazonaws.com/adobe-sign-email-dcr.png`}
        alt="Example of docs to be signed"
        className={styles.docsImage}
      /> */}
    </React.Fragment>
  );
};

export default Confirmed;

import React, { useState, useEffect } from "react";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import Button from "@material-ui/core/Button";
import { Form } from "react-final-form";
import { FormattedMessage, useIntl } from "react-intl";

import defaultMessage from "../components/Message";
import { CircularProgress, Grid } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import _ from "lodash";
const apiURL = (process.env.REACT_APP_DCR_API_BASE_SERVER_URL || "https://api.dev.dcrportal.com") + "/v1/graphql";
// const apiURL = "http://localhost:3000/v1/graphql";

function FileUpload(props) {
  const UPLOAD_FILE = `
    mutation($files: Upload) {
      upsertFile(files: $files)
    }
  `;

  const { file, setFile, ownerId, selectedFiles, setSelectedFiles, ind, setOwnerOK, hideIcon, skipSubmitOnChange, ownerOK } = props;

  const fields = {};
  const setFields = {};

  [fields.file, setFields.file] = useState({
    value: null,
    validations: [{ type: "required" }],
  });
  [fields.logo, setFields.logo] = useState({ value: null });

  var fileToUpload;

  return (
    <Form
      onSubmit={() => {}}
      render={({ handleSubmit }) => {
        function uploadButtonHandler(event) {
          const file = document.getElementById(`fileInput${ownerId.replace(/[^0-9+]/g, "")}${ind}`);
          file.click();
        }

        function uploadFileChanged(event) {
          if (event.target.files.length < 1 || !event.target.files) {
            return;
          }
          setSelectedFiles(event.target.files);
          fileToUpload = event.target.files;
          //handleSubmit(fileToUpload);
          const formData = new FormData();
          if (fileToUpload && fileToUpload.length > 0) {
            let fileStringMap = "{";
            let arrOfFiles = [];
            for (let i = 0; i < fileToUpload.length; i++) {
              if (i !== fileToUpload.length - 1) {
                fileStringMap += `"${JSON.stringify(i)}": ["variables.files.${JSON.stringify(i)}"],`;
              } else {
                fileStringMap += `"${JSON.stringify(i)}": ["variables.files.${JSON.stringify(i)}"]}`;
              }
              arrOfFiles.push(null);
            }

            formData.append(
              "operations",
              JSON.stringify({
                query: UPLOAD_FILE,
                variables: {
                  files: arrOfFiles,
                },
              })
            );
            formData.append("map", fileStringMap);
            for (let i = 0; i < fileToUpload.length; i++) {
              formData.append(JSON.stringify(i), fileToUpload[i]);
            }
          }

          fetch(apiURL, {
            method: "POST",
            body: formData,
          })
            .then(response => response.json())
            .then(response => {
              response && response.data && response.data.upsertFile && setFile([...file, ...response.data.upsertFile]);
            })
            .then(response => {
              setOwnerOK(true);
            })
            .catch(err => {
              window.NREUM.noticeError(err);
            });
        }

        function resetFileUpload() {
          setSelectedFiles([]);
          setOwnerOK(false);
        }

        return (
          <div>
            <form
              onSubmit={e => {
                e.preventDefault();
                onsubmit = "return false";
              }}
              id={`filePreviewForm${ownerId}${ind}`}>
              <Grid container>
                <Grid item xl={6} lg={6} md={6} sm={12}>
                  <input accept="image/*" id={`fileInput${ownerId.replace(/[^0-9+]/g, "")}${ind}`} type="file" style={{ display: "none" }} onChange={uploadFileChanged} />
                  <div style={{ display: "flex", flexWrap: "nowrap" }}>
                    {!ownerOK ? (
                      <Button className="formBtn" color="primary" variant="outlined" style={{ marginRight: 8, marginBottom: 8, minWidth: 203 }} onClick={uploadButtonHandler}>
                        <FormattedMessage id="chooseFile" defaultMessage={defaultMessage.chooseFile} />
                        <PhotoCameraIcon />
                      </Button>
                    ) : null}

                    {!_.isEmpty(selectedFiles) && !ownerOK ? (
                      <Grid container>
                        <Grid item>
                          <CircularProgress variant="indeterminate" />
                        </Grid>
                      </Grid>
                    ) : null}
                  </div>
                </Grid>
                {ownerOK ? (
                  <Grid item xl={12} lg={12} md={12} sm={12}>
                    <span style={{ display: "flex", flexWrap: "nowrap", cursor: "pointer" }} onClick={resetFileUpload}>
                      <CancelIcon color="error" /> Remove File
                    </span>
                  </Grid>
                ) : null}
              </Grid>
            </form>
          </div>
        );
      }}
    />
  );
}

export default FileUpload;

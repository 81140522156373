/** @jsx jsx */
import React, { useContext, useState, useEffect } from "react";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { TextField } from "final-form-material-ui";
import Select from "./components/Select";
import gql from "graphql-tag";
import { Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import CreditAppStyles from "./CreditApplicationForm.module.css";
import WizardFormHandler from "./WizardFormHandler";
import { parseCurrency, formatCurrency } from "../utils/stringFormatters";
import formatString from "format-string-by-pattern";
import styles from "./CreditApplicationForm.module.css";
import fonts from "../fonts.module.css";
import { jsx, css } from "@emotion/core";
import VendorContext from "../vendorContext";
import getCreditAppStatus from "../api/getCreditAppStatus";
import queryPublicAccount from "../api/queryPublicAccount";
import ProcessingScreen from "./ProcessingScreen";
import { getURLParameter } from "../utils";
import queryPublicAccountId from "../api/queryPublicAccountId";
import { useQuery } from "@apollo/react-hooks";
import { GooglePlacesAutocompleteBlock } from "../components/GooglePlacesAutocompleteBlock";
import { OnChange } from "react-final-form-listeners";
import { requiredDOB } from "../utils";
import { FormattedMessage } from "react-intl";
import defaultMessage from "../components/Message";
import { usStates } from "./OCAv3";

const CREATE_CREDIT_APPLICATION = gql`
  mutation ($creditApplication: CreateCreditApplicationInput!) {
    createCreditApplication(input: $creditApplication) {
      id
      success
      errorMessage
    }
  }
`;

const required = value => (value ? undefined : "Required");

const requiredEmail = value =>
  !!value && /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(value) ? undefined : (
    <FormattedMessage id="requiredValidEmail" defaultMessage={defaultMessage.requiredValidEmail} />
  );

const upperCaseFirstLetters = value => {
  let formatedValue = value.split("");

  formatedValue = formatedValue.map((letter, i) => {
    if (i === 0 || formatedValue[i - 1] === " " || formatedValue[i - 1] === ".") {
      return letter.toUpperCase();
    }

    return letter.toLowerCase();
  });
  formatedValue = formatedValue.join("");

  return formatedValue;
};

const requiredTaxID = value => (!!value && value.replace("-", "").length === 9 ? undefined : "9 digits are required for Tax Id");

const BlueTextField = withStyles({
  root: {
    "& .MuiInputLabel-root": {
      color: "#1473E6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#1473E6",
        opacity: "0.23",
      },
    },
  },
})(TextField);

const useStyles = makeStyles({
  hidden: {
    display: "none !important",
  },
});

const GET_CONTACT = gql`
  query contact($dynamicsId: ID!) {
    contact(dynamicsId: $dynamicsId) {
      email
      firstName
      lastName
      fullName
      title
      phoneNumber
      vendorContactRole
      mugshot
      accountId
    }
  }
`;

const TruckCreditApplicationForm = props => {
  const classes = useStyles();
  const { color: vendorColor } = useContext(VendorContext);
  const vendorStyle = useContext(VendorContext);
  const [createCreditApp] = useMutation(CREATE_CREDIT_APPLICATION);
  const [isLoading, setLoading] = useState(false);
  const [proceedToPaymentSelector, setProceedToPaymentSelector] = useState(false);
  const vendorGUID = getURLParameter("vendorGUID");
  const repId = getURLParameter("repId");
  const transactionGUID = getURLParameter("transactionGUID");
  const vendorcontactId = getURLParameter("vendorcontactId");
  const [isQueryPublicAccount, setQueryPublicAccount] = useState(false);
  const [industryGroupType, setIndustryGroupType] = useState("");
  const [allSalesReps, setAllSalesReps] = useState("");
  const [contactId] = useState(getURLParameter("vendorcontactId"));
  const [entityType, setEntityType] = useState("");
  const demoValues = props.demo;
  const stockId = getURLParameter("stockId");
  const equipmentDescription = getURLParameter("equipmentDescription");
  const price = getURLParameter("price");
  const first = getURLParameter("firstName");
  const last = getURLParameter("lastName");
  const phoneNum = getURLParameter("phone");
  const bizName = getURLParameter("businessName");
  let salesReps = [];
  const [submitting, setSubmitting] = useState(false);
  const setRepData = props.setRepData;
  const [localSalesRepId, setLocalSalesRepId] = useState();

  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [county, setCounty] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [addressHome, setAddressHome] = useState("");
  const [cityHome, setCityHome] = useState("");
  const [stateHome, setStateHome] = useState("");
  const [countyHome, setCountyHome] = useState("");
  const [postalCodeHome, setPostalCodeHome] = useState("");

  const ocaForm = props.ocaForm;
  const requiredState = value => (!!value && value.length < 3 ? undefined : "Please enter state as 2 letter abbreviation");
  const requiredSSN = value => (!!value && value.length === 9 ? undefined : "9 Digits Are Required ");

  const [getSaleRep, saleRepResponse] = useLazyQuery(GET_CONTACT, {
    variables: { dynamicsId: localSalesRepId },
  });

  useEffect(() => {
    if (localSalesRepId) {
      getSaleRep();
    }
  }, [localSalesRepId]);

  useEffect(() => {
    if (saleRepResponse.data) {
      setRepData({
        officePhone: saleRepResponse.data.contact.phoneNumber,
        email: saleRepResponse.data.contact.email,
        name: saleRepResponse.data.contact.fullName,
        availability: saleRepResponse.data.contact.availability || "Monday-Friday, 8am-5pm PST",
        mugshot: saleRepResponse.data.contact.mugshot,
      });
    }
  }, [saleRepResponse.data]);

  if (contactId) {
    const getContact = useQuery(GET_CONTACT, {
      variables: { dynamicsId: contactId },
    });

    if (getContact.loading) {
      return null;
    }

    if (getContact.data) {
      if (!vendorGUID && !isQueryPublicAccount) {
        setQueryPublicAccount(true);
        if (getContact.data.contact) {
          queryPublicAccountId(getContact.data.contact.accountId).then(response => {
            props.setVendor.setVendor(response.data.data.publicAccount.dynamicsAccountId, response.data.data.publicAccount.vendorProfile);
            try {
              setProceedToPaymentSelector(response.data.data.publicAccount.vendorProfile.paymentSelectorAutomatic);
              setIndustryGroupType(response.data.data.publicAccount.vendorProfile.industryGroupType);
            } catch (error) {
              console.error(error);
            }
          });
        }
      }
    }
  }

  if (vendorGUID && !isQueryPublicAccount) {
    setQueryPublicAccount(true);
    queryPublicAccount(vendorGUID.toLowerCase()).then(response => {
      try {
        if (response.data.data.publicAccount) {
          if (response.data.data.publicAccount.vendorProfile) {
            setProceedToPaymentSelector(response.data.data.publicAccount.vendorProfile.paymentSelectorAutomatic);
            setIndustryGroupType(response.data.data.publicAccount.vendorProfile.industryGroupType);
          }
          setAllSalesReps(response.data.data.publicAccount.salesReps);
        }
      } catch (error) {
        console.error(error);
      }
    });
  }
  const setRepArray = () => {
    if (allSalesReps) {
      salesReps = allSalesReps.map(item => {
        try {
          item.value = item.dynamicsContactId;
          if (item.title == null) {
            item.label = item.fullName;
          } else {
            item.label = item.fullName + ", " + item.title;
          }
          return item;
        } catch (err) {
          console.error(err);
          return null;
        }
      });
    }
  };

  const determineChannel = () => {
    if (vendorGUID) return "Dealer";
    if (vendorcontactId) return "VSP";
    if (repId) return "Rep";
    if (transactionGUID) return "Transaction";
    return "";
  };

  setRepArray();

  const onSubmit = async values => {
    setSubmitting(true);
    if (demoValues === "standard") {
      setLoading(true);
      if (!vendorGUID) {
        await setTimeout(function () {
          return (window.location.href = "/ps/?&propId=5eecc379-1434-ea11-8114-005056b05a0f");
        }, 10000);
      } else {
        await setTimeout(function () {
          return (window.location.href = "/ps/?&propId=5eecc379-1434-ea11-8114-005056b05a0f&vendorGUID=" + vendorGUID);
        }, 10000);
      }
    } else {
      const submitValues = {
        ...values,
        repId,
        draft: false,
        vendorGUID,
        dynamicsContactId: vendorcontactId,
        transactionGUID,
        ssn: values.owners[0].ssn,
        percOwner: !isNaN(parseInt(values.owners[0].percOwner)) ? parseInt(values.owners[0].percOwner) : null,
        dateEstablished: values.month + "-" + "01" + "-" + values.year,
        stockId: stockId,
        ocaType: `Simple - Trucking - ${determineChannel()}`,
      };
      // submitValues[`${name}.homeAddress`] = addressHome;
      delete submitValues.month;
      delete submitValues.year;
      if (!submitValues.vendorGUID) {
        delete submitValues.vendorGUID;
      }
      if (!submitValues.repId || submitValues.vendorGUID) {
        delete submitValues.repId;
      }

      if (submitValues.salesRep) {
        delete submitValues.salesRep;
      }
      if (!submitValues.dynamicsContactId && submitValues.salesRepId) {
        submitValues.dynamicsContactId = submitValues.salesRepId;
        delete submitValues.salesRepId;
      }

      if (!!submitValues.salesRepId) {
        delete submitValues.salesRepId;
      }

      if (submitValues.ssnExempt) {
        submitValues.ssnExempt = JSON.parse(values.ssnExempt);
      }
      if (submitValues.ssnExempt === "true" && (entityType === "803370001" || entityType === "803370002" || entityType === "803370003" || entityType === "100000000")) {
        submitValues.ssn = null;
        submitValues.owners[0].percOwner = null;
        submitValues.percOwner = null;
      }
      if (submitValues.homeSameAsBiz) {
        submitValues.homeSameAsBiz = JSON.parse(values.homeSameAsBiz);
      }
      if (submitValues.taxId) {
        submitValues.taxId = parseInt(submitValues.taxId);
      }
      if (submitValues.monthlyIncome) {
        submitValues.monthlyIncome = parseFloat(submitValues.monthlyIncome);
      }
      if (submitValues.annualRevenue) {
        submitValues.annualRevenue = parseFloat(submitValues.annualRevenue);
      }
      if (typeof submitValues.amountRequested !== "number") {
        submitValues.amountRequested = parseFloat(submitValues.amountRequested.replace(/[^0-9.]/g, ""));
      }
      if (proceedToPaymentSelector) {
        setLoading(true);
        submitValues.amountRequested = parseFloat(submitValues.amountRequested);
        await createCreditApp({
          variables: {
            creditApplication: {
              ...submitValues,
            },
          },
        })
          .then(resp => {
            const appId = resp.data.createCreditApplication.id;
            const appSuccess = resp.data.createCreditApplication.success;
            if (appSuccess === false) {
              if (!vendorGUID) {
                setTimeout(function () {
                  return (window.location.href = "/dedupe");
                }, 10000);
              }
              setTimeout(function () {
                return (window.location.href = "/dedupe/?vendorGUID=" + vendorGUID);
              }, 10000);
            }
            if (!vendorGUID) {
              setTimeout(function () {
                return (window.location.href = "/receipt/");
              }, 10000);
            }
            setTimeout(function () {
              return (window.location.href = "/receipt/?vendorGUID=" + vendorGUID);
            }, 10000);
            let retry = (() => {
              let count = 0;
              return (max, timeout, next) => {
                getCreditAppStatus(appId).then((response, error, body) => {
                  if (!response.data.data.creditApplication) {
                    window.location.href = "/error/?vendorGUID=" + vendorGUID;
                  }
                  if (error || response.status !== 200 || !response.data.data.creditApplication.prequalStatus) {
                    console.log("pending...");
                    if (count++ < max) {
                      return setTimeout(() => {
                        retry(max, timeout, next);
                      }, timeout);
                    } else {
                      console.log("pending error");
                      if (!vendorGUID) {
                        window.location.href = "/error/";
                      }
                      window.location.href = "/error/?vendorGUID=" + vendorGUID;
                      return next(new Error("max retries reached"));
                    }
                  }
                  console.log("success");
                  next(response, body);
                });
              };
            })();
            const maxTries = 10;
            const timeInterval = 5000;
            retry(maxTries, timeInterval, function (response, body) {
              let { prequalStatus, paymentSelectorURLV2 } = response.data.data.creditApplication;
              if (values.owners[0].ssn === "941-18-9841") {
                prequalStatus = "approved";
              }
              if (prequalStatus === "probation_review" || prequalStatus === "pending" || prequalStatus === "declined" || prequalStatus === "review_required") {
                return (window.location.href = "/receipt?vendorGUID=" + vendorGUID + "&repId=" + repId);
              } else if (prequalStatus === "approved" || prequalStatus === "pre_approved") {
                return (window.location.href = paymentSelectorURLV2);
              }
            });
          })
          .catch(error => {
            console.log(error);
            if (!vendorGUID) {
              window.location.href = "/error/";
            }
            window.location.href = "/error/?vendorGUID=" + vendorGUID;
          });
      } else {
        submitValues.amountRequested = parseFloat(submitValues.amountRequested);
        await createCreditApp({
          variables: {
            creditApplication: {
              ...submitValues,
            },
          },
        })
          .then(resp => {
            const appSuccess = resp.data.createCreditApplication.success;
            if (appSuccess === false) {
              if (!vendorGUID) {
                return (window.location.href = "/dedupe");
              }
              return (window.location.href = "/dedupe/?vendorGUID=" + vendorGUID);
            }
            if (!vendorGUID) {
              return (window.location.href = "/receipt/");
            }
            return (window.location.href = "/receipt/?vendorGUID=" + vendorGUID);
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  };

  const textFieldBoxColors = css`
    .MuiInputLabel-root {
      color: ${vendorColor};
    }
    .MuiOutlinedInput-root > fieldset {
      border-color: ${vendorColor};
    }
    &.stateSelect > fieldset {
      border-color: ${vendorColor} !important;
    }
  `;

  const textFieldBoxColorsSignature = css`
    .MuiInputLabel-root {
      color: ${vendorColor};
    }
    .MuiOutlinedInput-root > fieldset {
      border-color: ${vendorColor};
    }
    .MuiOutlinedInput-input {
      font-family: Homemade Apple, cursive;
      font-size: 30px;
    }
  `;

  const Condition = ({ when, is, children }) => (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => (value === is ? children : null)}
    </Field>
  );

  const Error = ({ name }) => (
    <Field name={name} subscription={{ error: true, touched: true }}>
      {({ meta: { error, touched } }) => (error && touched ? <span>{error}</span> : null)}
    </Field>
  );

  const initialValues = values => {
    if (values === "standard") {
      return {
        amountRequested: price ? price : 100,
        ccg_transaction_equipmentdescription: equipmentDescription ? equipmentDescription : "",
        firstName: first ? first : "Demo",
        lastName: last ? last : "Test",
        email: "ivan@dealercreditresources.com",
        phone: phoneNum ? phoneNum : "7141234567",
        businessType: "Demo Testing",
        businessName: bizName ? bizName : "Demo Business",
        dba: "DB Limited",
        bizPhone: "7894561230",
        year: 2002,
        month: 10,
        address: "123 Test Ave",
        city: "Test",
        state: "CA",
        postalCode: "90620",
        taxId: "123456789",
        annualRevenue: 100000000,
        ssnExempt: "true",
        legalStructure: "803370001",
        tib: "803370007",
        signature: "Demo Test",
        owners: [
          {
            firstName: "Demo",
            lastName: "Test",
            percOwner: 100,
            ssn: "000000000",
            title: "CEO",
            dateOfBirth: "",
          },
        ],
      };
    } else if (values === "demoeq") {
      return {
        amountRequested: price ? price : 100,
        ccg_transaction_equipmentdescription: equipmentDescription ? equipmentDescription : "",
        firstName: first ? first : "James",
        lastName: last ? last : "Nnbut",
        email: "ivan@dealercreditresources.com",
        phone: phoneNum ? phoneNum : "7141234567",
        businessType: "Demo Testing",
        businessName: bizName ? bizName : "Demo Business",
        dba: "DB Limited",
        bizPhone: "7894561230",
        year: 2002,
        month: 10,
        address: "6881 ZHRQYLLQ AVE",
        city: "CHARLOTTESVILLE",
        state: "VA",
        postalCode: "22903",
        taxId: "123456789",
        annualRevenue: 100000000,
        ssnExempt: "true",
        legalStructure: "803370001",
        tib: "803370007",
        signature: "Demo Test",
        owners: [
          {
            firstName: "James",
            lastName: "Nnbut",
            percOwner: 100,
            ssn: "666374150",
            title: "CEO",
            dateOfBirth: "",
          },
        ],
      };
    } else if (values === "demoex") {
      return {
        amountRequested: price ? price : 100,
        ccg_transaction_equipmentdescription: equipmentDescription ? equipmentDescription : "",
        firstName: first ? first : "Jonathon",
        lastName: last ? last : "Consumer",
        email: "ivan@dealercreditresources.com",
        phone: phoneNum ? phoneNum : "7141234567",
        businessType: "Demo Testing",
        businessName: bizName ? bizName : "Demo Business",
        dba: "DB Limited",
        bizPhone: "7894561230",
        year: 2002,
        month: 10,
        address: "10655 N BIRCH ST",
        city: "BURBANK",
        state: "CA",
        postalCode: "91502",
        taxId: "123456789",
        annualRevenue: 100000000,
        ssnExempt: "true",
        legalStructure: "803370001",
        tib: "803370007",
        signature: "Demo Test",
        owners: [
          {
            firstName: "Jonathon",
            lastName: "Consumer",
            percOwner: 100,
            ssn: "941189841",
            title: "CEO",
            dateOfBirth: "",
          },
        ],
      };
    } else {
      return {
        amountRequested: price ? price : null,
        ccg_transaction_equipmentdescription: equipmentDescription ? equipmentDescription : "",
        businessName: bizName ? bizName : "",
        firstName: first ? first : "",
        lastName: last ? last : "",
        phone: phoneNum ? phoneNum : "",
        owners: [
          {
            firstName: "",
            lastName: "",
            percOwner: null,
            ssn: "",
            dateOfBirth: "",
          },
        ],
      };
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <ProcessingScreen />
      ) : (
        <WizardFormHandler
          initialValues={initialValues(demoValues)}
          onSubmit={onSubmit}
          setEntityType={setEntityType}
          onSaveDraft={() => {
            return false;
          }}
          enableAutoSaveDraft={false}
          industryGroupType={industryGroupType}
          ocaForm={ocaForm}
          submitting={submitting}>
          <WizardFormHandler.Page>
            <Grid container spacing={4}>
              {!vendorcontactId && !repId && window.location.search.length > 0 && allSalesReps ? (
                <>
                  <Grid item xs={12} md={6} lg={6}>
                    <Field
                      component={Select}
                      formControlProps={{ fullWidth: true }}
                      label="Are you working with a Representative?"
                      name="salesRep"
                      variant="outlined"
                      css={textFieldBoxColors}
                      validate={required}>
                      <MenuItem value="yes">Yes</MenuItem>
                      <MenuItem value="no">No</MenuItem>
                    </Field>
                  </Grid>
                  <Condition when="salesRep" is="yes">
                    <Grid item xs={12} md={6} lg={6}>
                      {salesReps.length !== 0 ? (
                        <Field component={Select} formControlProps={{ fullWidth: true }} label="Representative" name="salesRepId" variant="outlined" validate={required}>
                          {salesReps.map(({ value, label }, i) => (
                            <MenuItem value={value} key={i} label={label}>
                              {label}
                            </MenuItem>
                          ))}
                          <OnChange name="salesRepId">
                            {(value, previous) => {
                              if (value !== previous) setLocalSalesRepId(value);
                            }}
                          </OnChange>
                        </Field>
                      ) : null}
                    </Grid>
                    <Error name="salesRep" />
                  </Condition>
                  <Condition when="salesRep" is="no">
                    <Grid item xs={12} md={6} lg={6} />
                  </Condition>
                </>
              ) : (
                <br />
              )}
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Field
                  component={BlueTextField}
                  css={textFieldBoxColors}
                  fullWidth
                  format={v => formatCurrency(v)}
                  label="Amount Requested"
                  name="amountRequested"
                  parse={v => parseCurrency(v)}
                  type="text"
                  validate={required}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Field
                  component={BlueTextField}
                  css={textFieldBoxColors}
                  fullWidth
                  label="Equipment Make and Model (if known)"
                  name="ccg_transaction_equipmentdescription"
                  type="text"
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </WizardFormHandler.Page>
          <WizardFormHandler.Page>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6} lg={6}>
                <Field
                  component={BlueTextField}
                  css={textFieldBoxColors}
                  fullWidth
                  label="First Name"
                  name="firstName"
                  type="text"
                  parse={v => upperCaseFirstLetters(v)}
                  validate={required}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Field
                  component={BlueTextField}
                  css={textFieldBoxColors}
                  fullWidth
                  label="Last Name"
                  name="lastName"
                  type="text"
                  parse={v => upperCaseFirstLetters(v)}
                  validate={required}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6} lg={6}>
                <Field component={BlueTextField} css={textFieldBoxColors} fullWidth label="Email" name="email" type="email" validate={requiredEmail} validate={required} variant="outlined" />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Field
                  component={BlueTextField}
                  css={textFieldBoxColors}
                  fullWidth
                  label="Best Phone Number"
                  name="phone"
                  parse={v => {
                    v = v.replace(/[^\d-() ]/, "");
                    return formatString("(999) 999-9999", v);
                  }}
                  type="text"
                  validate={required}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6} lg={6}>
                <Field
                  component={Select}
                  formControlProps={{ fullWidth: true }}
                  label="Legal Structure"
                  name="legalStructure"
                  variant="outlined"
                  parse={v => upperCaseFirstLetters(v)}
                  validate={required}>
                  {legalStructureOption.map(({ value, label }, i) => (
                    <MenuItem value={value} key={i}>
                      {label}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Field
                  component={BlueTextField}
                  css={textFieldBoxColors}
                  fullWidth
                  label="Industry Type"
                  name="businessType"
                  type="text"
                  validate={required}
                  parse={v => upperCaseFirstLetters(v)}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </WizardFormHandler.Page>
          {entityType === "803370001" || entityType === "803370002" || entityType === "803370003" || entityType === "100000000" ? (
            <WizardFormHandler.Page>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    component={BlueTextField}
                    css={textFieldBoxColors}
                    fullWidth
                    label="Legal Entity Name"
                    name="businessName"
                    type="text"
                    parse={v => upperCaseFirstLetters(v)}
                    validate={required}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Field component={BlueTextField} css={textFieldBoxColors} fullWidth label="DBA (if applicable)" name="dba" type="text" variant="outlined" />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} md={4} lg={6}>
                  <Field
                    component={BlueTextField}
                    css={textFieldBoxColors}
                    fullWidth
                    label="Business Phone Number"
                    name="bizPhone"
                    parse={v => {
                      v = v.replace(/[^\d-() ]/, "");
                      return formatString("(999) 999-9999", v);
                    }}
                    type="text"
                    validate={required}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Field component={Select} formControlProps={{ fullWidth: true }} label="Year Business Was Established" name="year" variant="outlined" validate={required}>
                    {DateCreate().map(({ value, label }, i) => (
                      <MenuItem value={value} key={i}>
                        {label}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                  <Field component={Select} formControlProps={{ fullWidth: true }} label="Month Business Was Established" name="month" variant="outlined" validate={required}>
                    {months.map(({ value, label }, i) => (
                      <MenuItem value={value} key={i}>
                        {label}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    component={BlueTextField}
                    css={textFieldBoxColors}
                    fullWidth
                    label="Business Address"
                    name="address"
                    type="text"
                    variant="outlined"
                    parse={v => upperCaseFirstLetters(v)}
                    validate={required}
                  />
                  {/* <GooglePlacesAutocompleteBlock
                    setCity={setCity}
                    setCounty={setCounty}
                    setState={setState}
                    setPostalCode={setPostalCode}
                    setAddress={setAddress}
                    required={true}
                    fieldName="address"
                    labelText="Business Address"/> */}
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    component={BlueTextField}
                    css={textFieldBoxColors}
                    fullWidth
                    label="City"
                    name="city"
                    type="text"
                    initialValue={city}
                    parse={v => upperCaseFirstLetters(v)}
                    validate={required}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    component={Select}
                    css={textFieldBoxColors}
                    formControlProps={{ fullWidth: true }}
                    label={<span style={{ color: vendorColor }}>State</span>}
                    name="state"
                    type="text"
                    initialValue={state}
                    validate={requiredState}
                    variant="outlined"
                    style={{ borderColor: vendorColor }}
                    className="stateSelect">
                    {usStates.map(({ value, label }, i) => (
                      <MenuItem value={value} key={i}>
                        {label}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    component={BlueTextField}
                    css={textFieldBoxColors}
                    fullWidth
                    label="Postal Code"
                    name="postalCode"
                    parse={v => {
                      v = v.toString().replace(/\D/g, "");
                      return !isNaN(v) && v.length <= 5 ? v : v.slice(0, -1);
                    }}
                    type="text"
                    initialValue={postalCodeHome}
                    validate={required}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </WizardFormHandler.Page>
          ) : (
            <WizardFormHandler.Page>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Field
                    component={BlueTextField}
                    css={textFieldBoxColors}
                    fullWidth
                    label="Business Name"
                    name="businessName"
                    type="text"
                    parse={v => upperCaseFirstLetters(v)}
                    validate={required}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    component={BlueTextField}
                    css={textFieldBoxColors}
                    fullWidth
                    label="Business Phone Number"
                    name="bizPhone"
                    parse={v => {
                      v = v.replace(/[^\d-() ]/, "");
                      return formatString("(999) 999-9999", v);
                    }}
                    type="text"
                    validate={required}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Field component={Select} formControlProps={{ fullWidth: true }} label="Year Business Was Established" name="year" variant="outlined" validate={required}>
                    {DateCreate().map(({ value, label }, i) => (
                      <MenuItem value={value} key={i}>
                        {label}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <Field component={Select} formControlProps={{ fullWidth: true }} label="Month Business Was Established" name="month" variant="outlined" validate={required}>
                    {months.map(({ value, label }, i) => (
                      <MenuItem value={value} key={i}>
                        {label}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
              </Grid>
              <div>
                <label>My business address is the same as my home address.</label>
                <div style={{ display: "inline-block" }}>
                  <label>
                    <Field name="homeSameAsBiz" component="input" type="radio" value="true" required /> Yes
                  </label>
                </div>
                <div style={{ display: "inline-block" }}>
                  <label>
                    <Field name="homeSameAsBiz" component="input" type="radio" value="false" /> No
                  </label>
                </div>
                <Error name="homeSameAsBiz" />
              </div>
              <br />
              <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    component={BlueTextField}
                    css={textFieldBoxColors}
                    fullWidth
                    label="Business Address"
                    name="address"
                    type="text"
                    parse={v => upperCaseFirstLetters(v)}
                    validate={required}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    component={BlueTextField}
                    css={textFieldBoxColors}
                    fullWidth
                    label="City"
                    name="city"
                    type="text"
                    initialValue={city}
                    parse={v => upperCaseFirstLetters(v)}
                    validate={required}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    component={Select}
                    css={textFieldBoxColors}
                    formControlProps={{ fullWidth: true }}
                    label={<span style={{ color: vendorColor }}>State</span>}
                    name="state"
                    type="text"
                    initialValue={state}
                    validate={requiredState}
                    variant="outlined"
                    style={{ borderColor: vendorColor }}
                    className="stateSelect">
                    {usStates.map(({ value, label }, i) => (
                      <MenuItem value={value} key={i}>
                        {label}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    component={BlueTextField}
                    css={textFieldBoxColors}
                    fullWidth
                    label="Postal Code"
                    name="postalCode"
                    parse={v => {
                      v = v.toString().replace(/\D/g, "");
                      return !isNaN(v) && v.length <= 5 ? v : v.slice(0, -1);
                    }}
                    type="text"
                    initialValue={postalCode}
                    validate={required}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </WizardFormHandler.Page>
          )}
          {entityType === "803370001" || entityType === "803370002" || entityType === "803370003" || entityType === "100000000" ? (
            <WizardFormHandler.Page>
              {({ push, pop }) => (
                <>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6} lg={6}>
                      <Field
                        component={BlueTextField}
                        label="Tax ID"
                        name="taxId"
                        css={textFieldBoxColors}
                        variant="outlined"
                        validate={requiredTaxID}
                        parse={v => {
                          v = v.replace(/\D/g, "");
                          return !isNaN(v) ? v : v.slice(0, -1);
                        }}
                        format={v => (v ? formatString("99-99999999", v) : "")}
                        type="text"
                        fullWidth></Field>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Field
                        component={BlueTextField}
                        css={textFieldBoxColors}
                        label="Annual Revenue"
                        format={v => formatCurrency(v)}
                        parse={v => parseCurrency(v)}
                        name="annualRevenue"
                        variant="outlined"
                        fullWidth></Field>
                    </Grid>
                  </Grid>
                  <div style={{ height: "30px" }} />
                  <hr className={styles.horizontalRule} style={{ borderColor: vendorStyle.vendorColor }} />
                  <h5 className={[fonts.h5, CreditAppStyles.h5Color].join(" ")} style={{ color: vendorColor }}>
                    Authorized Signer
                  </h5>
                  <div>
                    <label>
                      Does this business generates $10million or more in total sales <span style={{ textDecoration: "underline" }}>or</span> have more than 6 owners?
                    </label>
                    <div style={{ display: "inline-block" }}>
                      <label>
                        <Field name="ssnExempt" component="input" type="radio" value="true" required /> Yes
                      </label>
                    </div>
                    <div style={{ display: "inline-block" }}>
                      <label>
                        <Field name="ssnExempt" component="input" type="radio" value="false" required /> No
                      </label>
                    </div>
                    <Error name="ssnExempt" />
                  </div>
                  <br />
                  <FieldArray name="owners">
                    {({ fields }) => {
                      return fields.map((name, index) => (
                        <div key={index}>
                          <Grid container spacing={4}>
                            <Grid item xs={12} md={4} lg={4}>
                              <Field
                                component={BlueTextField}
                                css={textFieldBoxColors}
                                fullWidth
                                label="First Name"
                                name={`${name}.firstName`}
                                type="text"
                                parse={v => upperCaseFirstLetters(v)}
                                validate={required}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                              <Field
                                component={BlueTextField}
                                css={textFieldBoxColors}
                                fullWidth
                                label="Last Name"
                                name={`${name}.lastName`}
                                type="text"
                                parse={v => upperCaseFirstLetters(v)}
                                validate={required}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} md={4} lg={4}>
                              <Field
                                component={BlueTextField}
                                css={textFieldBoxColors}
                                fullWidth
                                label="Title"
                                name={`${name}.title`}
                                type="text"
                                parse={v => upperCaseFirstLetters(v)}
                                validate={required}
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                          <Condition when="ssnExempt" is="false">
                            <Grid container spacing={4}>
                              <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Field
                                  component={BlueTextField}
                                  css={textFieldBoxColors}
                                  format={v => (v ? v.toString() : "")}
                                  fullWidth
                                  label="Percent Ownership"
                                  name={`${name}.percOwner`}
                                  parse={v => {
                                    if (v > 100) v = v.toString().slice(0, -1);
                                    return !isNaN(parseInt(v)) ? parseInt(v) : null;
                                  }}
                                  validate={required}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Field
                                  component={BlueTextField}
                                  css={textFieldBoxColors}
                                  fullWidth
                                  label="Social Security"
                                  name={`${name}.ssn`}
                                  parse={v => {
                                    v = v.replace(/\D/g, "");
                                    return !isNaN(v) ? v : v.slice(0, -1);
                                  }}
                                  format={v => formatString("999-99-9999", v)}
                                  type="text"
                                  validate={requiredSSN}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Field
                                  component={BlueTextField}
                                  css={textFieldBoxColors}
                                  fullWidth
                                  label="Date of Birth (MM/DD/YYYY)"
                                  format={v => (v ? formatString("MM/DD/YYYY", v) : "")}
                                  name={`${name}.dateOfBirth`}
                                  type="text"
                                  validate={requiredDOB}
                                  variant="outlined"
                                />
                              </Grid>
                            </Grid>
                          </Condition>
                          <div style={{ padding: "20px 0 30px 0" }}>
                            {index === 0 ? (
                              <Button
                                className="formBtn"
                                color="primary"
                                variant="outlined"
                                onClick={() => {
                                  push("owners", undefined);
                                }}
                                disabled={fields.length >= 2 || (fields.value[0] && fields.value[0].percOwner === 100)}>
                                ADDITIONAL OWNERS
                              </Button>
                            ) : (
                              <Button className="formBtn" color="secondary" variant="outlined" onClick={() => pop("owners", undefined)}>
                                REMOVE OWNER
                              </Button>
                            )}
                          </div>
                        </div>
                      ));
                    }}
                  </FieldArray>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={12} lg={3} />
                    <Grid item xs={12} md={12} lg={6}>
                      <Field component={BlueTextField} css={textFieldBoxColorsSignature} fullWidth label="Type your name to sign" name="signature" type="text" validate={required} variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={12} lg={3} />
                  </Grid>
                </>
              )}
            </WizardFormHandler.Page>
          ) : (
            <WizardFormHandler.Page>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={6}>
                  <Field component={Select} formControlProps={{ fullWidth: true }} label="Industry Experience (in years)" name="industryExpYears" variant="outlined" validate={required}>
                    {years.map(({ value, label }, i) => (
                      <MenuItem value={value} key={i}>
                        {label}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    component={BlueTextField}
                    css={textFieldBoxColors}
                    fullWidth
                    format={v => formatCurrency(v)}
                    label="Gross Monthly Income (All income sources)"
                    name="monthlyIncome"
                    parse={v => parseCurrency(v)}
                    type="text"
                    validate={required}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={6}>
                  <Field
                    component={BlueTextField}
                    css={textFieldBoxColors}
                    fullWidth
                    label="Previous Truck Financing (if yes, please add lender)"
                    name="previousFinanceExp"
                    type="text"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Field component={Select} formControlProps={{ fullWidth: true }} label="Number of trucks owned" name="trucksOwned" variant="outlined" validate={required}>
                    {years.map(({ value, label }, i) => (
                      <MenuItem value={value} key={i}>
                        {label}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
              </Grid>
            </WizardFormHandler.Page>
          )}
          {entityType === "803370000" || entityType === "100000001" || entityType === "100000004" ? (
            <WizardFormHandler.Page>
              {({ push, pop }) => (
                <>
                  <FieldArray name="owners">
                    {({ fields }) => {
                      return fields.map((name, index) => (
                        <div key={index}>
                          <Grid container spacing={4}>
                            <Grid item xs={12} md={12} lg={4}>
                              <Field
                                component={BlueTextField}
                                css={textFieldBoxColors}
                                fullWidth
                                label="First Name"
                                name={`${name}.firstName`}
                                type="text"
                                parse={v => upperCaseFirstLetters(v)}
                                validate={required}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} md={12} lg={4}>
                              <Field
                                component={BlueTextField}
                                css={textFieldBoxColors}
                                fullWidth
                                label="Last Name"
                                name={`${name}.lastName`}
                                type="text"
                                parse={v => upperCaseFirstLetters(v)}
                                validate={required}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} md={12} lg={4}>
                              <Field component={BlueTextField} css={textFieldBoxColors} fullWidth label="Title" name={`${name}.title`} type="text" validate={required} variant="outlined" />
                            </Grid>
                          </Grid>
                          <Grid container spacing={4}>
                            <Grid item xs={12} md={12} lg={4}>
                              <Field
                                component={BlueTextField}
                                css={textFieldBoxColors}
                                format={v => (v ? v.toString() : "")}
                                fullWidth
                                label="Percent Ownership"
                                name={`${name}.percOwner`}
                                parse={v => {
                                  if (v > 100) {
                                    v = v.toString().slice(0, -1);
                                  }
                                  return parseInt(v);
                                }}
                                validate={required}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} md={12} lg={4}>
                              <Field
                                component={BlueTextField}
                                css={textFieldBoxColors}
                                fullWidth
                                label="Social Security"
                                name={`${name}.ssn`}
                                parse={v => {
                                  v = v.replace(/\D/g, "");
                                  return !isNaN(v) ? v : v.slice(0, -1);
                                }}
                                format={v => formatString("999-99-9999", v)}
                                type="text"
                                validate={requiredSSN}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={12} md={12} lg={4}>
                              <Field
                                component={BlueTextField}
                                css={textFieldBoxColors}
                                fullWidth
                                label="Date of Birth (MM/DD/YYYY)"
                                format={v => (v ? formatString("MM/DD/YYYY", v) : "")}
                                name={`${name}.dateOfBirth`}
                                type="text"
                                validate={requiredDOB}
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                          <Condition when="homeSameAsBiz" is="false">
                            <Grid container spacing={4}>
                              <Grid item xs={12} md={6} lg={6}>
                                <Field
                                  component={BlueTextField}
                                  css={textFieldBoxColors}
                                  fullWidth
                                  label="Home Address"
                                  name={`${name}.homeAddress`}
                                  type="text"
                                  parse={v => upperCaseFirstLetters(v)}
                                  validate={required}
                                  variant="outlined"
                                />
                                {/* <GooglePlacesAutocompleteBlock
                                  setCity={setCityHome}
                                  setCounty={setCountyHome}
                                  setState={setStateHome}
                                  setPostalCode={setPostalCodeHome}
                                  setAddress={setAddressHome}
                                  required={true}
                                  fieldName={`${name}.homeAddress`}
                                  labelText="Home Address"/> */}
                              </Grid>
                              <Grid item xs={12} md={6} lg={6}>
                                <Field
                                  component={BlueTextField}
                                  css={textFieldBoxColors}
                                  fullWidth
                                  label="City"
                                  name={`${name}.homeCity`}
                                  type="text"
                                  initialValue={cityHome}
                                  parse={v => upperCaseFirstLetters(v)}
                                  validate={required}
                                  variant="outlined"
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={4}>
                              <Grid item xs={12} md={6} lg={6}>
                                <Field
                                  component={Select}
                                  css={textFieldBoxColors}
                                  formControlProps={{ fullWidth: true }}
                                  label={<span style={{ color: vendorColor }}>State</span>}
                                  name={`${name}.homeState`}
                                  type="text"
                                  initialValue={stateHome}
                                  validate={requiredState}
                                  variant="outlined"
                                  style={{ borderColor: vendorColor }}
                                  className="stateSelect">
                                  {usStates.map(({ value, label }, i) => (
                                    <MenuItem value={value} key={i}>
                                      {label}
                                    </MenuItem>
                                  ))}
                                </Field>
                              </Grid>
                              <Grid item xs={12} md={6} lg={6}>
                                <Field
                                  component={BlueTextField}
                                  css={textFieldBoxColors}
                                  fullWidth
                                  label="Postal Code"
                                  name={`${name}.homePostalCode`}
                                  type="text"
                                  initialValue={postalCodeHome}
                                  validate={required}
                                  variant="outlined"
                                />
                              </Grid>
                            </Grid>
                          </Condition>
                        </div>
                      ));
                    }}
                  </FieldArray>
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={12} lg={3} />
                    <Grid item xs={12} md={12} lg={6}>
                      <Field component={BlueTextField} css={textFieldBoxColorsSignature} fullWidth label="Type your name to sign" name="signature" type="text" validate={required} variant="outlined" />
                    </Grid>
                    <Grid item xs={12} md={12} lg={3} />
                  </Grid>
                </>
              )}
            </WizardFormHandler.Page>
          ) : null}
        </WizardFormHandler>
      )}
    </React.Fragment>
  );
};

export default TruckCreditApplicationForm;

const legalStructureOption = [
  { value: "803370001", label: "Corporation" },
  { value: "803370002", label: "LLC" },
  { value: "803370000", label: "Sole Proprietorship" },
  { value: "100000001", label: "Owner Operator" },
  { value: "803370003", label: "Partnership" },
  { value: "100000000", label: "Municipality" },
  { value: "100000004", label: "Other" },
];

const years = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15" },
  { value: 16, label: "16" },
  { value: 17, label: "17" },
  { value: 18, label: "18" },
  { value: 19, label: "19" },
  { value: 20, label: "20+" },
];

const months = [
  { value: "01", label: "Jan" },
  { value: "02", label: "Feb" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const DateCreate = () => {
  const dateYears = [];
  for (var i = 2020; i >= 1950; i--) {
    dateYears.push({ value: i, label: i });
  }
  return dateYears;
};

import React, { useContext, useEffect, useState } from "react";
import styles from "./PaymentSelector.module.css";
import fonts from "../fonts.module.css";
import VendorContext from "../vendorContext";
import Modal from "@material-ui/core/Modal";
import Step from "@material-ui/core/Step";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import HeaderCard from "./components/HeaderCard";
import HighlightCard from "./components/HighlightCard";
import OptionCard from "./components/OptionCard";
import ConfirmationModal from "./components/ConfirmationModal";
import { getURLParameter, convertToDate } from "../utils";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { calculateRatesAndPayments } from "../utils/paymentSelectorMath";
import GBBCard from "./components/GBBCard";
import GBBConfirmationModal from "./components/GBBConfirmationModal";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import LenderOptionConfirmationModal from "./components/LenderOptionConfirmationModal";
import { formatCurrency } from "../utils/stringFormatters";
import defaultMessage from "../components/Message";
import sslLogo from "../assets/images/ssl-logo-300x166.png";
import trnsactLogo from "../assets/images/trnsact-logo-powered-by-light.png";
import { getContrastColor } from "../utils/colorUtils";
import LoadButton from "../components/LoadButton";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

const config = require("../config");

const GET_PROPOSAL = gql`
  query proposal($dynamicsId: ID, $id: ID) {
    proposal(dynamicsId: $dynamicsId, id: $id) {
      productId
      rate
      riskTier
      amountRequested
      markupInternal
      markupDealer
      bizName
      proposalStage
      businessName
      creditProductIds
      vendorOpportunityId
      accountId
      status
      createdDateTime
      gbbCalculatedOptions
      gbbProposedTerms
      residual
      type
      equipmentId
      quantity
      newUsed
      year
      make
      model
      additionalDescription
      equipmentPictureUrl
      serialNumberVin
      stockId
      mileage
      gbbProposedApr
      createdDateTime
      lenderType
      maxApprovedAmount
      financedAmount
      redirect
      equipmentDescription
      fastTrackId
      category
      leadSheetFormId
      paymentSelectorOptions {
        paymentSelectorOptionsId
        lenderProfileId
        lenderProgramId
        textHeader
        textPaymentOptions
        textPaymentOptionsFinePrint
        textAmount
        showInterestRate
        showBestInterestRate
        createdDateTime
        modifiedDateTime
      }
      paymentCards {
        productId
        productName
        productHeader
        term
        rate
        payment
        rateFactor
        riskTier
        amountMin
        amountMax
        docFee
        advancePayment
        markupInternal
        lender
        calculatedPayment {
          paymentAmountPerPeriod
        }
        lenderProgramId
      }
      lenderPaymentOptions {
        paymentId
        proposalId
        lenderId
        term
        residual
        lenderType
        payment
        paymentGroupId
        paymentCriteria
      }
      vendorOpportunity {
        equipmentDescription
      }
      contact {
        email
        firstName
        lastName
        fullName
        phoneNumber
        mugshot
        title
        availability
      }
      vendorProfile {
        logo
        styles
      }
    }
  }
`;

const updateProposal = gql`
  mutation ($dynamicsId: ID, $id: ID, $dynamicsObj: updateProposalCRMFields, $fieldsObj: updateProposalFields) {
    updateProposal(dynamicsId: $dynamicsId, id: $id, dynamicsObj: $dynamicsObj, fieldsObj: $fieldsObj)
  }
`;

const stepLabels = ["Credit Application", "Payment Selection", "Sign Your Docs"];

const PSHeader = props => {
  const proposalProps = props.proposalDetails;
  const logo = props.logo || _.get(proposalProps, "data.proposal.vendorProfile.logo", null);
  const textHeader = props.textHeader;
  return (
    <>
      {logo ? (
        <>
          <h2 style={{ color: "rgba(0,0,0,0.85)", textAlign: "center", fontWeight: "bold" }}>Prepared Exclusively For</h2>
          <div className={props.isPH ? styles.phVendorLogoWrapper : styles.vendorLogoWrapper}>
            <img
              src={logo && logo.includes("/") ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${logo}` : `https://credit-app-images.s3-us-west-2.amazonaws.com/${logo}`}
              alt="Vendor Logo"
            />
          </div>
          <hr className={styles.horizontalRule} style={{ borderColor: props.vendorColor, marginTop: "24px" }} />
        </>
      ) : null}
      <p className={[fonts.pGrey, fonts.h5, styles.stepperTitle].join(" ")}>{textHeader ? textHeader : "Modern Finance Process"}</p>
      <Stepper activeStep={1} alternativeLabel>
        {stepLabels.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {!!props.loanAmount && !!props.customerBusiness && <HeaderCard loanAmount={props.loanAmount} customerBusiness={props.customerBusiness} textAmount={props.textAmount} />}
        {!!props.loanAmount && !!props.type && !!props.maxApproved && <HeaderCard loanAmount={props.loanAmount} maxApproved={props.maxApproved} type={props.type} textAmount={props.textAmount} />}
      </div>
      <div className={styles.centerText}>
        {!!proposalProps.data.proposal.equipmentPictureUrl && (
          <>
            <div style={{ height: "28px" }} />
            <div className={styles.vehiclePhotoWrapper} style={{ display: "inline-block" }}>
              <img src={proposalProps.data.proposal.equipmentPictureUrl} alt="Equipment Photo" />
            </div>
            <div className={styles.vehiclePhotoWrapper} style={{ display: "inline-block", alignItems: "center", textAlign: "center", verticalAlign: "top" }}>
              <span style={{ fontSize: "12px" }}>
                {proposalProps.data.proposal.newUsed} {proposalProps.data.proposal.year} {proposalProps.data.proposal.make} {proposalProps.data.proposal.model}
              </span>
              <br />
              <div style={{ textAlign: "center", fontWeight: "bold" }}>Vehicle Description</div>
              {proposalProps.data.proposal.additionalDescription}
              <div style={{ textAlign: "center", fontWeight: "bold" }}>Quote Date:</div>
              {convertToDate(proposalProps.data.proposal.createdDateTime)}
            </div>
            <hr className={styles.horizontalRule} style={{ borderColor: props.vendorColor, marginTop: "30px" }} />
          </>
        )}
        {!!proposalProps.data.proposal.vendorOpportunity && !!proposalProps.data.proposal.vendorOpportunity.equipmentDescription && (
          <>
            <div className={styles.vehiclePhotoWrapper} style={{ display: "inline-block", alignItems: "center", textAlign: "center", verticalAlign: "top" }}>
              <h2>Equipment Description</h2>
              <div style={{ fontSize: "18px" }}>{proposalProps.data.proposal.vendorOpportunity.equipmentDescription}</div>
            </div>
            <hr className={styles.horizontalRule} style={{ borderColor: props.vendorColor, marginTop: "30px" }} />
          </>
        )}
      </div>
    </>
  );
};

const PaymentSelector = props => {
  const [propId] = useState(getURLParameter("propId"));
  const [proposalId] = useState(getURLParameter("proposalId"));
  const [vendorGUID] = useState(getURLParameter("vendorGUID"));
  const proposalProps = useQuery(GET_PROPOSAL, { variables: { dynamicsId: propId, id: proposalId } });
  const [fastTrackStatusUpdated, setFastTrackStatusUpdated] = useState(false);

  const [updateFastTrackStatus] = useMutation(updateProposal);
  const queryParams = new URLSearchParams(window.location.search);
  const [applicantIsViewingThisProposal] = useState(queryParams.get("av") === "" || queryParams.get("av.") === "");

  useEffect(() => {
    if (applicantIsViewingThisProposal) {
      if (!fastTrackStatusUpdated) {
        console.log("Applicant is viewing, updating FT status!");
        updateFastTrackStatus({
          variables: {
            id: proposalId,
            fieldsObj: {
              fastTrackStatusId: 215,
            },
          },
        });
      }
      setFastTrackStatusUpdated(true);
    } else {
      console.log("Applicant is NOT viewing, skipping update of FT status!");
    }
  });

  const setRepData = props.setRepData;
  const setVendorObj = props.setVendorObj;

  if (proposalProps.loading) {
    return null;
  }
  if (!propId && !proposalId) {
    return (window.location.href = "/pserror/");
  }

  if (proposalProps.loading) {
    return null;
  }
  if (proposalProps.error) {
    return (window.location.href = "/pserror/");
  }
  if (!proposalProps.data) {
    return (window.location.href = "/pserror/");
  }
  if (!proposalProps.data.proposal) {
    return (window.location.href = "/pserror/");
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const bizName = !!proposalProps.data.proposal.bizName ? proposalProps.data.proposal.bizName : proposalProps.data.proposal.businessName;
  const proposalStage = !!proposalProps.data.proposal.proposalStage ? proposalProps.data.proposal.proposalStage : proposalProps.data.proposal.status;
  const proposalType = !!proposalProps.data.proposal.type ? proposalProps.data.proposal.type : null;
  const maxApprovedAmount = !!proposalProps.data.proposal.maxApprovedAmount ? proposalProps.data.proposal.maxApprovedAmount : null;
  const thisProposalIsFastTracked = !!proposalProps.data.proposal.fastTrackId;
  const paymentSelectorOptions = !!proposalProps.data.proposal.paymentSelectorOptions ? proposalProps.data.proposal.paymentSelectorOptions : [];
  let textHeader = null;
  let textPaymentOptions = null;
  let textPaymentOptionsFinePrint = null;
  let textAmount = null;
  let showInterestRate = true;
  let showBestInterestRate = true;
  let hasPaymentSelectorOptions = false;

  if (proposalProps.data.proposal.paymentSelectorOptions) {
    textHeader = proposalProps.data.proposal.paymentSelectorOptions[0].textHeader;
    textPaymentOptions = proposalProps.data.proposal.paymentSelectorOptions[0].textPaymentOptions;
    textPaymentOptionsFinePrint = proposalProps.data.proposal.paymentSelectorOptions[0].textPaymentOptionsFinePrint;
    textAmount = proposalProps.data.proposal.paymentSelectorOptions[0].textAmount;
    showBestInterestRate = proposalProps.data.proposal.paymentSelectorOptions[0].showBestInterestRate;
    showInterestRate = proposalProps.data.proposal.paymentSelectorOptions[0].showInterestRate;
    hasPaymentSelectorOptions = true;
  }

  const calculateMonthlyOptions = obj => {
    if (!obj) {
      return null;
    }
    for (let i = 0; i < obj.length; i++) {
      obj[i].amountRequested = proposalProps.data.proposal.amountRequested;
      if (isNaN(obj[i].advancePayment) && obj[i].advancePayment.charAt(0) === "$") {
        obj[i].advancePayment = Math.floor(obj[i].advancePayment.replace("$", ""));
      }
      if (isNaN(obj[i].docFee) && obj[i].docFee.charAt(0) === "$") {
        obj[i].docFee = Math.floor(obj[i].docFee.replace("$", ""));
      }

      if (obj[i].calculatedPayment && obj[i].calculatedPayment.paymentAmountPerPeriod) {
        obj[i].interestRate = obj[i].rate;
        obj[i].monthlyPayments = numberWithCommas(obj[i].calculatedPayment.paymentAmountPerPeriod);
      } else {
        obj[i].monthlyPayments = obj[i].amountRequested * obj[i].rateFactor;
        obj[i].mpWithPoints = obj[i].monthlyPayments * (1 + (obj[i].markupInternal / 100 + proposalProps.data.proposal.markupDealer / 100));
        obj[i].monthlyPayments = obj[i].mpWithPoints;
        obj[i].monthlyPayments = obj[i].monthlyPayments.toFixed(2);
        const ratesandpay = calculateRatesAndPayments(obj[i].amountRequested, obj[i].term, obj[i].rate, obj[i].markupInternal / 100 + proposalProps.data.proposal.markupDealer / 100);
        obj[i].interestRate = obj[i].rate === 0 || obj[i].rate === null ? 0 : ratesandpay.simpleInterest;
      }

      if (obj[i].advancePayment > 0) {
        obj[i].amountDue = obj[i].advancePayment * obj[i].monthlyPayments + obj[i].docFee;
        obj[i].amountDue = (Math.round(obj[i].amountDue * 100) / 100).toFixed(2);
      } else {
        obj[i].amountDue = obj[i].monthlyPayments + obj[i].docFee;
        obj[i].amountDue = (Math.round(obj[i].amountDue * 100) / 100).toFixed(2);
      }
      obj[i].creditProduct = obj[i].productId + " - " + obj[i].productName;
      obj[i].monthlyPayments = numberWithCommas(obj[i].monthlyPayments);
    }
  };

  if (proposalType === "base" || proposalType === "select" || propId) {
    let allPaymentOptions = proposalProps.data.proposal.paymentCards;
    calculateMonthlyOptions(allPaymentOptions);
    var groupBy = function (xs, key) {
      if (!xs) {
        return null;
      }
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    const bestInterest = allPaymentOptions
      ? allPaymentOptions.reduce(function (prev, curr) {
          return prev.interestRate < curr.interestRate ? prev : curr;
        })
      : null;
    const lowestPayment = allPaymentOptions
      ? allPaymentOptions.reduce(function (prev, curr) {
          if (prev.calculatedPayment && curr.calculatedPayment) {
            return prev.calculatedPayment.paymentAmountPerPeriod < curr.calculatedPayment.paymentAmountPerPeriod ? prev : curr;
          }
          return prev.mpWithPoints < curr.mpWithPoints ? prev : curr;
        })
      : null;
    const grouped = groupBy(allPaymentOptions, "productHeader");
    return (
      <PaymentSelectorV2
        bizName={bizName}
        bestInterestRate={bestInterest}
        lowestPayment={lowestPayment}
        allPaymentOptionsCards={grouped}
        proposalStage={proposalStage}
        type={proposalType}
        vendorGUID={vendorGUID}
        setRepData={setRepData}
        thisProposalIsFastTracked={thisProposalIsFastTracked}
        paymentSelectorOptions={paymentSelectorOptions}
        textHeader={textHeader}
        textPaymentOptions={textPaymentOptions}
        textPaymentOptionsFinePrint={textPaymentOptionsFinePrint}
        textAmount={textAmount}
        showInterestRate={showInterestRate}
        showBestInterestRate={showBestInterestRate}
        hasPaymentSelectorOptions={hasPaymentSelectorOptions}
      />
    );
  } else if (proposalType === "gbb") {
    return <PaymentSelectorGBB bizName={bizName} proposalStage={proposalStage} type={proposalType} vendorGUID={vendorGUID} setRepData={setRepData} />;
  } else {
    return (
      <PaymentSelectorLenderOptions
        bizName={bizName}
        proposalStage={proposalStage}
        type={proposalType}
        vendorGUID={vendorGUID}
        maxApproved={maxApprovedAmount}
        setRepData={setRepData}
        setVendorObj={setVendorObj}
        thisProposalIsFastTracked={thisProposalIsFastTracked}
        textHeader={textHeader}
        textPaymentOptions={textPaymentOptions}
        textPaymentOptionsFinePrint={textPaymentOptionsFinePrint}
        textAmount={textAmount}
        showInterestRate={showInterestRate}
        showBestInterestRate={showBestInterestRate}
      />
    );
  }
};
export default PaymentSelector;

const PaymentSelectorV2 = props => {
  const [propId] = useState(getURLParameter("propId"));
  const [proposalId] = useState(getURLParameter("proposalId"));
  const productNames = [];
  const proposalProps = useQuery(GET_PROPOSAL, { variables: { dynamicsId: propId, id: proposalId } });
  const [customerBusiness] = useState(props.bizName);
  const [transactionGUID] = useState(getURLParameter("TUId"));
  const vendorGUID = props.vendorGUID;
  const [showModal, setShowModal] = useState(false);
  const [loanAmount, setLoanAmount] = useState("$XX.XX");
  const [submitted, setSubmitted] = useState(false);
  const [selectedProductIndex, setSelectedProductIndex] = useState();
  const [isQuote, setIsQuote] = useState(false);
  const [formId, setFormId] = useState("");
  const { color: vendorColor, logo } = useContext(VendorContext);
  const paymentOptions = props.allPaymentOptionsCards;
  const bestRate = props.bestInterestRate ? props.bestInterestRate : {};
  const lowestMonthlyPayment = props.lowestPayment ? props.lowestPayment : {};
  const type = props.type;
  const [postPaymentSelection] = useMutation(updateProposal);
  const thisProposalIsFastTracked = props.thisProposalIsFastTracked;
  const paymentSelectorOptions = props.paymentSelectorOptions;
  const textHeader = props.textHeader;
  const textPaymentOptions = props.textPaymentOptions;
  const textPaymentOptionsFinePrint = props.textPaymentOptionsFinePrint;
  const textAmount = props.textAmount;
  const showInterestRate = props.showInterestRate;
  const showBestInterestRate = props.showBestInterestRate;
  const hasPaymentSelectorOptions = props.hasPaymentSelectorOptions;
  const paymentSelectionObj = {
    dcrStage: "Presented",
    status: "Viewed by customer",
  };

  // console.log(`
  //   PaymentSelectorV2
  //     textHeader =  ${textHeader}
  //     textPaymentOptions =  ${textPaymentOptions}
  //     textPaymentOptionsFinePrint = ${textPaymentOptionsFinePrint}
  //     textAmount =  ${textAmount}
  //     showInterestRate =  ${showInterestRate}
  //     showBestInterestRate =  ${showBestInterestRate}

  //     lowestMonthlyPayment = ${JSON.stringify(lowestMonthlyPayment)}
  //     bestRate = ${JSON.stringify(bestRate)}
  //     bestInterestRate = ${JSON.stringify(props.bestInterestRate)}
  // `);

  for (const prop in paymentOptions) {
    productNames.push(prop);
  }

  const setRepData = props.setRepData;

  useEffect(() => {
    if (!!proposalProps.data.proposal.contact) {
      setRepData({
        officePhone: proposalProps.data.proposal.contact.phoneNumber,
        email: proposalProps.data.proposal.contact.email,
        name: proposalProps.data.proposal.contact.fullName,
        availability: proposalProps.data.proposal.contact.availability || "Monday-Friday, 8am-5pm PST",
        mugshot: proposalProps.data.proposal.contact.mugshot,
      });
    }
  }, [proposalProps.data.proposal.contact]);

  useEffect(() => {
    if (!!proposalProps.data.proposal.category) {
      setIsQuote(proposalProps.data.proposal.category === "QUOTE");
    }
    if (!!proposalProps.data.proposal.leadSheetFormId) {
      setFormId(proposalProps.data.proposal.leadSheetFormId);
    }
  }, [proposalProps.data.proposal]);

  useEffect(() => {
    const postPayment = () => {
      if (propId) {
        postPaymentSelection({
          variables: {
            dynamicsId: propId,
            dynamicsObj: paymentSelectionObj,
          },
        });
      }
      if (proposalId) {
        postPaymentSelection({
          variables: {
            id: proposalId,
            fieldsObj: paymentSelectionObj,
          },
        });
      }
    };
    const vendorGUID = getURLParameter("vendorGUID");
    if (vendorGUID === "4af87cff-d949-e911-8107-005056b05a0f") {
      setPH(true);
    }
    const loanAmount = proposalProps.data.proposal.amountRequested.toFixed(2);
    if (loanAmount) setLoanAmount(loanAmount);
    if (props.proposalStage === "Accepted") {
      return (window.location.href = "/psconfirmed/");
    } else if (props.proposalStage === "" || props.proposalStage == null || props.proposalStage === "Created" || props.proposalStage === "Proposal Sent") {
      postPayment();
    }
  }, []);
  const [isPH, setPH] = useState(false);

  const handleCardClick = productIndex => {
    setSelectedProductIndex(productIndex);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const labelText = () => {
    if (bestRate.interestRate > 0.1) {
      return "Lowest Payment";
    }
    if (isNaN(bestRate.interestRate)) {
      return "Lowest Monthly Payment";
    }
    return `Lowest Payment${showBestInterestRate ? " and Best Interest Rate" : ""}`;
  };
  return (
    <>
      <PSHeader
        logo={logo}
        loanAmount={loanAmount}
        customerBusiness={customerBusiness}
        proposalDetails={proposalProps}
        isPH={isPH}
        vendorColor={vendorColor}
        textHeader={textHeader}
        textAmount={textAmount}
      />
      <div className={styles.centerText}>
        <h4 style={{ color: vendorColor }} className={[fonts.h4, styles.defaultTextColor].join(" ")}>
          Payment Options
        </h4>
        <p className={fonts.pGrey}>
          {textPaymentOptions
            ? textPaymentOptions
            : `
          Based off of your credit, we would like to offer you the following finance options. Please click to review any one of the following offers. If you have any questions, please give us a call.
          These options are subject to change and are based on a Pre-Approval only.`}
        </p>
        <div style={{ height: "10px" }} />
        {type !== "gbb" && (
          <>
            {bestRate === lowestMonthlyPayment ? (
              <HighlightCard
                label={labelText()}
                payment={lowestMonthlyPayment.monthlyPayments}
                months={lowestMonthlyPayment.term}
                rate={(lowestMonthlyPayment.interestRate * 100).toFixed(2)}
                rateCheck={(lowestMonthlyPayment.interestRate * 100).toFixed(2)}
                handleClick={() => {
                  handleCardClick(lowestMonthlyPayment);
                }}
                thisProposalIsFastTracked={thisProposalIsFastTracked}
                paymentSelectorOptionsForThisCard={paymentSelectorOptions.find(pso => {
                  return pso.lenderProgramId === lowestMonthlyPayment.lenderProgramId;
                })}
              />
            ) : (
              <React.Fragment>
                <HighlightCard
                  label={"Lowest Payment"}
                  payment={lowestMonthlyPayment.monthlyPayments}
                  months={lowestMonthlyPayment.term}
                  rate={(lowestMonthlyPayment.interestRate * 100).toFixed(2)}
                  rateCheck={(lowestMonthlyPayment.interestRate * 100).toFixed(2)}
                  handleClick={() => {
                    handleCardClick(lowestMonthlyPayment);
                  }}
                  thisProposalIsFastTracked={thisProposalIsFastTracked}
                  paymentSelectorOptionsForThisCard={paymentSelectorOptions.find(pso => {
                    return pso.lenderProgramId === lowestMonthlyPayment.lenderProgramId;
                  })}
                />
              </React.Fragment>
            )}
            {bestRate === lowestMonthlyPayment || bestRate.interestRate > 0.1 || !showBestInterestRate ? (
              ""
            ) : (
              <HighlightCard
                label={"Best Interest Rate"}
                payment={bestRate.monthlyPayments}
                months={bestRate.term}
                rate={parseFloat(bestRate.interestRate * 100).toFixed(2)}
                handleClick={() => {
                  handleCardClick(bestRate);
                }}
                thisProposalIsFastTracked={thisProposalIsFastTracked}
                showInterestRate={showInterestRate}
              />
            )}
            <React.Fragment>
              {productNames[0] ? (
                <h5 style={{ color: vendorColor }} className={[fonts.h4, styles.defaultTextColor].join(" ")}>
                  {productNames[0]}
                </h5>
              ) : (
                ""
              )}
              {paymentOptions[productNames[0]].map(({ term, monthlyPayments, interestRate }, i) => (
                <OptionCard
                  rate={parseFloat(interestRate * 100).toFixed(2)}
                  payment={monthlyPayments}
                  term={term}
                  rateCheck={(lowestMonthlyPayment.interestRate * 100).toFixed(2)}
                  handleClick={() => {
                    handleCardClick(paymentOptions[productNames[0]][i]);
                  }}
                  thisProposalIsFastTracked={thisProposalIsFastTracked}
                  key={i}
                  paymentSelectorOptionsForThisCard={paymentSelectorOptions.find(pso => {
                    return pso.lenderProgramId === paymentOptions[productNames[0]][i].lenderProgramId;
                  })}
                />
              ))}
            </React.Fragment>
            {productNames[1] ? (
              <h5 style={{ color: vendorColor }} className={[fonts.h4, styles.defaultTextColor].join(" ")}>
                {productNames[1]}
              </h5>
            ) : (
              ""
            )}
            <React.Fragment>
              {!productNames[1]
                ? ""
                : paymentOptions[productNames[1]].map(({ term, monthlyPayments, interestRate }, i) => (
                    <OptionCard
                      rate={parseFloat(interestRate * 100).toFixed(2)}
                      payment={monthlyPayments}
                      term={term}
                      rateCheck={(lowestMonthlyPayment.interestRate * 100).toFixed(2)}
                      showInterestRate={showInterestRate}
                      handleClick={() => {
                        handleCardClick(paymentOptions[productNames[1]][i]);
                      }}
                      key={i}
                      paymentSelectorOptionsForThisCard={paymentSelectorOptions.find(pso => {
                        return pso.lenderProgramId === paymentOptions[productNames[1]][i].lenderProgramId;
                      })}
                    />
                  ))}
            </React.Fragment>
          </>
        )}

        <hr className={styles.horizontalRule} style={{ borderColor: vendorColor, marginTop: "30px" }} />
        <p className={[fonts.pGrey, styles.finePrint].join(" ")}>
          {textPaymentOptionsFinePrint
            ? textPaymentOptionsFinePrint
            : `Rates shown represent simple interest. Simple interest is a rate of interest that is calculated by dividing the Total Interest by the principal amount of the loan; it represents the total
          amount of interest that you will pay as a percentage of the amount borrowed. It does not include the Origination Fee. It is not annualized and you should use caution in comparing it to
          interest rates expressed in annualized terms or to loans with different durations.`}
        </p>
      </div>
      <Modal
        open={showModal}
        onClose={closeModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <ConfirmationModal
          customerBusiness={customerBusiness}
          loanAmount={loanAmount}
          closeModal={closeModal}
          termLength={selectedProductIndex && selectedProductIndex.term}
          monthlyPayment={selectedProductIndex && selectedProductIndex.monthlyPayments}
          transactionGUID={transactionGUID}
          formSubmitted={submitted}
          setFormSubmit={setSubmitted}
          vendorGUID={vendorGUID}
          propId={propId}
          isQuote={isQuote}
          formId={formId}
          proposalId={proposalId}
          interestRate={selectedProductIndex && selectedProductIndex.interestRate}
          docFee={selectedProductIndex && selectedProductIndex.docFee}
          rate={selectedProductIndex && selectedProductIndex.rate}
          advancePayment={selectedProductIndex && selectedProductIndex.advancePayment}
          amountDue={selectedProductIndex && selectedProductIndex.amountDue}
          creditProduct={selectedProductIndex && selectedProductIndex.creditProduct}
          textAmount={textAmount}
          hasPaymentSelectorOptions={hasPaymentSelectorOptions}
        />
      </Modal>
    </>
  );
};

const PaymentSelectorGBB = props => {
  const [propId] = useState(getURLParameter("propId"));
  const [proposalId] = useState(getURLParameter("proposalId"));
  const productNames = [];
  const proposalProps = useQuery(GET_PROPOSAL, { variables: { dynamicsId: propId, id: proposalId } });
  const [customerBusiness] = useState(props.bizName);
  const [transactionGUID] = useState(getURLParameter("TUId"));
  const [showModal, setShowModal] = useState(false);
  const [loanAmount, setLoanAmount] = useState("$XX.XX");
  const [submitted, setSubmitted] = useState(false);
  const [selectedProductIndex, setSelectedProductIndex] = useState();
  const [isQuote, setIsQuote] = useState(false);
  const [formId, setFormId] = useState("");
  const { color: vendorColor, logo } = useContext(VendorContext);
  const paymentOptions = props.gbbOptions;
  const type = props.type;
  const vendorGUID = props.vendorGUID;
  const [postPaymentSelection] = useMutation(updateProposal);
  const thisProposalIsFastTracked = props.thisProposalIsFastTracked;
  const paymentSelectionObj = {
    dcrStage: "Presented",
    status: "Viewed by customer",
    fastTrackStatusId: 220,
  };

  for (const prop in paymentOptions) {
    productNames.push(prop);
  }

  const setRepData = props.setRepData;

  useEffect(() => {
    if (!!proposalProps.data.proposal.contact) {
      setRepData({
        officePhone: proposalProps.data.proposal.contact.phoneNumber,
        email: proposalProps.data.proposal.contact.email,
        name: proposalProps.data.proposal.contact.fullName,
        availability: proposalProps.data.proposal.contact.availability || "Monday-Friday, 8am-5pm PST",
        mugshot: proposalProps.data.proposal.contact.mugshot,
      });
    }
  }, [proposalProps.data.proposal.contact]);

  useEffect(() => {
    if (!!proposalProps.data.proposal.category) {
      setIsQuote(proposalProps.data.proposal.category === "QUOTE");
    }
    if (!!proposalProps.data.proposal.leadSheetFormId) {
      setFormId(proposalProps.data.proposal.leadSheetFormId);
    }
  }, [proposalProps.data.proposal]);

  useEffect(() => {
    const postPayment = () => {
      if (propId) {
        postPaymentSelection({
          variables: {
            dynamicsId: propId,
            dynamicsObj: paymentSelectionObj,
          },
        });
      }
      if (proposalId) {
        postPaymentSelection({
          variables: {
            id: proposalId,
            fieldsObj: paymentSelectionObj,
          },
        });
      }
    };
    const vendorGUID = getURLParameter("vendorGUID");
    if (vendorGUID === "4af87cff-d949-e911-8107-005056b05a0f") {
      setPH(true);
    }
    const loanAmount = proposalProps.data.proposal.amountRequested.toFixed(2);
    if (loanAmount) setLoanAmount(loanAmount);
    if (props.proposalStage === "Accepted") {
      return (window.location.href = "/psconfirmed/");
    } else if (props.proposalStage === "" || props.proposalStage == null || props.proposalStage === "Created" || props.proposalStage === "Proposal Sent") {
      postPayment();
    }
  }, []);
  const [isPH, setPH] = useState(false);

  const handleCardClick = productIndex => {
    setSelectedProductIndex(productIndex);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <PSHeader logo={logo} proposalDetails={proposalProps} isPH={isPH} vendorColor={vendorColor} />
      <div className={styles.centerText}>
        <h4 style={{ color: vendorColor }} className={[fonts.h4, styles.defaultTextColor].join(" ")}>
          YOUR PAYMENT OPTIONS
        </h4>
        <p className={fonts.pGrey}>
          Based off of your credit, we would like to offer you the following finance options. <br />
          Please click to review any one of the following offers. If you have any questions, please give us a call. <br />
          These options are subject to change and are based on a Pre-Approval only.
        </p>
        <br />
        <Typography variant="h4" style={{ color: vendorColor, fontWeight: "bold" }}>
          TRAC LEASE
        </Typography>
        <Typography variant="caption" style={{ color: vendorColor }}>
          {`${proposalProps.data.proposal.residual}% Residual  ${proposalProps.data.proposal.gbbProposedTerms} Months`}
        </Typography>
        <div style={{ height: "28px" }} />
        <Grid container alignItems="stretch">
          {proposalProps.data.proposal.gbbCalculatedOptions[0].trac.map(({ term, monthlyPayments, simpleInterest, emailHeader, aftermarketName }, i) => (
            <GBBCard
              monthlyPayments={monthlyPayments}
              index={i}
              term={proposalProps.data.proposal.gbbProposedTerms}
              emailHeader={emailHeader}
              aftermarketName={aftermarketName}
              handleClick={() => {
                handleCardClick(proposalProps.data.proposal.gbbCalculatedOptions[0].trac[i]);
              }}
              key={i}
            />
          ))}
        </Grid>
        <div style={{ height: "28px" }} />
        <Typography variant="h4" style={{ color: vendorColor, fontWeight: "bold" }}>
          FINANCE
        </Typography>
        <Typography variant="caption" style={{ color: vendorColor }}>
          {`${proposalProps.data.proposal.gbbProposedTerms} Months ${proposalProps.data.proposal.gbbProposedApr}% Rate`}
        </Typography>
        <div style={{ height: "28px" }} />
        <Grid container alignItems="stretch">
          {proposalProps.data.proposal.gbbCalculatedOptions[0].finance.map(({ term, monthlyPayments, simpleInterest, emailHeader, aftermarketName }, i) => (
            <GBBCard
              rate={parseFloat(simpleInterest).toFixed(2)}
              monthlyPayments={monthlyPayments}
              index={i}
              term={proposalProps.data.proposal.gbbProposedTerms}
              emailHeader={emailHeader}
              aftermarketName={aftermarketName}
              handleClick={() => {
                handleCardClick(proposalProps.data.proposal.gbbCalculatedOptions[0].finance[i]);
              }}
              key={i}
            />
          ))}
        </Grid>
        <div style={{ height: "10px" }} />
        <hr className={styles.horizontalRule} style={{ borderColor: vendorColor, marginTop: "30px" }} />
        <p className={[fonts.pGrey, styles.finePrint].join(" ")}>
          Rates shown represent simple interest. Simple interest is a rate of interest that is calculated by dividing the Total Interest by the principal amount of the loan; it represents the total
          amount of interest that you will pay as a percentage of the amount borrowed. It does not include the Origination Fee. It is not annualized and you should use caution in comparing it to
          interest rates expressed in annualized terms or to loans with different durations.
        </p>
      </div>
      <Modal
        open={showModal}
        onClose={closeModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <GBBConfirmationModal
          customerBusiness={customerBusiness}
          loanAmount={loanAmount}
          closeModal={closeModal}
          termLength="60"
          monthlyPayment={selectedProductIndex && selectedProductIndex.monthlyPayments}
          transactionGUID={transactionGUID}
          formSubmitted={submitted}
          setFormSubmit={setSubmitted}
          vendorGUID={vendorGUID}
          propId={propId}
          isQuote={isQuote}
          formId={formId}
          proposalId={proposalId}
          interestRate={selectedProductIndex && selectedProductIndex.simpleInterest}
          docFee={selectedProductIndex && selectedProductIndex.docFee}
          rate={selectedProductIndex && selectedProductIndex.rate}
          advancePayment={selectedProductIndex && selectedProductIndex.advancePayment}
          amountDue={selectedProductIndex && selectedProductIndex.amountDue}
          creditProduct={selectedProductIndex}
        />
      </Modal>
    </>
  );
};

const PaymentSelectorLenderOptions = props => {
  const [propId] = useState(getURLParameter("propId"));
  const [proposalId] = useState(getURLParameter("proposalId"));
  const productNames = [];
  const proposalProps = useQuery(GET_PROPOSAL, { variables: { dynamicsId: propId, id: proposalId } });
  const [customerBusiness] = useState(props.bizName);
  const [transactionGUID] = useState(getURLParameter("TUId"));
  const [showModal, setShowModal] = useState(false);
  const [loanAmount, setLoanAmount] = useState("$XX.XX");
  const [submitted, setSubmitted] = useState(false);
  const [selectedProductIndex, setSelectedProductIndex] = useState();
  const [termSelected, setTermSelected] = useState(0);
  const [lenderOption, setLenderOption] = useState(null);
  const { color: vendorColor, logo } = useContext(VendorContext);
  const paymentOptions = props.lenderPaymentOptions;
  const type = props.type;
  const vendorGUID = props.vendorGUID;
  const [redirectToSigning, setRedirectToSigning] = useState();
  const [voId, setVoId] = useState();
  const [postPaymentSelection] = useMutation(updateProposal);
  const textHeader = props.textHeader;
  const textPaymentOptions = props.textPaymentOptions;
  const textPaymentOptionsFinePrint = props.textPaymentOptionsFinePrint;
  const textAmount = props.textAmount;
  const showInterestRate = props.showInterestRate;
  const showBestInterestRate = props.showBestInterestRate;
  const [amountDueProposalV2, setAmountDueProposalV2] = useState(null);
  const [lenderType, setLenderType] = useState(null);
  const [isQuote, setIsQuote] = useState(false);
  const [formId, setFormId] = useState("");
  const queryParams = new URLSearchParams(window.location.search);
  const [applicantIsViewingThisProposal] = useState(queryParams.get("av") === "" || queryParams.get("av.") === "");

  let urlParams = null;
  if (window) {
    urlParams = new URLSearchParams(window.location.search);
  }

  // console.log(`
  //   PaymentSelectorLenderOptions
  //     textHeader =  ${textHeader}
  //     textPaymentOptions =  ${textPaymentOptions}
  //     textPaymentOptionsFinePrint = ${textPaymentOptionsFinePrint}
  //     textAmount =  ${textAmount}
  //     showInterestRate =  ${showInterestRate}
  //     showBestInterestRate =  ${showBestInterestRate}
  // `);
  const paymentSelectionObj = {
    dcrStage: "Presented",
    status: "Viewed by customer",
  };

  for (const prop in paymentOptions) {
    productNames.push(prop);
  }

  const setRepData = props.setRepData;
  const setVendorObj = props.setVendorObj;

  useEffect(() => {
    if (!!proposalProps.data.proposal.contact) {
      setRepData({
        officePhone: proposalProps.data.proposal.contact.phoneNumber,
        email: proposalProps.data.proposal.contact.email,
        name: proposalProps.data.proposal.contact.fullName,
        availability: proposalProps.data.proposal.contact.availability || "Monday-Friday, 8am-5pm PST",
        mugshot: proposalProps.data.proposal.contact.mugshot,
      });
    }
  }, [proposalProps.data.proposal.contact]);

  useEffect(() => {
    if (!!proposalProps.data.proposal.vendorProfile) {
      setVendorObj(null, proposalProps.data.proposal.vendorProfile);
    }
  }, [proposalProps.data.proposal.vendorProfile]);

  useEffect(() => {
    if (!!proposalProps.data.proposal) {
      const redirectToSigning = proposalProps.data.proposal.redirect;
      setRedirectToSigning(redirectToSigning);
      setVoId(proposalProps.data.proposal.vendorOpportunityId);
      setLenderType(proposalProps.data.proposal.lenderType);
      setIsQuote(proposalProps.data.proposal.category === "QUOTE");
      setFormId(proposalProps.data.proposal.leadSheetFormId);
    }
  }, [proposalProps.data.proposal]);

  useEffect(() => {
    const postPayment = () => {
      if (propId) {
        postPaymentSelection({
          variables: {
            dynamicsId: propId,
            dynamicsObj: paymentSelectionObj,
          },
        });
      }
      if (proposalId) {
        postPaymentSelection({
          variables: {
            id: proposalId,
            fieldsObj: paymentSelectionObj,
          },
        });
      }
    };
    const vendorGUID = getURLParameter("vendorGUID");
    if (vendorGUID === "4af87cff-d949-e911-8107-005056b05a0f") {
      setPH(true);
    }
    const loanAmount = proposalProps.data.proposal.amountRequested.toFixed(2);
    if (loanAmount) setLoanAmount(loanAmount);
    if (props.proposalStage === "Accepted" || props.proposalStage === "accepted - pending bank") {
      return (window.location.href = "/psconfirmed/");
    } else if ((props.proposalStage === "" || props.proposalStage == null || props.proposalStage === "Created" || props.proposalStage === "Proposal Sent") && !urlParams.has("viewOnly")) {
      postPayment();
    }
  }, []);
  const [isPH, setPH] = useState(false);

  const handleCardClick = productIndex => {
    setSelectedProductIndex(productIndex);

    console.log(`productIndex: ${JSON.stringify(productIndex)}`);

    let amountDue = null;

    try {
      if (productIndex.paymentCriteria) {
        amountDue = 0;
        if (productIndex.paymentCriteria.downPaymentAmount) {
          amountDue += productIndex.paymentCriteria.downPaymentAmount;
        }
        if (productIndex.paymentCriteria.numberOfAdvancePayments && productIndex.paymentCriteria.numberOfAdvancePayments > 0) {
          amountDue += parseFloat((productIndex.paymentCriteria.numberOfAdvancePayments * productIndex.payment).toFixed(2));
        }
      }
      setAmountDueProposalV2(amountDue);
    } catch (e) {
      console.log("Problem calculating amountDue on for proposalV2");
    }

    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  const redirectToOCA = () => {
    window.location.href = `/oca/?vendorGUID=${vendorGUID}&formId=${formId}`;
  };
  useEffect(() => {
    if (proposalProps.data) {
      setLenderOption(proposalProps.data.proposal.lenderPaymentOptions.sort((a, b) => a.payment - b.payment)[termSelected]);
    }
  }, [proposalProps, termSelected]);

  const totalPaymentsAmount = proposalProps.data.proposal.financedAmount || proposalProps.data.proposal.lenderPaymentOptions[0].paymentCriteria.amountFinanced;
  const GBBArray = proposalProps.data.proposal.lenderPaymentOptions
    .map(option => {
      if (option.paymentCriteria && option.paymentCriteria.menu && option.paymentCriteria.menu.menuOptions && option.paymentCriteria.menu.menuOptions.length > 0) return option;
    })
    .filter(i => i);
  const isGBB = GBBArray.length > 0;

  console.log("isGBB ::", isGBB);

  return (
    <>
      <PSHeader logo={logo} loanAmount={loanAmount} type={true} maxApproved={props.maxApproved} proposalDetails={proposalProps} isPH={isPH} vendorColor={vendorColor} textHeader={textHeader} />
      <div className={styles.centerText} style={{ marginTop: 48 }}>
        <h2 style={{ color: "rgba(0,0,0,0.85)", marginBottom: "0px" }} className={[styles.defaultTextColor].join(" ")}>
          Your Payment Options
        </h2>
        {!!totalPaymentsAmount && (
          <p className={fonts.pGrey} style={{ marginTop: "5px", fontSize: "14px", marginBottom: 4 }}>{`Based ${formatCurrency(totalPaymentsAmount.toFixed(2))} financed amount.`}</p>
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          {isQuote ? null : <p style={{ fontSize: "16px", maxWidth: 600 }}>Good news! You've been approved for the below financing options. Please select one of the following offers to proceed.</p>}
        </div>
        <Grid container justify="center" style={{ margin: "32px 0" }}>
          {isGBB ? (
            <>
              {lenderOption && (
                <>
                  <Grid item xs={12} style={{ color: "rgba(0,0,0,0.7)", paddingTop: "24px", paddingBottom: "8px" }}>
                    <span style={{ fontSize: "26px" }}>{lenderOption.paymentCriteria.financeProgramName || "Finance"}</span>
                  </Grid>
                  {isGBB && (
                    <Grid item xs={12} style={{ color: "rgba(0,0,0,0.5)", paddingBottom: 24 }}>
                      <span>{lenderOption.paymentCriteria.rate}% Rate</span>
                      <span> | </span>
                      <span>
                        <Select value={termSelected} onChange={e => setTermSelected(e.target.value)}>
                          {proposalProps.data.proposal.lenderPaymentOptions.map((option, i) => (
                            <MenuItem value={i}>{option.term} Months</MenuItem>
                          ))}
                        </Select>
                      </span>
                    </Grid>
                  )}

                  <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
                    {lenderOption.paymentCriteria.menu.menuOptions.map((option, i) => (
                      <GBBCard
                        monthlyPayments={option.payment.paymentAmountPerPeriod}
                        index={i}
                        term={lenderOption.term}
                        handleClick={() => {
                          handleCardClick({ ...lenderOption, ...option, isMenuOption: true });
                        }}
                        key={i}
                        rate={lenderOption.paymentCriteria.rate}
                        downPayment={lenderOption.paymentCriteria.downPaymentAmount}
                        advancePayments={lenderOption.paymentCriteria.numberOfAdvancePayments}
                        packages={option.packages}
                        backgroundColor={option.titleBgColor}
                        titleFontColor={option.titleColor}
                        header={option.name}
                      />
                    ))}
                  </Grid>
                </>
              )}
            </>
          ) : (
            <>
              {proposalProps.data.proposal.lenderPaymentOptions &&
                proposalProps.data.proposal.lenderPaymentOptions
                  .sort((a, b) => a.payment - b.payment)
                  .map(({ term, payment, residual, paymentCriteria, paymentGroupId }, i) => {
                    let paymentCriteriaHeader = null;
                    const card = (
                      <GBBCard
                        monthlyPayments={payment}
                        index={i}
                        term={term}
                        handleClick={() => {
                          handleCardClick(proposalProps.data.proposal.lenderPaymentOptions[i]);
                        }}
                        key={i}
                        rate={paymentCriteria.rate}
                        downPayment={paymentCriteria.downPaymentAmount}
                        advancePayments={paymentCriteria.numberOfAdvancePayments}
                      />
                    );
                    if (paymentCriteria) {
                      if (i === 0 || (i > 0 && paymentGroupId !== proposalProps.data.proposal.lenderPaymentOptions[i - 1].paymentGroupId)) {
                        paymentCriteriaHeader = (
                          <>
                            <Grid item xs={12} style={{ color: "rgba(0,0,0,0.7)", paddingTop: "24px", paddingBottom: "8px" }}>
                              <span style={{ fontSize: "26px" }}>{paymentCriteria.financeProgramName}</span>
                            </Grid>
                            {/* <Grid item xs={12} style={{ color: "rgba(0,0,0,0.5)", paddingBottom: 24 }}> */}
                            {/* <span style={{ fontSize: "18px" }}>{formatCurrency(paymentCriteria.amountFinanced.toFixed(2))}</span> */}
                            {/* <span> | {paymentCriteria.downPaymentAmount ? formatCurrency(paymentCriteria.downPaymentAmount.toFixed(2)) : "$0.00"} down</span> */}
                            {/* <span> | {paymentCriteria.numberOfAdvancePayments ? paymentCriteria.numberOfAdvancePayments : "0"} advance payments</span> */}
                            {/* </Grid> */}
                          </>
                        );
                      }
                    }
                    return (
                      <React.Fragment>
                        {paymentCriteriaHeader}
                        {card}
                      </React.Fragment>
                    );
                  })}
            </>
          )}
        </Grid>
      </div>
      {isQuote && applicantIsViewingThisProposal ? (
        <Grid lg={12} md={12} sm={12} xs={12}>
          <div style={{ textAlign: "center" }}>
            <LoadButton
              style={{
                backgroundColor: vendorColor,
                borderColor: vendorColor,
                color: getContrastColor(vendorColor),
                height: "3rem",
                width: "9rem",
              }}
              type="submit"
              variant="contained"
              onClick={() => redirectToOCA()}>
              Apply Now
            </LoadButton>
          </div>
        </Grid>
      ) : null}
      <hr className={styles.horizontalRule} style={{ borderColor: vendorColor, width: "100%", marginTop: 108 }} />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={6} lg={6} className={styles.sslLogoContainer}>
          <img src={sslLogo} alt="SSL Logo" className={styles.sslLogo} />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} className={styles.poweredByDCRLogoContainer}>
          <a href="http://dcr.ai/" target="_blank">
            <img src={trnsactLogo} className={styles.poweredByDCRLogo} alt="Powered By Trnsact" />
          </a>
        </Grid>
      </Grid>
      <Grid container justify="space-between" alignItems="flex-start">
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <p className={[styles.footerText, fonts.pGrey, fonts.footerText].join(" ")} style={{ width: "100%", marginBottom: 32 }}>
            <FormattedMessage id="sslDisclaimer" defaultMessage={defaultMessage.sslDisclaimer} />
          </p>
          <p className={[fonts.pGrey, fonts.footerText].join(" ")}>
            <FormattedMessage id="fundingInquiryDisclaimer" defaultMessage={defaultMessage.fundingInquiryDisclaimer} />
          </p>
        </Grid>
      </Grid>
      <Modal
        open={showModal}
        onClose={closeModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
        }}>
        <LenderOptionConfirmationModal
          customerBusiness={customerBusiness}
          loanAmount={loanAmount}
          maxApproved={props.maxApproved}
          closeModal={closeModal}
          termLength={selectedProductIndex && selectedProductIndex.term}
          monthlyPayment={selectedProductIndex && selectedProductIndex.payment}
          transactionGUID={transactionGUID}
          formSubmitted={submitted}
          setFormSubmit={setSubmitted}
          vendorGUID={vendorGUID}
          propId={propId}
          proposalId={proposalId}
          interestRate={selectedProductIndex && selectedProductIndex.simpleInterest}
          paymentId={selectedProductIndex && selectedProductIndex.paymentId}
          docFee={selectedProductIndex && selectedProductIndex.docFee}
          rate={selectedProductIndex && selectedProductIndex.paymentCriteria.rate ? selectedProductIndex.paymentCriteria.rate : selectedProductIndex && selectedProductIndex.rate}
          advancePayment={selectedProductIndex && selectedProductIndex.advancePayment}
          amountDue={selectedProductIndex && selectedProductIndex.amountDue}
          creditProduct={selectedProductIndex}
          redirectToSigning={redirectToSigning}
          voId={voId}
          amountDueProposalV2={amountDueProposalV2}
          lenderType={lenderType}
          isQuote={isQuote}
          formId={formId}
          menuOption={
            selectedProductIndex && selectedProductIndex.isMenuOption
              ? {
                  titleBgColor: selectedProductIndex.titleBgColor,
                  titleColor: selectedProductIndex.titleColor,
                  packages: selectedProductIndex.packages,
                  payment: selectedProductIndex.payment,
                  name: selectedProductIndex.name,
                }
              : null
          }
        />
      </Modal>
    </>
  );
};

import React, { useState, useEffect } from "react";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import Button from "@material-ui/core/Button";
// import Grid from '@material-ui/core/Grid';
import { Form } from "react-final-form";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import { FormattedMessage, useIntl } from "react-intl";

import defaultMessage from "../components/Message";
const apiURL = (process.env.REACT_APP_DCR_API_BASE_SERVER_URL || "https://api.dev.dcrportal.com") + "/v1/graphql";
// const apiURL = "http://localhost:3000/v1/graphql";

function OCADocsUpload(props) {
  const UPLOAD_FILE = `
    mutation($files: Upload) {
      upsertFile(files: $files) 
    }
  `;

  const { file, setFile, selectedFiles, setSelectedFiles, ind } = props;

  const [previewImg, setPreviewImg] = useState([]);

  const fields = {};
  const setFields = {};

  [fields.file, setFields.file] = useState({
    value: null,
    validations: [{ type: "required" }],
  });
  [fields.logo, setFields.logo] = useState({ value: null });
  var fileToUpload;

  return (
    <Form
      onSubmit={value => {
        const formData = new FormData();
        if (fileToUpload && fileToUpload.length > 0) {
          let fileStringMap = "{";
          let arrOfFiles = [];
          for (let i = 0; i < fileToUpload.length; i++) {
            if (i !== fileToUpload.length - 1) {
              fileStringMap += `"${JSON.stringify(i)}": ["variables.files.${JSON.stringify(i)}"],`;
            } else {
              fileStringMap += `"${JSON.stringify(i)}": ["variables.files.${JSON.stringify(i)}"]}`;
            }
            arrOfFiles.push(null);
          }

          formData.append(
            "operations",
            JSON.stringify({
              query: UPLOAD_FILE,
              variables: {
                files: arrOfFiles,
              },
            })
          );
          formData.append("map", fileStringMap);
          for (let i = 0; i < fileToUpload.length; i++) {
            formData.append(JSON.stringify(i), fileToUpload[i]);
          }
        }

        return new Promise(async (resolve, reject) => {
          fetch(apiURL, {
            method: "POST",
            body: formData,
          })
            .then(response => response.json())
            .then(response => {
              response && response.data && response.data.upsertFile && setFile([...file, ...response.data.upsertFile]);
            })
            .catch(err => {
              window.NREUM.noticeError(err);
              console.log(err);
            });
        });
      }}
      render={({ handleSubmit }) => {
        function uploadButtonHandler(event) {
          const file = document.getElementById(`fileInput${ind}`);
          file.click();
        }

        function uploadFileChanged(event) {
          if (event.target.files.length < 1 || !event.target.files) {
            return;
          }
          setSelectedFiles(event.target.files);
          fileToUpload = event.target.files;
          handleSubmit(fileToUpload);
        }

        return (
          <div>
            <form
              onSubmit={e => {
                e.preventDefault();
                onsubmit = "return false";
              }}
              id={`filePreviewForm${ind}`}>
              <input accept="*" id={`fileInput${ind}`} type="file" style={{ display: "none" }} onChange={uploadFileChanged} />
              <Button className="formBtn" color="primary" variant="outlined" style={{ marginRight: "8px" }} onClick={uploadButtonHandler}>
                <FormattedMessage id="chooseFile" defaultMessage={defaultMessage.chooseFile} />
                &nbsp;
                <CloudUploadIcon />
              </Button>
              {/* <Button
              className="formBtn"
              color="primary"
              variant="contained"
              type="submit"
              onClick={handleSubmit}
              disabled={!selectedFiles}
            >
              {'CONFIRM DRIVER LICENSE'}
            </Button> */}
            </form>
          </div>
        );
      }}
    />
  );
}

export default OCADocsUpload;

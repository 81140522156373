import React, { useContext } from "react";
import axios from "axios";
import { apiURL } from "../config";

// const getCreditApp = id => {
//   return axios.get("https://app.useanvil.com/form/dealer-credit-resources-inc/quinn-credit-app-fillable-pdf-v-3-with-trades/", {

//   })
//   .then(res => console.log(res))
//   .catch(err => console.log(err));
// };

const AnvilIframeOCAv3 = () => {
  // getCreditApp();
  return (
    <div>
      <iframe
        src="https://app.useanvil.com/form/dealer-credit-resources-inc/quinn-credit-app-fillable-pdf-v-3-with-trades?test=true/"
        height="800px"
        width="100%"
        scrolling="no"
        frameBorder="0"
        allowFullScreen></iframe>
    </div>
  );
};

export default AnvilIframeOCAv3;

import axios from "axios";
import { apiURL } from "../config";

const getCreditAppStatus = id => {
  return axios.post(apiURL, {
    operationName: null,
    variables: {},
    query: `{creditApplication(id: "${id}") {id prequalStatus paymentSelectorURL lenderStatus
      proposal{
        id
        lenderPaymentOptions{
          paymentId
          proposalId
          payment
        }
      }
  }}`,
  });
};

export default getCreditAppStatus;

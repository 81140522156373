import React from "react";
import Fade from "@material-ui/core/Fade";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import searchGIF from "../assets/images/searching-papers-blue-small.gif";
import { DeltaTimer } from "../utils";
import styles from "./General.module.css";

class ProcessingScreen extends React.Component {
  constructor(props) {
    super(props);
    this.setState = this.setState.bind(this);
  }

  state = {
    fadeInterval: 3600,
    fadeIn: false,
    fadeTextArr: [
      "Reaching out to lenders network...",
      "Comparing financial products...",
      "Sorting out interest rates...",
      "Scrutinizing spreadsheets...",
      "Comparing payment options...",
      "Looking at alternate sources...",
    ],
    fadeTextI: -1,
  };

  componentDidMount() {
    var timer = new DeltaTimer(time => {
      this.setState((prevState, props) => {
        const { fadeIn, fadeTextArr, fadeTextI } = prevState;
        let nextFadeTextI = fadeTextI;
        if (!fadeIn) {
          if (fadeTextI === fadeTextArr.length - 1) {
            nextFadeTextI = 0;
          } else {
            nextFadeTextI += 1;
          }
        }
        return { fadeIn: !fadeIn, fadeTextI: nextFadeTextI };
      });
    }, this.state.fadeInterval);

    timer.start();
  }

  render() {
    const { fadeInterval, fadeIn, fadeTextArr, fadeTextI } = this.state;
    return (
      <React.Fragment>
        <span className={styles.cardTitle}>Please give us a moment while we take a look at your credit.</span>
        <p>
          This may take a few minutes. <strong>Please do not navigate away from this screen.</strong>
        </p>
        <br />
        <Container maxWidth="sm">
          <Box height={1}>
            <Container maxWidth="lg" fixed={true}>
              <Fade in={fadeIn} timeout={fadeInterval}>
                <Typography align="center" variant="h3" style={{ fontFamily: "Source Sans Pro, sans-serif" }}>
                  {fadeTextArr[fadeTextI]}
                </Typography>
              </Fade>
            </Container>
          </Box>
          <div className="row">
            <div className="col s12">
              <img className="responsive-img" src={searchGIF} alt="Searching" />
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default ProcessingScreen;

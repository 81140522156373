import React, { useContext, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Field } from "react-final-form";
import VendorContext from "../vendorContext";
import Dropzone, { useDropzone } from "react-dropzone";
import Previews from "./FileUpload";
import styles from "./General.module.css";
import { getURLParameter } from "../utils";
import { Form } from "react-final-form";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import { gql } from "apollo-boost";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { Typography } from "@material-ui/core";
import { jsx, css } from "@emotion/core";
import TextField from "@material-ui/core/TextField";
import { Input } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import { SentimentSatisfied, TramRounded } from "@material-ui/icons";
import { Checkbox } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Modal from "@material-ui/core/Modal";
import ReactHtmlParser from "react-html-parser";
import { FormattedMessage, useIntl } from "react-intl";
import defaultMessage from "../components/Message";

export const theme = createMuiTheme({
  typography: {
    fontFamily: "Homemade Apple, cursive",
  },
});

const config = require("../config");
const apiURL = (process.env.REACT_APP_DCR_API_BASE_SERVER_URL || "https://api.dev.dcrportal.com") + "/v1/graphql";
// const apiURL = "http://localhost:3000/v1/graphql";

const WaiverComponent = ({ text }) => {
  return <>{ReactHtmlParser(text)}</>;
};

function DLUpload(props) {
  const lang = useIntl();

  const UPLOAD_FILE = `
    mutation($input: UpsertDocumentInput!, $file: Upload) {
      upsertDocument(input: $input, file: $file) 
    }
  `;

  const UPDATE_OWNER_PG = gql`
    mutation ($input: UpdateCreditApplicationInput!) {
      updateCreditApplicationOwnerPG(input: $input)
    }
  `;

  const GET_ACCOUNT = gql`
    query ($dynamicsId: ID) {
      publicAccount(dynamicsId: $dynamicsId) {
        name
        address
        vendorProfile {
          dcrDisclosureTerms
          dcrDisclosureLanguage
          dcrDisclosureTermsSpanish
          dcrDisclosureLanguageSpanish
        }
      }
    }
  `;

  const GET_CONTACT = gql`
    query contact($dynamicsId: ID!) {
      contact(dynamicsId: $dynamicsId) {
        email
        firstName
        lastName
        fullName
        title
        phoneNumber
        vendorContactRole
        mugshot
        accountId
      }
    }
  `;

  const GET_CREDIT_APP = gql`
    query ($id: ID!) {
      creditApplication(id: $id) {
        id
        dynamicsId
        amountRequested
        businessName
        amountRequested
        equipmentDescription
        firstName
        lastName
        creditApplicationOwner {
          firstName
          lastName
          title
          signature
          ownerPgId
          dlUploaded
        }
      }
    }
  `;

  const demoData = {
    owner: 0,
    vendorGUID: "38e27536-7ccd-e811-80fa-005056b05a0f",
    caId: "57074348-e843-11ed-95ed-bbd25701f850",
    repId: "d49dc1f1-7da2-ed11-816c-066ef92796c9",
  };

  const demo = getURLParameter("demo");
  const { logo } = useContext(VendorContext);
  const creditAppId = demo ? demoData.caId : getURLParameter("caId");
  const vendorGUID = demo ? demoData.vendorGUID : getURLParameter("vendorGUID");
  const repId = demo ? demoData.repId : getURLParameter("repId");
  const owner = demo ? demoData.owner : getURLParameter("own");
  const [file, setFile] = useState();
  const fields = {};
  const setFields = {};
  const [account, setAccount] = useState();
  const [creditApp, setCreditApp] = useState();
  const [disclosure, setDisclousure] = useState("");
  const [vsp, setVsp] = useState();
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);

  useEffect(() => {
    if (!!account && !!account.publicAccount.vendorProfile.dcrDisclosureLanguage) {
      if (lang.locale == "es" && !!account.publicAccount.vendorProfile.dcrDisclosureLanguageSpanish) {
        setDisclousure(
          account.publicAccount.vendorProfile.dcrDisclosureLanguageSpanish
            ? account.publicAccount.vendorProfile.dcrDisclosureLanguageSpanish
            : account.publicAccount.vendorProfile.dcrDisclosureLanguage
        );
      } else {
        setDisclousure(account.publicAccount.vendorProfile.dcrDisclosureLanguage);
      }
    }
  }, [lang, account]);

  const useStyles = makeStyles(theme => ({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflowY: "auto",
    },
  }));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { data: accountData } = useQuery(GET_ACCOUNT, {
    variables: {
      dynamicsId: vendorGUID,
    },
  });

  const { data: creditAppData } = useQuery(GET_CREDIT_APP, {
    variables: {
      id: creditAppId,
    },
  });

  const { data: vendorSalesPerson } = useQuery(GET_CONTACT, {
    variables: {
      dynamicsId: repId,
    },
  });

  useEffect(() => {
    setCreditApp(creditAppData);
  }, [creditAppData]);
  useEffect(() => {
    setAccount(accountData);
  }, [accountData]);
  useEffect(() => {
    setVsp(vendorSalesPerson);
  }, [vendorSalesPerson]);

  [fields.file, setFields.file] = useState({
    value: null,
    validations: [{ type: "required" }],
  });
  [fields.logo, setFields.logo] = useState({ value: null });
  const [submitting, setSubmitting] = useState(false);
  const [page, setPage] = useState("dlUpload");

  function setValue(value, fieldName) {
    const field = {};
    if (!!fields[fieldName].format && typeof fields[fieldName].format === "function") {
      field.format = fields[fieldName].format;
      value = field.format(value);
    }
    field.value = value;
    setFields[fieldName](field);
  }

  const [updateCreditAppRequest, { data: updateCreditAppResponseData, loading: loadingUpdateCreditApp }] = useMutation(UPDATE_OWNER_PG);

  const [value, setSignature] = useState("");
  const [isChecked, setChecked] = useState(false);
  const [needDL, setNeedDL] = useState(false);
  const [needSignature, setNeedSignature] = useState(false);
  const [alreadySubmitted, setAlreadySubmitted] = useState(false);

  const handleCheckBox = e => {
    setChecked(e.target.checked);
  };

  const handleChange = e => {
    setSignature(e.target.value);
  };

  useEffect(() => {
    if (!!creditApp && !!creditApp.creditApplication) {
      if (
        (creditApp.creditApplication.creditApplicationOwner[owner].dlUploaded === false || !creditApp.creditApplication.creditApplicationOwner[owner].dlUploaded) &&
        !creditApp.creditApplication.creditApplicationOwner[owner].signature
      ) {
        {
          setNeedDL(true);
        }
        setNeedSignature(true);
      }

      if (
        (creditApp.creditApplication.creditApplicationOwner[owner].dlUploaded === true && creditApp.creditApplication.creditApplicationOwner[owner].signature == null) ||
        !creditApp.creditApplication.creditApplicationOwner[owner].signature
      ) {
        {
          setNeedSignature(true);
        }
      }
      if (creditApp.creditApplication.creditApplicationOwner[owner].dlUploaded === false && creditApp.creditApplication.creditApplicationOwner[owner].signature) {
        setNeedDL(true);
      }
      if (creditApp.creditApplication.creditApplicationOwner[owner].dlUploaded === true && creditApp.creditApplication.creditApplicationOwner[owner].signature) {
        setAlreadySubmitted(true);
      }
    }
  });

  function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      width: "400px",
      border: "2px solid #000",
      height: "500px",
      padding: "16px 32px 24px",
      position: "absolute",
      boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)",
      overflowY: "auto",
      backgroundColor: "#fff",
    };
  }

  const classes = useStyles();

  const body = (
    <div style={modalStyle}>
      <h2 id="simple-modal-title">
        <FormattedMessage id="dcrTermsAndConditions" defaultMessage={defaultMessage.dcrTermsAndConditions} />
      </h2>
      <div id="simple-modal-description">
        <FormattedMessage id="termsAndConditions1" defaultMessage={defaultMessage.termsAndConditions1} />{" "}
        <a title="Privacy and Security" href="https://www.dealercreditresources.com/privacy/" target="_blank">
          https://www.dealercreditresources.com/privacy/
        </a>{" "}
        <FormattedMessage id="termsAndConditions2" defaultMessage={defaultMessage.termsAndConditions2} />
      </div>
      <br />
      {!!account && !!account.publicAccount.vendorProfile.dcrDisclosureTerms && <WaiverComponent text={account.publicAccount.vendorProfile.dcrDisclosureTerms} />}
    </div>
  );

  function signatureInputField() {
    return (
      <>
        {disclosure && <WaiverComponent text={disclosure} />}
        <Button required={true} onClick={handleOpen} href="#text-buttons" color="primary">
          <h4>
            <FormattedMessage id="iAccept" defaultMessage={defaultMessage.iAccept} />
          </h4>{" "}
          &nbsp;{" "}
          <h4>
            <u>
              <FormattedMessage id="termsAndConditions" defaultMessage={defaultMessage.termsAndConditions} />
            </u>
          </h4>
        </Button>
        <Modal open={open} onClose={handleClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
          {body}
        </Modal>
        <Checkbox
          style={{
            color: "#0c84fc",
          }}
          onChange={handleCheckBox}
        />{" "}
        &nbsp;
        <ThemeProvider theme={theme}>
          <TextField
            name="signature"
            placeholder={<FormattedMessage id="signHere" defaultMessage={defaultMessage.signHere} />}
            required={true}
            value={value}
            onChange={handleChange}
            type="string"
            variant="outlined">
            {" "}
          </TextField>
        </ThemeProvider>
      </>
    );
  }

  function uploadButtonHandler(event) {
    const file = document.getElementById("file");
    file.click();
  }

  function renderDLUpload() {
    return (
      <>
        <Button startIcon={<CloudUploadIcon />} className="formBtn" color="primary" variant="outlined" style={{ marginRight: "8px" }} onClick={uploadButtonHandler}>
          <FormattedMessage id="uploadDL" defaultMessage={defaultMessage.uploadDL} />
          &nbsp;
        </Button>
      </>
    );
  }

  function renderMissingFields() {
    if (
      (creditApp.creditApplication.creditApplicationOwner[owner].dlUploaded === false || !creditApp.creditApplication.creditApplicationOwner[owner].dlUploaded) &&
      !creditApp.creditApplication.creditApplicationOwner[owner].signature
    ) {
      return (
        <>
          <h3>
            <u>
              <FormattedMessage id="dlUploadPagePleaseReview" defaultMessage={defaultMessage.dlUploadPagePleaseReview} />{" "}
            </u>
            <h4>
              {" "}
              a.
              <FormattedMessage id="dlUploadPageUploadPicture" defaultMessage={defaultMessage.dlUploadPageUploadPicture} />{" "}
            </h4>
            <h4>
              {" "}
              b. <FormattedMessage id="dlUploadPageProvideAuth" defaultMessage={defaultMessage.dlUploadPageProvideAuth} />
            </h4>
          </h3>
          <br />
          {renderDLUpload()}
          <br />
          <br />
          {signatureInputField()}
        </>
      );
    }

    if (creditApp.creditApplication.creditApplicationOwner[owner].dlUploaded === true && !creditApp.creditApplication.creditApplicationOwner[owner].signature) {
      return (
        <>
          <h3>
            <u>
              <FormattedMessage id="dlUploadPagePleaseReview" defaultMessage={defaultMessage.dlUploadPagePleaseReview} />{" "}
            </u>
            <h4>
              {" "}
              a. <FormattedMessage id="dlUploadPageProvideAuth" defaultMessage={defaultMessage.dlUploadPageProvideAuth} />{" "}
            </h4>
          </h3>
          <br />
          {signatureInputField()}
        </>
      );
    }

    if (creditApp.creditApplication.creditApplicationOwner[owner].dlUploaded === false && creditApp.creditApplication.creditApplicationOwner[owner].signature) {
      return (
        <>
          <h3>
            <u>
              <FormattedMessage id="dlUploadPagePleaseReview" defaultMessage={defaultMessage.dlUploadPagePleaseReview} />{" "}
            </u>
            <h4>
              {" "}
              a. <FormattedMessage id="dlUploadPageUploadPicture" defaultMessage={defaultMessage.dlUploadPageUploadPicture} />{" "}
            </h4>
          </h3>
          <br />
          {renderDLUpload()}
        </>
      );
    }

    if (creditApp.creditApplication.creditApplicationOwner[owner].dlUploaded === true && creditApp.creditApplication.creditApplicationOwner[owner]) {
      return (
        <>
          <h3>
            <u>
              <FormattedMessage id="dlUploadPageThanks" defaultMessage={defaultMessage.dlUploadPageThanks} />
            </u>
          </h3>
          <br />
        </>
      );
    }
  }
  return (
    <>
      {page === "dlUpload" ? (
        <>
          {logo ? (
            <div className={styles.vendorLogoWrapper}>
              <img
                src={logo && logo.includes("/") ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${logo}` : `https://credit-app-images.s3-us-west-2.amazonaws.com/${logo}`}
                alt="Vendor Logo"
              />
            </div>
          ) : (
            ""
          )}
          <Typography align="center">
            <h1>
              <FormattedMessage id="dlUploadPageAddendum" defaultMessage={defaultMessage.dlUploadPageAddendum} />
            </h1>
          </Typography>
          {!!account && (
            <>
              <Typography align="center" variant="h5" style={{ fontFamily: "Source Sans Pro, sans-serif" }}></Typography>

              <div>
                <u>
                  <FormattedMessage id="dlUploadPageDealer" defaultMessage={defaultMessage.dlUploadPageDealer} />:
                </u>
                <br />
                <br /> {account.publicAccount.name}
              </div>
            </>
          )}
          {!!vsp && (
            <>
              <div>
                <u>
                  <FormattedMessage id="dlUploadPageRep" defaultMessage={defaultMessage.dlUploadPageRep} />:
                </u>
                <br />
                <br /> {vsp.contact.firstName + " " + vsp.contact.lastName}
              </div>
              <div>
                <u>
                  {" "}
                  <FormattedMessage id="dlUploadPagePhone" defaultMessage={defaultMessage.dlUploadPagePhone} />:
                </u>
                <br />
                <br /> {vsp.contact.phoneNumber}
              </div>
              <div>
                <u>
                  <FormattedMessage id="dlUploadPageEmail" defaultMessage={defaultMessage.dlUploadPageEmail} />:
                </u>
                <br />
                <br /> {vsp.contact.email}
              </div>
            </>
          )}
          <br />
          {!!creditApp && (
            <>
              <Typography align="center" variant="h5" style={{ fontFamily: "Source Sans Pro, sans-serif" }}>
                <FormattedMessage id="dlUploadPageApplicant" defaultMessage={defaultMessage.dlUploadPageApplicant} />
              </Typography>
              <div>
                <u>
                  <FormattedMessage id="name" defaultMessage={defaultMessage.name} />:
                </u>
                <br />
                <br /> {creditApp.creditApplication.businessName}
              </div>
              <br />
              <div>
                <u>
                  <FormattedMessage id="amountRequested" defaultMessage={defaultMessage.amountRequested} />:
                </u>
                <br />
                <br /> ${creditApp.creditApplication.amountRequested}
              </div>
              <br />
              <div>
                <u>
                  <FormattedMessage id="dlUploadPageEquipmentDetails" defaultMessage={defaultMessage.dlUploadPageEquipmentDetails} />:
                </u>
                <br />
                <br /> {creditApp.creditApplication.equipmentDescription}
              </div>
              <br />
              <div>
                <u>
                  <FormattedMessage id="owner" defaultMessage={defaultMessage.owner} />:
                </u>
                <br />
                <br /> {creditApp.creditApplication.creditApplicationOwner[owner].firstName + " " + creditApp.creditApplication.creditApplicationOwner[owner].lastName},{" "}
                {creditApp.creditApplication.creditApplicationOwner[owner].title}
              </div>
              <br />
              {renderMissingFields()}
            </>
          )}
          <br />
          <br />
          <br />
          <Form
            onSubmit={async values => {
              if (fields.file.value) {
                const formData = new FormData();
                formData.append(
                  "operations",
                  JSON.stringify({
                    query: UPLOAD_FILE,
                    variables: {
                      input: {
                        vendorOpportunityId: "",
                        source: "OCA",
                        createdBy: "testing",
                        docType: "driver license",
                        docName: "driver license",
                        creditAppId: creditAppId,
                        vendorGUID: vendorGUID.toString(),
                        docDescription: `${
                          creditApp.creditApplication.creditApplicationOwner[owner].firstName + " " + creditApp.creditApplication.creditApplicationOwner[owner].lastName
                        } driver's license`,
                      },
                      file: fields.file.value,
                    },
                  })
                );
                if (fields.file.value) {
                  formData.append(
                    "map",
                    JSON.stringify({
                      0: ["variables.file"],
                    })
                  );
                  formData.append("0", fields.file.value);
                }

                return new Promise(async (resolve, reject) => {
                  fetch(apiURL, {
                    method: "POST",
                    body: formData,
                  })
                    .then(response => response.json())
                    .then(async response => {
                      response && response.data && response.data.upsertFile && setFile(response.data.upsertFile);
                      try {
                        if (value && response.data.upsertDocument.success) {
                          await updateCreditAppRequest({
                            variables: {
                              input: {
                                id: creditAppId,
                                owners: {
                                  ownerPgId: creditApp.creditApplication.creditApplicationOwner[owner].ownerPgId,
                                  signature: value,
                                  dlUploaded: true,
                                },
                                auth: true,
                              },
                            },
                          });
                          setSubmitting(true);
                          setPage("complete");
                        } else if (value) {
                          await updateCreditAppRequest({
                            variables: {
                              input: {
                                id: creditAppId,
                                owners: {
                                  ownerPgId: creditApp.creditApplication.creditApplicationOwner[owner].ownerPgId,
                                  signature: value,
                                },
                                auth: true,
                              },
                            },
                          });
                          setSubmitting(true);
                          setPage("complete");
                        } else if (response.data.upsertDocument.success) {
                          await updateCreditAppRequest({
                            variables: {
                              input: {
                                id: creditAppId,
                                owners: {
                                  ownerPgId: creditApp.creditApplication.creditApplicationOwner[owner].ownerPgId,
                                  dlUploaded: true,
                                },
                                auth: true,
                              },
                            },
                          });
                          setSubmitting(true);
                          setPage("complete");
                        }
                      } catch (err) {
                        window.NREUM.noticeError(err);
                        console.error(err);
                      }
                    })
                    .catch(err => {
                      window.NREUM.noticeError(err);
                      console.log(err);
                    });
                });
              }
              if (value) {
                await updateCreditAppRequest({
                  variables: {
                    input: {
                      id: creditAppId,
                      owners: {
                        ownerPgId: creditApp.creditApplication.creditApplicationOwner[owner].ownerPgId,
                        signature: value,
                      },
                      auth: true,
                    },
                  },
                });
                setSubmitting(true);
                setPage("complete");
              }
            }}
            render={({ handleSubmit }) => {
              function uploadFileChanged(event) {
                setValue(event.target.files[0], "file");
                const reader = new FileReader();
                reader.onload = e => {
                  const avatar = document.getElementById("avatar");
                  avatar.src = e.target.result;
                  setValue(e.target.result, "logo");
                };
                reader.readAsDataURL(event.target.files[0]);
              }
              return (
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    handleSubmit();
                  }}>
                  <input id="file" type="file" style={{ display: "none" }} onChange={uploadFileChanged} />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}>
                    <img
                      id="avatar"
                      alt="Company Logo"
                      style={
                        fields.logo.value
                          ? {
                              marginRight: "32px",
                              height: "64px",
                              width: "64px",
                              borderRadius: "50%",
                            }
                          : {
                              height: "1x",
                              width: "1px",
                              borderRadius: "50%",
                            }
                      }
                      src={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="}
                    />
                  </div>
                  <Button
                    className="formBtn"
                    color="primary"
                    variant="contained"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={(needSignature && !value) || (needSignature && !isChecked) || (needDL && !fields.file.value) || submitting || alreadySubmitted}>
                    <FormattedMessage id="submit" defaultMessage={defaultMessage.submit} />
                  </Button>
                </form>
              );
            }}
          />
        </>
      ) : (
        <h3>
          {" "}
          <FormattedMessage id="dlUploadPageReceivedDL" defaultMessage={defaultMessage.dlUploadPageReceivedDL} />
        </h3>
      )}
    </>
  );
}
export default DLUpload;

import React from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

const LoadButton = ({ children, loading, ...rest }) => (
  <>
    <Button {...rest}>{children}</Button>
    {loading && (
      <div style={{ padding: "0 12px", display: "inline-block" }}>
        <CircularProgress size={16} />
      </div>
    )}
  </>
);

export default LoadButton;

import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import VendorContext from "../vendorContext";
import styles from "./CreditApplicationWrapper.module.css";
import Button from "@material-ui/core/Button";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import MessageModal from "../components/MessageModal";
import CheckSVG from "../assets/images/Check.svg";
import ReCAPTCHA from "react-google-recaptcha";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import mixpanel from "../mixpanel";
import checkFormIdStatus from "../api/checkFormIdStatus";
import { getValidFormId } from "../utils/saveAndReturn";
import { v4 as uuidv4 } from "uuid";
import { useHistory, useLocation } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { useFormik } from "formik";
import { FieldInput } from "./components/FieldInput";
import { contactFormPartnerValidator } from "./validators";

const config = require("../config");

const useStyles = makeStyles(theme => ({
  equipmentPicContainer: {
    [theme.breakpoints.up("md")]: {
      maxHeight: 257,
      maxWidth: 400,
    },
  },
  equipmentPic: {
    borderTopLeftRadius: 7,
    borderBottomLeftRadius: 7,
    display: "block",
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      borderTopLeftRadius: 7,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: 7,
    },
  },
  personalInfoStyles: {
    [theme.breakpoints.up("md")]: {
      width: "calc(50% - 30px) !important",
      marginRight: "30px",
      maxWidth: 400,
    },
  },
  successesSentEmailHeader: {
    fontWeight: "300",
    fontSize: "26px",
  },
  iconSuccess: {
    width: "37px",
    height: "37px",
    marginRight: "20px",
  },
  columnHeader: {
    marginBottom: "20px",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "23.5px",
    letterSpacing: "-0.00941173px",
  },
  columnText: {
    fontSize: "14px",
    lineHeight: "16px",
    color: "#606060",
  },
  column: {
    borderRight: "1px solid #CCCCCC",
    "@media (max-width: 959px)": {
      border: "none",
      marginBottom: "20px",
    },
  },
  columnContentContainer: {
    justifyContent: "center",
    "@media (max-width: 959px)": {
      paddingTop: "20px",
      border: "none",
    },
  },
  successSentContainer: {
    padding: "50px 90px 30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 540px)": {
      padding: "50px 30px 30px",
    },
  },
  equipmentInfoContainer: {
    marginBottom: 60,
    borderRadius: 7,
    boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.116852)",
    position: "relative",
  },
  equipmentInfoHeader: {
    fontWeight: 500,
    fontSize: 30,
    lineHeight: "35px",
    marginBottom: 20,
  },
  dealerInfoContainer: {
    padding: 20,
  },
  companyLogoUrl: {
    position: "inherit",
    maxHeight: "50px",
    right: 0,
    top: 0,
    marginRight: 20,
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      marginTop: 20,
    },
  },
  dealerInfoHeader: {
    fontWeight: 700,
    fontSize: 30,
    lineHeight: "35px",
    marginBottom: 20,
  },
  companyInfo: {},
  companyTitle: {
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "16px",
  },
  companyName: {
    margin: "3px 0 5px",
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "16px",
    textTransform: "uppercase",
    color: "#818181",
  },
  equipmentDesHeader: {
    fontSize: 30,
    fontWeight: 700,
    margin: "20px 0 15px",
    lineHeight: "35px",
  },
  URLItem: {
    flexBasis: "auto",
    flex: "1 1",
    fontSize: 14,
    fontWeight: 700,
    lineHeight: "16px",
    maxWidth: "none",
  },
  URLParametrText: {
    margin: 0,
    fontSize: 14,
    fontWeight: 300,
    lineHeight: "16px",
    textTransform: "uppercase",
    color: "#818181",
  },
  partnerFormHeaderText: {
    fontWeight: 500,
    fontSize: 30,
    lineHeight: "35px",
  },
  partnerFormInput: {},
  inputContainer: {
    position: "relative",
    marginBottom: "28px !important",
    "& .MuiFormLabel-root": {
      color: "#000",
    },
    "& .MuiInputBase-root": {
      maxHeight: 50,
      borderRadius: 7,
    },
    "& .MuiFormHelperText-root": {
      position: "absolute",
      bottom: -20,
    },
  },
  submitContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  sendEmailButton: {
    borderRadius: 7,
  },
  captchaContainer: {
    "& > div": {
      marginRight: "30px !important",
    },
    marginBottom: 25,
    marginLeft: 45,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: 20,
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: 0,
      marginTop: 0,
    },
    [theme.breakpoints.only("md")]: {
      marginLeft: 0,
      marginTop: 0,
    },
  },
}));

const CREATE_LEAD = gql`
  mutation ($input: CreateLeadInput!) {
    createLead(input: $input) {
      id
    }
  }
`;

const SEND_LINK = gql`
  mutation ($input: sendContactFormOCALinkInput!) {
    sendContactFormOCALink(input: $input) {
      success
    }
  }
`;

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      format="+1 (###) ###-####"
      mask="_"
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const getURLDCRParameters = paramKey => {
  const paramValue = new URL(window.location.href).searchParams.get(paramKey);
  if (paramValue) return paramValue;
  return "";
};

const ContactForm = () => {
  const classes = useStyles();
  const [formId, setFormId] = useState(getValidFormId());
  let location = useLocation();
  let history = useHistory();

  const { partnerLogo, accountName, partnerName, vpId, color: vendorColor } = useContext(VendorContext);
  const partnerId = getURLDCRParameters("partnerId");
  const partnerDealerId = getURLDCRParameters("partnerDealerId");
  const dealerId = getURLDCRParameters("dealerId");
  const equipmentId = getURLDCRParameters("equipId");
  const manufacturer = getURLDCRParameters("manufacturer");
  const model = getURLDCRParameters("model");
  const serialNum = getURLDCRParameters("serialNum");
  const stockNumber = getURLDCRParameters("stockNumber");
  const equipmentCondition = getURLDCRParameters("equipmentCondition");
  const equipmentPictureUrl = getURLDCRParameters("equipmentPictureUrl");
  const companyLogoUrl = getURLDCRParameters("companyLogoUrl");
  const equipmentYear = getURLDCRParameters("equipmentYear");
  const equipmentMake = getURLDCRParameters("equipmentMake");
  const equipmentModel = getURLDCRParameters("equipmentModel");
  const equipmentHours = getURLDCRParameters("equipmentHours");
  const equipmentSN = getURLDCRParameters("equipmentSN"); //Serial Number
  const equipmentDescriptionURL = getURLDCRParameters("equipmentDescription");
  const dealerName = getURLDCRParameters("dealerName");
  const price = getURLDCRParameters("price");
  const machineURL = getURLDCRParameters("machineURL");
  const [openMessageModal, setOpenMessageModal] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [isPassedCaptcha, setIsPassedCaptcha] = useState(false);
  const formik = useFormik({
    initialValues: {
      contactFirstName: "",
      contactLastName: "",
      contactEmail: "",
      contactPhone: "",
      contactQuestion: "",
      businessName: "",
    },
    validationSchema: contactFormPartnerValidator,
    onSubmit: () => handleSubmit(),
  });

  const { touched, ...rest } = formik;

  useEffect(() => {
    if (formId) {
      //check if formId is available already
      // Using an IIFE

      (async formId => {
        try {
          const { data } = await checkFormIdStatus(formId);
          const used = !!data.data.checkFormIdStatus && !!data.data.checkFormIdStatus.used;

          if (used) {
            const newFormId = uuidv4();
            setFormId(newFormId);
            var urlParams = new URLSearchParams(location.search);
            urlParams.set("formId", newFormId);

            history.push({
              pathname: location.pathname,
              search: urlParams.toString(),
            });
          }
        } catch (e) {
          window.NREUM.noticeError(e);
          console.log(e);
          console.log("Failed to reload OCA");
        }
      })(formId);

      try {
        window.waitForGlobal("_mfq", function () {
          if (formId) {
            window._mfq.push(...window._mfq, ["setVariable", "FORM_ID", formId]);
          }
        });
      } catch (error) {
        window.NREUM.noticeError(error);
      }
    }
    if (!formId) {
      setFormId(uuidv4());
    } else {
      const query = new URLSearchParams(location.search);
      if (!query.get("formId")) {
        const url = location.search ? `${location.search}&formId=${formId}` : `?formId=${formId}`;

        history.push({
          pathname: location.pathname,
          search: url,
        });
      }
    }
  }, [formId]);

  const siteKey = config.captchaSiteKey;

  const equipmentDescription = `${equipmentId} ${manufacturer} ${model} ${serialNum} ${stockNumber}`;

  const [createLead] = useMutation(CREATE_LEAD, {
    context: { authRequired: true },
  });

  const [sendLink] = useMutation(SEND_LINK);

  const handleCloseModal = () => setOpenMessageModal(false);
  const handleOpenModal = () => setOpenMessageModal(true);
  const handleSubmissionMessage = message => setSubmissionMessage(message);

  const handleSubmit = async () => {
    try {
      handleOpenModal();
      mixpanel.track("Complete Dealer Contact Form", { send_immediately: true });
      console.log(`:: TRACKED :: Complete Dealer Contact Form`);

      handleSubmissionMessage({
        title: "Sending",
        message: "Please wait. Sending the email...",
      });

      let description = "";

      if (equipmentId) {
        description += `equipment id: ${equipmentId}  - `;
      }

      if (manufacturer) {
        description += `manufacturer: ${manufacturer}  - `;
      }

      if (model) {
        description += `model: ${model}  - `;
      }

      if (serialNum) {
        description += `serial number: ${serialNum} - `;
      }

      if (stockNumber) {
        description += `stock number: ${serialNum}`;
      }

      await createLead({
        variables: {
          input: {
            email: formik.values.contactEmail,
            fullName: `${formik.values.contactFirstName} ${formik.values.contactLastName}`,
            phone: formik.values.contactPhone,
            partnerId: partnerId,
            partnerDealerId: partnerDealerId,
            emailRemindersEnabled: false,
            source: "Partner Contact Form",
            send: true,
            description,
            userProfile: {},
            businessName: formik.values.businessName,
            amountRequested: parseFloat(price),
            communicationDetail: formik.values.contactQuestion,
            machine: `${manufacturer} ${model}`,
            serialNumber: serialNum,
            stockNumber: stockNumber,
            partnerName: partnerName.slice(5),
            machineURL,
            formId: formId,
          },
        },
      });
      handleSubmissionMessage({
        title: "Success",
        message: "Sent Successfully",
      });
      setFormSubmitted(true);
    } catch (e) {
      console.log(e);
      handleSubmissionMessage({
        title: "Error",
        message: "There was something wrong with your request",
      });
    }
  };

  const handleSendLink = async () => {
    try {
      handleOpenModal();
      mixpanel.track("Sending DCF Complete OCA later link", { send_immediately: true });
      console.log(`:: TRACKED :: Sending DCF Complete OCA later link`);
      handleSubmissionMessage({
        title: "Sending",
        message: "Please wait. Sending the link...",
      });
      const link = encodeURIComponent(`?partnerId=${partnerId}&partnerDealerId=${partnerDealerId}&formId=${formId}`);
      await sendLink({
        variables: {
          input: {
            email: formik.values.contactEmail,
            fullName: `${formik.values.contactFirstName} ${formik.values.contactLastName}`,
            phone: formik.values.contactPhone,
            link: link,
            vpId: vpId,
          },
        },
      });
      handleSubmissionMessage({
        title: "Success",
        message: "Sent Successfully",
      });
      setFormSubmitted(true);
    } catch (e) {
      handleSubmissionMessage({
        title: "Error",
        message: "There was something wrong with your request",
      });
    }
  };

  const disableSubmit = () => {
    if (Object.keys(formik.touched).length !== 0 && Object.keys(formik.errors).length === 0 && isPassedCaptcha) {
      return false;
    } else {
      return true;
    }
  };

  const disableSendLink = () => {
    if ((!formik.errors.contactEmail && formik.touched.contactEmail) || (!formik.errors.contactPhone && formik.touched.contactPhone)) {
      return false;
    } else {
      return true;
    }
  };

  const handlePrequalify = async () => {
    mixpanel.track("Click to Prequalify", { send_immediately: true });
    console.log(`:: TRACKED :: Click to Prequalify`);

    return (window.location.href = `
    /?partnerId=${partnerId}&partnerDealerId=${partnerDealerId}&price=${price}&firstName=${formik.values.contactFirstName}&lastName=${formik.values.contactLastName}&equipmentDescription=${equipmentDescription}
    &firstName=${formik.values.contactFirstName}&lastName=${formik.values.contactLastName}&phone=${formik.values.contactPhone}&email=${formik.values.contactEmail}
    &equipmentCondition=${equipmentCondition}
    &equipmentYear=${equipmentYear}&equipmentMake=${manufacturer}&equipmentModel=${model}&companyLogoUrl=${companyLogoUrl}
    &equipmentHours=${equipmentHours}&equipmentSN=${equipmentSN}&equipmentPictureUrl=${equipmentPictureUrl}&formId=${formId}`);
  };

  const handleTextDealer = async () => {
    mixpanel.track("DCF Text Dealer", { send_immediately: true });
    console.log(`:: TRACKED :: DCF Text Dealer`);
    try {
      handleOpenModal();
      handleSubmissionMessage({
        title: "Error",
        message: "Sorry but this dealer does not have this feature enabled.",
      });
    } catch (e) {
      console.log(e);
      handleSubmissionMessage({
        title: "Error",
        message: "There was something wrong with your request",
      });
    }
  };

  useEffect(() => {
    if (!!partnerDealerId && !!partnerId && !!dealerName && !!config.APP_ENV) {
      mixpanel.register({
        partnerDealerId,
        dealerName,
        partnerId,
        environment: config.APP_ENV,
      });

      mixpanel.track("Access Dealer Contact Form", { send_immediately: true });
      console.log(`:: TRACKED :: Access Dealer Contact Form`);
    }
  }, [partnerDealerId, partnerId, dealerName]);

  return (
    <>
      {partnerLogo ? (
        <div className={`${styles.vendorLogoWrapper} ${styles.partnerLogoBg}`} style={{ backgroundColor: vendorColor }}>
          <img
            src={
              partnerLogo && partnerLogo.includes("/")
                ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${partnerLogo}`
                : `https://credit-app-images.s3-us-west-2.amazonaws.com/${partnerLogo}`
            }
            alt="Partner Logo"
          />
        </div>
      ) : (
        ""
      )}

      {!formSubmitted ? (
        <form className={styles.partnerForm}>
          <Grid container className={classes.equipmentInfoContainer}>
            {equipmentPictureUrl && equipmentPictureUrl !== "undefined" && (
              <Grid item xs={12} sm={12} md={6} className={classes.equipmentPicContainer}>
                <img className={classes.equipmentPic} style={{ width: "100%" }} src={decodeURIComponent(equipmentPictureUrl)} alt="Equipment Pic" />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={6} className={classes.dealerInfoContainer}>
              <Grid container direction="column">
                <Typography component="h2" variant="h2" className={classes.dealerInfoHeader} style={{ marginBottom: 15 }}>
                  Dealer
                </Typography>
                {companyLogoUrl && companyLogoUrl !== "undefined" && (
                  <Grid item>
                    <img src={decodeURIComponent(companyLogoUrl)} alt="Equipment Pic" className={classes.companyLogoUrl} />
                  </Grid>
                )}
                <Grid item className={classes.companyInfo}>
                  <p className={classes.companyName}>{!!accountName ? accountName : dealerName}</p>
                </Grid>
                <Grid item>
                  {(equipmentId || model || serialNum || manufacturer) && (
                    <Typography component="h2" variant="h2" className={classes.equipmentDesHeader}>
                      Equipment Description
                    </Typography>
                  )}
                  {equipmentDescription.length !== 4 && (
                    <Grid container>
                      {equipmentId && (
                        <Grid item className={classes.URLItem} xs={12} md={3} lg={3}>
                          Equip ID: <p className={classes.URLParametrText}>{equipmentId}</p>
                        </Grid>
                      )}
                      {model && (
                        <Grid item className={classes.URLItem} xs={12} md={3} lg={3}>
                          Model: <p className={classes.URLParametrText}>{model}</p>
                        </Grid>
                      )}
                      {serialNum && (
                        <Grid item className={classes.URLItem} xs={12} md={3} lg={3} justify="flex-end">
                          Serial Number: <p className={classes.URLParametrText}>{serialNum}</p>
                        </Grid>
                      )}
                      {manufacturer && (
                        <Grid item className={classes.URLItem} xs={12} md={3} lg={3}>
                          Manufacturer: <p className={classes.URLParametrText}>{manufacturer}</p>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Typography className={classes.partnerFormHeaderText}>Send an Email</Typography>
          <Grid container direction="column" spacing={1}>
            <Grid container xs={12} md={12} lg={12} className={styles.partnerFormFieldsContainer}>
              <Grid container className={classes.personalInfoStyles}>
                <Grid item xs={12} md={12} lg={12} className={classes.inputContainer}>
                  <FieldInput
                    id="contact-name"
                    name="contactFirstName"
                    value={formik.contactFirstName}
                    className={classes.partnerFormField}
                    label="First Name"
                    placeholder="First Name"
                    inputProps={{
                      className: classes.partnerFormInput,
                    }}
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12} className={classes.inputContainer}>
                  <FieldInput
                    id="contact-last-name"
                    name="contactLastName"
                    value={formik.contactLastName}
                    className={classes.partnerFormField}
                    label="Last Name"
                    placeholder="Last Name"
                    inputProps={{
                      className: classes.partnerFormInput,
                    }}
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12} className={classes.inputContainer}>
                  <FieldInput
                    id="business-name"
                    name="businessName"
                    value={formik.businessName}
                    className={classes.partnerFormField}
                    label="Business Name"
                    placeholder="Business Name"
                    inputProps={{
                      className: classes.partnerFormInput,
                    }}
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12} className={classes.inputContainer}>
                  <FieldInput
                    id="contact-phone"
                    label="Your Phone Number"
                    name="contactPhone"
                    value={formik.contactPhone}
                    className={classes.partnerFormField}
                    placeholder="Enter your phone number here"
                    inputProps={{
                      className: classes.partnerFormInput,
                    }}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    formik={formik}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12} className={classes.inputContainer}>
                  <FieldInput
                    id="contact-email"
                    label="Your Email Address"
                    name="contactEmail"
                    value={formik.contactEmail}
                    className={classes.partnerFormField}
                    placeholder="Enter your Email here"
                    inputProps={{
                      className: classes.partnerFormInput,
                    }}
                    formik={formik}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className={classes.submitContainer}>
                <FieldInput
                  id="contact-question"
                  label="Ask Your Question"
                  placeholder="Enter your question here"
                  value={formik.contactQuestion}
                  InputLabelProps={{
                    className: styles.questionFieldLabel,
                  }}
                  name="contactQuestion"
                  multiline
                  rows={8}
                  formik={formik}
                />
                <Grid container alignItems="flex-end" className={classes.captchaContainer}>
                  <ReCAPTCHA
                    sitekey={siteKey}
                    className={styles.captchaStyles}
                    onChange={() => {
                      setIsPassedCaptcha(!isPassedCaptcha);
                    }}
                  />
                  <Button color="primary" className={`${styles.sendEmailButton} ${classes.sendEmailButton}`} ariant="contained" onClick={handleSubmit} disabled={disableSubmit()}>
                    Send Email
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <MessageModal isOpen={openMessageModal} handleCloseModal={handleCloseModal} title={submissionMessage.title} message={submissionMessage.message} />
        </form>
      ) : (
        <div className={formSubmitted ? classes.successSentContainer : styles.succesSentContainer}>
          <Grid wrap="nowrap" container alignItems="center" justify="center" className={styles.partnerFormHeaderText} style={{ marginBottom: "70px" }}>
            <img src={CheckSVG} className={classes.iconSuccess} />
            <span className={classes.successesSentEmailHeader}>
              {` YOUR EMAIL TO ${!!accountName ? accountName : dealerName} WAS SENT
              SUCCESSFULLY`}
            </span>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={4} className={classes.column}>
              <Grid container justify="center">
                <div>
                  <Grid item xs={12} className={classes.columnHeader}>
                    Want a faster response?
                  </Grid>
                  <Grid item xs={12} className={classes.columnText}>
                    Text to reach out to dealer faster
                  </Grid>
                  <Button color="primary" className={styles.sendEmailButton} style={{ marginTop: "40px", borderRadius: "7px" }} ariant="contained" onClick={() => handleTextDealer()}>
                    Text Dealer
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4} className={classes.column}>
              <Grid container className={classes.columnContentContainer}>
                <div>
                  <Grid item md={12} className={classes.columnHeader}>
                    Prequalify for financing
                  </Grid>
                  <Grid item md={12} className={classes.columnText}>
                    Start your application here
                  </Grid>
                  <Button color="primary" className={styles.sendEmailButton} style={{ marginTop: "40px", borderRadius: "7px" }} ariant="contained" onClick={() => handlePrequalify()}>
                    Prequalify
                  </Button>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <Grid container justify="center" className={classes.columnContentContainer}>
                <div>
                  <Grid item xs={12} className={classes.columnHeader}>
                    Dont have time now?
                  </Grid>
                  <Grid item xs={12} className={classes.columnText}>
                    Send me a link to prequalify
                  </Grid>
                  <Grid item style={{ marginTop: "20px" }} className={classes.inputContainer}>
                    <FieldInput
                      id="contact-email"
                      label="Email"
                      name="contactEmail"
                      value={formik.contactEmail}
                      style={{ marginBottom: "10px" }}
                      size="small"
                      placeholder="Enter your Email here"
                      inputProps={{
                        className: styles.partnerFormInput,
                      }}
                      formik={formik}
                    />
                    <Grid container justify="center" alignItems="center">
                      <Grid item md={5} style={{ backgroundColor: "#CCCCCC", height: "1px" }}></Grid>
                      <Grid item md={1} style={{ padding: "0px 0px 0px 3px", color: "#606060" }}>
                        or
                      </Grid>
                      <Grid item md={5} style={{ backgroundColor: "#CCCCCC", height: "1px" }}></Grid>
                    </Grid>
                    <FieldInput
                      id="contact-phone"
                      label="Phone Number"
                      name="contactPhone"
                      value={formik.contactPhone}
                      style={{ marginTop: "10px" }}
                      size="small"
                      placeholder="Enter your number here"
                      inputProps={{
                        className: styles.partnerFormInput,
                      }}
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      formik={formik}
                    />
                  </Grid>
                  <Button
                    color="primary"
                    className={styles.sendLinkButton}
                    style={{ marginTop: "40px", borderRadius: "7px" }}
                    ariant="contained"
                    onClick={() => handleSendLink()}
                    disabled={disableSendLink()}>
                    SEND ME A LINK
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <MessageModal isOpen={openMessageModal} handleCloseModal={handleCloseModal} title={submissionMessage.title} message={submissionMessage.message} />
        </div>
      )}
    </>
  );
};

export default ContactForm;

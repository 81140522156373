import React, { useContext } from "react";
import styles from "../form/CreditApplicationWrapper.module.css";
import VendorContext from "../vendorContext";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import fonts from "../fonts.module.css";

const config = require("../config");

const stepLabels = ["Credit Application", "Payment Selection", "Sign Your Docs"];

const LenderPSConfirmed = () => {
  const { logo, accountName: name } = useContext(VendorContext);

  return (
    <React.Fragment>
      {logo ? (
        <div className={styles.vendorLogoWrapper}>
          <img
            src={logo && logo.includes("/") ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${logo}` : `https://credit-app-images.s3-us-west-2.amazonaws.com/${logo}`}
            alt="Vendor Logo"
          />
        </div>
      ) : (
        ""
      )}
      <p className={[fonts.pGrey, fonts.h5, styles.stepperTitle].join(" ")} style={{ textAlign: "center" }}>
        Modern Finance Process
      </p>
      <Stepper activeStep={2} alternativeLabel>
        {stepLabels.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <hr />
      <div style={{ height: "28px" }} />
      <div style={{ textAlign: "center" }}>
        <h4>Thank you for selecting your payment option.</h4>
        <br />
        {name ? name : "Your dealer"} who will be working to finalize the details of your equipment purchase.
        <br />
        Once this occurs, you will receive an email notifying you that you financing documents are ready for e-signature.
      </div>
    </React.Fragment>
  );
};

export default LenderPSConfirmed;

import React, { useContext, useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import styles from "./CreditApplicationWrapper.module.css";
import CreditApplicationForm from "./CreditApplicationForm";
import TruckCreditApplicationForm from "./TruckCreditApplicationForm";
import sslLogo from "../assets/images/ssl-logo-300x166.png";
import fonts from "../fonts.module.css";
import VendorContext from "../vendorContext";
import { getURLParameter } from "../utils";
import ComprehensiveCreditApplicationForm from "./ComprehensiveCreditApplicationForm";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import OCAv3 from "./OCAv3";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { FormattedMessage } from "react-intl";
import defaultMessage from "../components/Message";
import Button from "@material-ui/core/Button";
import AdjSidebarStyles from "../layouts/Main/components/Sidebar/AdjustedSidebar.module.css";
import SidebarStyles from "../layouts/Main/components/Sidebar/Sidebar.module.css";
import formatString from "format-string-by-pattern";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import logo from "../assets/images/trnsact-logo-powered-by-light.png";

import queryPublicAccount from "../api/queryPublicAccount";
import { ChatWithDealer } from "./components/ChatWithDealer/ChatWithDealer";
import _ from "lodash";

const config = require("../config");

const stepLabels = [
  <FormattedMessage id="creditApplication" defaultMessage={defaultMessage.creditApplication} />,
  <FormattedMessage id="paymentSelection" defaultMessage={defaultMessage.paymentSelection} />,
  <FormattedMessage id="signYourDocs" defaultMessage={defaultMessage.signYourDocs} />,
];

const useStyles = makeStyles(theme => ({
  stepperContainer: {
    margin: "0 -36px",
  },
  formContainer: {
    filter: props => (props.isOpenModal ? "blur(10px)" : ""),
    pointerEvents: props => (props.isOpenModal ? "none" : "auto"),
  },
  needHelpButton: {
    borderRadius: "4px 4px 0 0",
    backgroundColor: "#9D9D9D !important",
    padding: "10px",
    minWidth: "120px",
    marginTop: 20,
  },
  closeModalButton: {
    backgroundColor: "#1473E6 !important",
    borderRadius: "4px 4px 0 0",
    padding: "10px",
    minWidth: "120px",
    marginTop: 20,
  },
  modalContainer: {
    height: "800px",
    width: "calc(100% - 40px) !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    zIndex: "1200",
    minHeight: "560px",
    [theme.breakpoints.down("md")]: {
      paddingTop: "230px",
    },
  },
  modalDialog: {
    backgroundColor: "#ffffff",
    borderRadius: 8,
    width: "fit-content",
  },
  chatWithDealer: {
    fontWeight: 500,
    fontSize: 16,
    cursor: "pointer",
    color: "#3785F4",
  },
  noVendorLogo: {
    borderLeft: "none",
    textAlign: "center",
  },
}));

const CreditApplicationFormWrapper = props => {
  const [isOpenModal, setOpenModal] = useState(false);
  const [openChat, setOpenChat] = useState(false);

  const { color: vendorColor, logo: vendorLogo, partnerLogo, textColor: textColor } = useContext(VendorContext);
  const classes = useStyles({ isOpenModal, vendorColor });

  const vendorIndustry = props.industry;
  const setDemo = props.setDemo;
  const setRepData = props.setRepData;
  const ocaForm = props.form ? props.form : "Standard v3";
  const [publicData, setPublicData] = useState("");
  const [ocaConfig, setOcaConfig] = useState(undefined);
  const [progressPerc, setProgressPerc] = useState(0);
  const partnerId = props.partnerId;

  const pathname = window.location.pathname;
  const location = window.location.href;
  const isIframe = location.includes("iframeApp=true");
  const isPartner = location.includes("partnerDealerId");

  const [isPH, setPH] = useState(false);
  const [isOT, setOT] = useState(false);
  const [isOcalaTractors, setOcalaTractors] = useState(false);
  const [locations, setLocations] = useState(null);
  const [logoToRender, setLogoToRender] = useState(null);

  const LinearProgressWithLabel = props => {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${progressPerc}%`}</Typography>
        </Box>
      </Box>
    );
  };

  const getPublicAccountData = vendorGUID => {
    if (vendorGUID) {
      queryPublicAccount(vendorGUID.toLowerCase()).then(response => {
        const publicAccount = response && response.data && response.data.data && response.data.data.publicAccount;
        const ocaTemplate = publicAccount && publicAccount.vendorProfile && publicAccount.vendorProfile.ocaTemplate;
        const locations = _.get(response, "data.data.publicAccount.locations");
        if (publicAccount) {
          setPublicData(publicAccount);
        }

        if (ocaTemplate) {
          const activeTemplate = ocaTemplate.find(template => template.active);
          setOcaConfig(activeTemplate && activeTemplate.jsonDefinition);
        }
        setLocations(locations);
      });
    }
  };

  useEffect(() => {
    const vendorGUID = getURLParameter("vendorGUID");
    if (vendorGUID === "4af87cff-d949-e911-8107-005056b05a0f") {
      setPH(true);
    }
    if (vendorGUID === "de89d6a4-173e-ea11-8115-005056b05a0f" || vendorGUID === "DE89D6A4-173E-EA11-8115-005056B05A0F") {
      setOT(true);
    }
    if (vendorGUID === "bb6a5307-6036-eb11-812b-066ef92796c9" || vendorGUID === "BB6A5307-6036-EB11-812B-066EF92796C9") {
      setOcalaTractors(true);
    }
    getPublicAccountData(vendorGUID);
  }, []);

  useEffect(() => {
    if (!locations) {
      return; // if no locations response yet
    }
    const currentLocation = _.find(locations, { locationId: getURLParameter("locationId") });
    const logoToRender = _.get(currentLocation, "logo") || vendorLogo;
    setLogoToRender(logoToRender);
  }, [locations, vendorLogo]);

  const renderOCA = () => {
    if (vendorIndustry === "trucking" && ocaForm === "Simple v2") {
      return <TruckCreditApplicationForm vendorProfile={props.vendorProfile} setVendor={props} vendorIndustry={vendorIndustry} demo={setDemo} ocaForm={ocaForm} setRepData={setRepData} />;
    } else if (ocaForm === "Standard v2") {
      return <ComprehensiveCreditApplicationForm setVendor={props} vendorIndustry={vendorIndustry} ocaForm={ocaForm} demo={setDemo} setRepData={setRepData} />;
    } else if (ocaForm === "Standard v3" || ocaForm === "Comprehensive v3" || ocaForm === "Simple v3") {
      return <OCAv3 vendorProfile={props.vendorProfile} setVendor={props} vendorIndustry={vendorIndustry} demo={setDemo} ocaForm={ocaForm} setRepData={setRepData} progress={setProgressPerc} />;
    } else if (ocaForm === "Simple v2" && (vendorIndustry === "generic" || vendorIndustry === "medical")) {
      return <CreditApplicationForm setVendor={props} vendorIndustry={vendorIndustry} ocaForm={ocaForm} demo={setDemo} setRepData={setRepData} />;
    } else {
      return <OCAv3 setVendor={props} vendorIndustry={vendorIndustry ? vendorIndustry : "generic"} demo={setDemo} ocaForm="Standard v3" setRepData={setRepData} />;
    }
  };

  let { email, mugshot, name, officePhone, availability } = props.repData;

  return (
    <>
      {isOpenModal && (
        <div className={classes.modalContainer}>
          <Grid container justify="center" alignItems="center" direction="column" className={classes.modalDialog}>
            <Grid item>
              <h3 className={fonts.h3} style={{ textAlign: "center" }}>
                <FormattedMessage id="needHelp" defaultMessage={defaultMessage.needHelp} />
              </h3>
              <p style={{ textAlign: "center" }}>
                <FormattedMessage id="representativesHelp" defaultMessage={defaultMessage.representativesHelp} />
              </p>
            </Grid>

            <Grid item className={pathname === `/bankUpload/` ? AdjSidebarStyles.mdColumn : SidebarStyles.mdColumn} style={{ padding: 0 }}>
              <img
                src={
                  mugshot && mugshot.includes("/")
                    ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${mugshot}`
                    : "https://credit-app-images.s3-us-west-2.amazonaws.com/avatar-placeholder.png"
                }
                alt="agent"
                className={pathname === `/bankUpload/` ? AdjSidebarStyles.photo : SidebarStyles.photo}
              />
            </Grid>

            <Grid item className={pathname === `/bankUpload/` ? AdjSidebarStyles.mdColumn : SidebarStyles.mdColumn}>
              <h3 className={fonts.h5}>{name}</h3>
              <p>{email}</p>
              <p>{formatString("(999) 999-9999", officePhone)}</p>
            </Grid>
          </Grid>
        </div>
      )}
      {partnerLogo ? (
        <>
          <div className={(isPH || isOT || isOcalaTractors ? `${styles.phVendorLogoWrapper} ` : `${styles.vendorLogoWrapper} `) + styles.partnerLogoBg} style={{ backgroundColor: vendorColor }}>
            {partnerLogo.includes("ctt.png") ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src="https://cdn1.commercialtrucktrader.com/v1/media/cttol-logo-icon.png" alt="Partner Logo" style={{ height: 64, marginRight: 8 }} />
                <img src="https://cdn0.commercialtrucktrader.com/ctt-logo-white-text-large.png" alt="Partner Logo" style={{ height: 64 }} />
              </div>
            ) : (
              <img
                src={
                  partnerLogo && partnerLogo.includes("/")
                    ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${partnerLogo}`
                    : `https://credit-app-images.s3-us-west-2.amazonaws.com/${partnerLogo}`
                }
                alt="Partner Logo"
              />
            )}
          </div>
          <div className={isPH || isOT || isOcalaTractors ? styles.phVendorLogoWrapper : styles.vendorLogoWrapper}>
            {props.vendorProfile.logo && (
              <img
                src={`https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${props.vendorProfile.logo}`}
                alt="Vendor Logo"
                className={styles.highwayLogo}
                style={{
                  maxHeight: "200px",
                  height: "auto",
                  marginRight: "20px",
                  maxWidth: "25%",
                  objectFit: "scale-down",
                }}
              />
            )}
            <div className={[styles.sslTextContainer, !props.vendorProfile.logo ? " " + classes.noVendorLogo : ""].join(" ")}>
              <h5 style={{ color: vendorColor }} className={[fonts.h5, styles.formSubheader].join(" ")}>
                {!partnerId ? (
                  <FormattedMessage id="modernFinanceProcess" defaultMessage={defaultMessage.modernFinanceProcess} />
                ) : (
                  <FormattedMessage id="prequalifyHeader" defaultMessage={defaultMessage.modernFinanceProcess} />
                )}
              </h5>
              {ocaForm === "Comprehensive v3" ? (
                <p className={fonts.pGrey}>
                  <FormattedMessage id="completeInfoBelow" defaultMessage={defaultMessage.completeInfoBelow} />
                </p>
              ) : (
                <p className={fonts.pGrey}>
                  <FormattedMessage id="completeInfoBelowAmount" defaultMessage={defaultMessage.completeInfoBelowAmount} />
                </p>
              )}
            </div>
          </div>
        </>
      ) : null}
      {logoToRender && !partnerLogo ? (
        <div className={isPH || isOT || isOcalaTractors ? styles.phVendorLogoWrapper : styles.vendorLogoWrapper}>
          <img
            src={logoToRender.includes("/") ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${logoToRender}` : `https://credit-app-images.s3-us-west-2.amazonaws.com/${logoToRender}`}
            alt="Vendor Logo"
          />
        </div>
      ) : null}
      {/* {props.vendorProfile.logo ? (
        <div className={classes.vendorProfileLogo}>
          <img 
            src={`https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${props.vendorProfile.logo}`}
            alt="Dealer Logo"
          />
        </div>
      ) : null} */}
      <Container>
        <div className={classes.formContainer} style={{ paddingTop: 16 }}>
          <hr className={styles.horizontalRule} style={{ borderColor: vendorColor, width: "100%" }} />
          {ocaForm === "Comprehensive v3" ? (
            <div>
              <LinearProgressWithLabel value={progressPerc} />
            </div>
          ) : !partnerId ? (
            <div className={classes.stepperContainer}>
              <Stepper activeStep={0} alternativeLabel>
                {stepLabels.map(label => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
          ) : null}

          {renderOCA()}
          <hr className={styles.horizontalRule} style={{ borderColor: vendorColor, width: "100%" }} />
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={6} lg={6} className={styles.sslLogoContainer}>
              <img src={sslLogo} alt="SSL Logo" className={styles.sslLogo} />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} className={styles.poweredByDCRLogoContainer}>
              <a href="http://dcr.ai/" target="_blank">
                <img src={logo} className={styles.poweredByDCRLogo} alt="Powered By Trnsact" />
              </a>
            </Grid>
          </Grid>
          <Grid container justify="space-between" alignItems="flex-start">
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <p className={[styles.footerText, fonts.pGrey, fonts.footerText].join(" ")} style={{ width: "100%", marginBottom: 32 }}>
                <FormattedMessage id="sslDisclaimer" defaultMessage={defaultMessage.sslDisclaimer} />
              </p>
              <p className={[fonts.pGrey, fonts.footerText].join(" ")}>
                <FormattedMessage id="fundingInquiryDisclaimer" defaultMessage={defaultMessage.fundingInquiryDisclaimer} />
              </p>
            </Grid>
            {isIframe && (
              <Grid item xs={12} sm={6} md={6} lg={6} style={{ margin: "16px 0 32px" }}>
                <Grid container direction="column" style={{ width: "fit-content", marginLeft: "auto" }}>
                  <Grid item justify="flex-end">
                    <h3 style={{ margin: "0 0 8px" }}>
                      <FormattedMessage id="needHelp" defaultMessage={defaultMessage.needHelp} />
                    </h3>
                    <p className={[styles.footerText, fonts.pGrey, fonts.footerText].join(" ")} style={{ width: "100%", margin: "0 0 16px" }}>
                      <FormattedMessage id="representativesHelp" defaultMessage={defaultMessage.representativesHelp} />
                    </p>
                  </Grid>
                  <Grid container direction="row" style={{ width: "fit-content" }}>
                    <div className={pathname === `/bankUpload/` ? AdjSidebarStyles.mdColumn : SidebarStyles.mdColumn} style={{ padding: 0 }} xs={12} sm={5} md={5} lg={5}>
                      <img
                        src={
                          mugshot && mugshot.includes("/")
                            ? `https://${config.S3_BUCKET_NAME}.s3-us-west-2.amazonaws.com/${mugshot}`
                            : "https://credit-app-images.s3-us-west-2.amazonaws.com/avatar-placeholder.png"
                        }
                        alt="agent"
                        className={pathname === `/bankUpload/` ? AdjSidebarStyles.photo : SidebarStyles.photo}
                        style={{ height: 100, width: 100, marginRight: 16 }}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", width: "fit-content", justifyContent: "center" }}>
                      {name && <p style={{ marginBottom: 4 }}>{name}</p>}
                      {email && (
                        <div style={{ display: "flex", alignItems: "center", fontSize: 14, marginBottom: 4 }}>
                          <label className={fonts.pGrey} style={{ marginRight: 4 }}>
                            Email:
                          </label>
                          <a href={email}>{email}</a>
                        </div>
                      )}
                      {officePhone && (
                        <div style={{ display: "flex", alignItems: "center", fontSize: 14, marginBottom: 4 }}>
                          <label className={fonts.pGrey} style={{ marginRight: 4 }}>
                            Phone:
                          </label>
                          <a href={officePhone}>{formatString("(999) 999-9999", officePhone)}</a>
                        </div>
                      )}
                      {availability && (
                        <div style={{ display: "flex", alignItems: "center", fontSize: 14, marginBottom: 4 }}>
                          {/* <label className={fonts.pGrey} style={{ marginRight: 4 }}>availability:</label> */}
                          <p className={fonts.pGrey} style={{ margin: 0 }}>
                            {availability}
                          </p>
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {/* {isPartner && (
              <span>
                <p className={classes.chatWithDealer} onClick={() => setOpenChat(true)}>
                  <FormattedMessage id="chatWithDealer" defaultMessage={defaultMessage.chatWithDealer} />
                </p>
                <ChatWithDealer open={openChat} setOpen={setOpenChat} />
              </span>
            )} */}
          </Grid>
        </div>
      </Container>
    </>
  );
};

export default CreditApplicationFormWrapper;

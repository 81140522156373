import axios from "axios";
import { apiURL } from "../config";

const queryPublicAccount = dynamicsAccountId => {
  return axios.post(apiURL, {
    operationName: null,
    variables: {},
    query: `{
  publicAccount(dynamicsId: "${dynamicsAccountId}") {
    name
    vendorProfile {
      id
      dynamicsId
      paymentSelectorAutomatic
      industryGroupType
      logo
      styles
      ocaForm
      disclosureLanguage
      ownershipRequirement
      dlRequired
      dlUploadRequired
      dcrDisclosureLanguage
      dcrDisclosureTerms
      dcrDisclosureLanguageSpanish
      dcrDisclosureTermsSpanish
      amountRequested
      altLanguageOca
      showConsumerCreditApp
      ocaDocUpload
      reqDocsLanguage
      showIndustryOptionset
      psChannel
      multiLocationManagement
       ocaTemplate {
          ocaTemplateId
          name
          jsonDefinition
          active
          jsonSettings
        }
    }
    primaryContact {
      fullName
      phoneNumber
      email
      mugshot
      availability
    }
    salesReps {
      fullName
      dynamicsContactId
      phoneNumber
      email
      availability
      mugshot
      title
    }
    locations {
      locationId
      locationName
      category
      accountDynamicsId
      logo
      ocaContactId
      colorSchema
      ocaContact {
        email
        firstName
        lastName
        fullName
        title
        phoneNumber
        vendorContactRole
        mugshot
        availability
        accountId
        dynamicsContactId
        id
        lastLogin
        invitationCode
        invitationDatetime
        status
      }
      contactIds
      contactsInfo {
        email
        fullName
        title
        phoneNumber
        vendorContactRole
        mugshot
        availability
        dynamicsContactId
      }
      locationParentId
      notificationAppReceipt
    }
  }
}
`,
  });
};

export default queryPublicAccount;

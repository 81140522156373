import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import styles from "./WizardFormHandler.module.css";
import Checkbox from "@material-ui/core/Checkbox";
import Modal from "@material-ui/core/Modal";
import ReactHtmlParser from "react-html-parser";
import { Typography } from "@material-ui/core";
import createDecorator from "final-form-focus";
import { getLocalStorageWithExpiry, markAsAlreadySubmitted, restoreSaveFormData, setLocalStorageWithExpiry } from "../utils/saveAndReturn";
import AutoSave from "./AutoSave";
import { FormattedMessage } from "react-intl";
import defaultMessage from "../components/Message";

const _ = require("lodash");

class WizardFormHandler extends Component {
  static Page = ({ children, mutators }) => {
    if (typeof children === "function") {
      return children(mutators);
    } else return children;
  };

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      values: props.initialValues || {},
      termsAccepted: false,
      showModal: false,
      formId: props.formId,
      windowClosePopup: true,
      // authorizationText: null,
      // termsAndConditions: null
    };
    this.setEntityType = props.setEntityType;
    this.isAPartner = props.isAPartner;
    this.setProgressPerc = props.progress;
    this.setOwner1Info = props.owner1Info;
    this.setOwner2Info = props.owner2Info;
    this.setAllOwnerInfo = props.allOwnerInfo;
    this.decorator = createDecorator();
    this.formId = props.formId;
    this.autoSave = false;
    this.setWindowClosePopup = true;
    this.docsUploadSetting = props.docsUploadSetting;
    this.onPageChange = props.onPageChange ? props.onPageChange : () => {};
    this.afterValidate = props.afterValidate;

    if (this.formId) {
      restoreSaveFormData(this.props.initialValues, this.formId);
      //Send up-to-date initial values, either mocked or saved from previous session
      //to be updated on parent component
      props.doUpdateState(this.props.initialValues);
    }
  }

  // componentDidUpdate(prevProps) {
  //   // Typical usage (don't forget to compare props):
  //   if (this.props.isConsumerCreditApplication) {
  //     //this.skipToOwnerShip();
  //   }
  // }

  // skipToOwnerShip = () => {
  //   if (this.state.page == 2) {
  //     this.next(this.state.values);
  //   }
  // };

  handleWindowClose = e => {
    if (this.state.windowClosePopup) {
      e.returnValue = "Are you sure you want to leave?";
    }
  };
  componentWillReceiveProps() {
    this.formId = this.props.formId;
    if (this.props.enableAutoSaveDraft && this.formId) {
      this.autoSave = true;
    }
    window.addEventListener("beforeunload", this.handleWindowClose);
  }

  next = values => {
    const pageChangeNum = Math.min(this.state.page + 1, this.props.children.length - 1);

    this.onPageChange(pageChangeNum, this.isAPartner, "nextPage", true, this.props.children.length - 1);

    document.body.scrollTop = document.documentElement.scrollTop = 0;

    this.setState(state => {
      return {
        page: Math.min(state.page + 1, this.props.children.length - 1),
        values,
      };
    });

    if (this.props.ocaForm === "Comprehensive v3") {
      if (this.props.industryGroupType === "CAT") {
        this.setProgressPerc(Math.round(Math.min(((this.state.page + 1) / (this.props.children.length - 4)) * 100)));
      } else {
        this.setProgressPerc(Math.round(Math.min(((this.state.page + 1) / (this.props.children.length - 4)) * 100)));
      }
      if (this.props.industryGroupType === "generic") {
        this.setProgressPerc(Math.round(Math.min(((this.state.page + 1) / (this.props.children.length - 6)) * 100)));
      }
    }

    if (this.props.isConsumerCreditApplication) {
      this.setProgressPerc(Math.round(Math.min(((this.state.page + 7) / (this.props.children.length - 1)) * 100)));
    }
  };

  previous = () => {
    this.setState(state => {
      const pageChangeNum = Math.max(state.page - 1, 0);
      this.onPageChange(pageChangeNum, this.isAPartner, "previousPage", false, this.props.children.length - 1);
      return {
        page: Math.max(state.page - 1, 0),
      };
    });

    if (this.state.termsAccepted === true) {
      this.setState({ termsAccepted: false });
    }
    if (this.props.ocaForm === "Comprehensive v3") {
      if (this.props.industryGroupType === "CAT") {
        this.setProgressPerc(Math.round(Math.min(((this.state.page - 1) / (this.props.children.length - 4)) * 100)));
      } else {
        this.setProgressPerc(Math.round(Math.min(((this.state.page - 1) / (this.props.children.length - 1)) * 100)));
      }
    }
  };

  handleSaveDraft = () => {
    const { onSaveDraft } = this.props;
    //filter info
    const formDraft = getLocalStorageWithExpiry(this.formId);
    const minimunData = ["email", "firstName", "lastName", "phone"];

    const result = minimunData
      .map(field => {
        if (_.isEmpty(formDraft[field])) {
          return false;
        }
        return true;
      })
      .every(v => v === true);

    if (result) {
      const payload = { ...formDraft, formId: this.formId };
      //execute savedraft
      onSaveDraft(payload);
    }
  };

  validate = values => {
    if (this.formId && this.autoSave) {
      const store = _.cloneDeep(values);
      store.owners = store.owners.map(function (owner) {
        return { ...owner, ssn: "" };
      });
      this.afterValidate(store);
      setLocalStorageWithExpiry(store, this.formId);
    }

    const activePage = React.Children.toArray(this.props.children)[this.state.page];

    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = values => {
    const { children, onSubmit } = this.props;
    const { page } = this.state;
    const { docsUploadSetting } = this.props;

    var isLastPage = page === React.Children.count(children) - 1;

    if (this.props.industryGroupType === "trucking" && this.props.ocaForm === "Simple v2") {
      if (values.legalStructure === "803370001" || values.legalStructure === "803370002" || values.legalStructure === "803370003" || values.legalStructure === "100000000") {
        isLastPage = page === React.Children.count(children) - 3;
      }
    }

    if (this.props.ocaForm === "Standard v3") {
      if (docsUploadSetting === "enabled") {
        isLastPage = page === React.Children.count(children) - 8;
      } else {
        isLastPage = page === React.Children.count(children) - 9;
      }
    }
    if (this.props.ocaForm === "Comprehensive v3") {
      if (docsUploadSetting === "enabled") {
        isLastPage = page === React.Children.count(children) - 7;
      } else {
        isLastPage = page === React.Children.count(children) - 8;
      }
    }
    if (this.props.industryGroupType === "CAT") {
      if (docsUploadSetting === "enabled") {
        isLastPage = page === React.Children.count(children) - 5;
      } else {
        isLastPage = page === React.Children.count(children) - 6;
      }
    }
    if ((this.props.industryGroupType === "generic" || this.props.industryGroupType === "medical") && (this.props.ocaForm === "Standard v3" || this.props.ocaForm === "Simple v3")) {
      if (docsUploadSetting === "enabled") {
        isLastPage = page === React.Children.count(children) - 9;
      } else {
        isLastPage = page === React.Children.count(children) - 10;
      }
    }
    if (this.props.industryGroupType === "generic" && this.props.ocaForm === "Comprehensive v3") {
      if (docsUploadSetting === "enabled") {
        isLastPage = page === React.Children.count(children) - 8;
      } else {
        isLastPage = page === React.Children.count(children) - 9;
      }
    }
    if (this.props.isConsumerCreditApplication) {
      if (docsUploadSetting === "enabled") {
        isLastPage = page === React.Children.count(children) - 11;
        if (this.props.consumerType === "comprehensive") {
          isLastPage = page === React.Children.count(children) - 9;
        }
      } else {
        isLastPage = page === React.Children.count(children) - 12;
        if (this.props.consumerType === "comprehensive") {
          isLastPage = page === React.Children.count(children) - 10;
        }
      }
    }
    if (this.props.ocaForm === "Standard v3" && this.props.industryGroupType === "trucking_2") {
      if (docsUploadSetting === "enabled") {
        isLastPage = page === React.Children.count(children) - 7;
      } else {
        isLastPage = page === React.Children.count(children) - 8;
      }
    }

    if (isLastPage) {
      this.setWindowClosePopup = false;
      this.setState(state => ({
        ...state,
        windowClosePopup: false,
      }));
      //disable now the auto save upon form submit
      window.removeEventListener("beforeunload", this.handleWindowClose);

      markAsAlreadySubmitted(this.formId);
      return onSubmit(values);
    } else {
      this.next(values);
      if (values.legalStructure) {
        this.setEntityType(values.legalStructure);
      }
      if (this.props.ocaForm === "Comprehensive v3" || this.props.ocaForm === "Standard v3" || this.props.ocaForm === "Simple v3") {
        if (values.owners[0]) {
          this.setOwner1Info(values.owners[0]);
          this.setAllOwnerInfo([values.owners[0]]);
        }
        if (values.owners[1]) {
          this.setOwner2Info(values.owners[1]);
          this.setAllOwnerInfo([values.owners[0], values.owners[1]]);
        }
        if (values.owners[0] && values.owners[1]) {
          this.setOwner2Info(values.owners[1]);
          this.setAllOwnerInfo([values.owners[0], values.owners[1]]);
        }
      }
    }
  };

  handleOpen = () => {
    this.setState({ showModal: true });
  };
  handleClose = () => {
    this.setState({ showModal: false });
  };

  handleAcceptedTerms = () => {
    if (this.state.termsAccepted == true) {
      this.setState({ termsAccepted: false });
    } else {
      this.setState({ termsAccepted: true });
    }
  };

  render() {
    const { children } = this.props;
    const { page, values } = this.state;
    const { docsUploadSetting } = this.props;
    const activePage = React.Children.toArray(children)[page];

    var isLastPage = page === React.Children.count(children) - 1;

    if (this.props.industryGroupType === "trucking" && this.props.ocaForm === "Simple v2") {
      if (values.legalStructure === "803370001" || values.legalStructure === "803370002" || values.legalStructure === "803370003" || values.legalStructure === "100000000") {
        isLastPage = page === React.Children.count(children) - 3;
      }
    }
    if (this.props.ocaForm === "Standard v3") {
      if (docsUploadSetting === "enabled") {
        isLastPage = page === React.Children.count(children) - 8;
      } else {
        isLastPage = page === React.Children.count(children) - 9;
      }
    }
    if (this.props.ocaForm === "Comprehensive v3") {
      if (docsUploadSetting === "enabled") {
        isLastPage = page === React.Children.count(children) - 7;
      } else {
        isLastPage = page === React.Children.count(children) - 8;
      }
    }
    if (this.props.industryGroupType === "CAT") {
      if (docsUploadSetting === "enabled") {
        isLastPage = page === React.Children.count(children) - 5;
      } else {
        isLastPage = page === React.Children.count(children) - 6;
      }
    }
    if ((this.props.industryGroupType === "generic" || this.props.industryGroupType === "medical") && (this.props.ocaForm === "Standard v3" || this.props.ocaForm === "Simple v3")) {
      if (docsUploadSetting === "enabled") {
        isLastPage = page === React.Children.count(children) - 9;
      } else {
        isLastPage = page === React.Children.count(children) - 10;
      }
    }
    if (this.props.industryGroupType === "generic" && this.props.ocaForm === "Comprehensive v3") {
      if (docsUploadSetting === "enabled") {
        isLastPage = page === React.Children.count(children) - 8;
      } else {
        isLastPage = page === React.Children.count(children) - 9;
      }
    }
    if (this.props.isConsumerCreditApplication) {
      if (docsUploadSetting === "enabled") {
        isLastPage = page === React.Children.count(children) - 11;
        if (this.props.consumerType === "comprehensive") {
          isLastPage = page === React.Children.count(children) - 9;
        }
      } else {
        isLastPage = page === React.Children.count(children) - 12;
        if (this.props.consumerType === "comprehensive") {
          isLastPage = page === React.Children.count(children) - 10;
        }
      }
    }
    if (this.props.ocaForm === "Standard v3" && this.props.industryGroupType === "trucking_2") {
      if (docsUploadSetting === "enabled") {
        isLastPage = page === React.Children.count(children) - 7;
      } else {
        isLastPage = page === React.Children.count(children) - 8;
      }
    }

    return (
      <Form
        initialValues={values}
        validate={this.validate}
        decorators={[this.decorator]}
        onSubmit={this.handleSubmit}
        mutators={{
          ...arrayMutators,
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}>
        {({ handleSubmit, submitting, value, form: { mutators } }) => {
          return (
            <form onSubmit={handleSubmit} id="ocaFormIdentifier">
              {this.autoSave ? <AutoSave debounce={1200} save={this.handleSaveDraft} /> : null}

              {isLastPage && (
                <>
                  <Typography align="center" variant="h6" style={{ fontFamily: "Source Sans Pro, sans-serif" }}>
                    <FormattedMessage id="authAndAcceptance" defaultMessage={defaultMessage.authAndAcceptance} />
                  </Typography>
                  {!!this.props.dealerDisclosureLanguage && !this.props.isConsumerCreditApplication ? (
                    <>
                      <div style={{ width: "100%" }}>
                        <WaiverComponent text={this.props.dealerDisclosureLanguage} />
                      </div>
                      <div styles={{ display: "inline-block" }}>
                        <Checkbox style={{ display: "inline-block" }} label="Checkbox Label" color="primary" name="checkbox" value="control" onClick={() => this.handleAcceptedTerms()} />
                        <div style={{ display: "inline-block" }}>
                          <FormattedMessage id="iAccept" defaultMessage={defaultMessage.iAccept} />{" "}
                          <span style={{ textDecoration: "underline" }} onClick={() => this.handleOpen()}>
                            <FormattedMessage id="termsAndConditions" defaultMessage={defaultMessage.termsAndConditions} />
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                  {this.props.isConsumerCreditApplication ? (
                    <>
                      <div style={{ width: "100%" }}>
                        <WaiverComponent text={this.props.consumerDisclosureLanguage} />
                      </div>
                      <div styles={{ display: "inline-block" }}>
                        <Checkbox style={{ display: "inline-block" }} label="Checkbox Label" color="primary" name="checkbox" value="control" onClick={() => this.handleAcceptedTerms()} />
                        <div style={{ display: "inline-block" }}>
                          <FormattedMessage id="iAccept" defaultMessage={defaultMessage.iAccept} />{" "}
                          <span style={{ textDecoration: "underline" }} onClick={() => this.handleOpen()}>
                            <FormattedMessage id="termsAndConditions" defaultMessage={defaultMessage.termsAndConditions} />
                          </span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {<activePage.type {...activePage.props} mutators={mutators} />}
              {isLastPage && !this.props.dealerDisclosureLanguage ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginLeft: "-12px",
                  }}>
                  <Checkbox
                    data-test-id="accept-checkbox"
                    label="Checkbox Label"
                    color="primary"
                    name="checkbox"
                    value="control"
                    onClick={() => this.handleAcceptedTerms()}
                    className={styles.acceptFormCheckboxPadding}
                  />
                  <div style={{ display: "inline-block", paddingTop: "15px" }}>
                    <FormattedMessage id="iAccept" defaultMessage={defaultMessage.iAccept} />{" "}
                    <span style={{ textDecoration: "underline" }} onClick={() => this.handleOpen()}>
                      <FormattedMessage id="termsAndConditions" defaultMessage={defaultMessage.termsAndConditions} />
                    </span>
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className={styles.buttonsWrapper}>
                <div className={styles.buttonsBox}>
                  {page > 0 && (
                    <div className={`${styles.nextButton} ${styles.footerButton}`} style={{ marginRight: "40px" }}>
                      <Button type="button" onClick={this.previous} variant="outlined" color="primary" style={{ padding: "8px 40px", width: "100%" }}>
                        <FormattedMessage id="previous" defaultMessage={defaultMessage.previous} />
                      </Button>
                    </div>
                  )}
                  {!isLastPage && (
                    <div className={`${styles.nextButton} ${styles.footerButton}`}>
                      <Button type="submit" variant="contained" color="primary" style={{ padding: "8px 40px" }}>
                        {" "}
                        <FormattedMessage id="next" defaultMessage={defaultMessage.next} />
                      </Button>
                    </div>
                  )}
                  {isLastPage && this.state.termsAccepted && (
                    <div className={styles.footerButton}>
                      <Button type="submit" disabled={this.props.submitting} variant="contained" color="primary" style={{ padding: "8px 40px", width: "100%" }}>
                        {this.props.submitting ? (
                          <FormattedMessage id="submitting" defaultMessage={defaultMessage.submitting} />
                        ) : (
                          <FormattedMessage id="submit" defaultMessage={defaultMessage.submit} />
                        )}
                      </Button>
                    </div>
                  )}
                  {isLastPage && !this.state.termsAccepted && (
                    <div className={styles.footerButton}>
                      <Button type="submit" disabled variant="contained" color="primary" style={{ padding: "8px 40px", width: "100%" }}>
                        <FormattedMessage id="submit" defaultMessage={defaultMessage.submit} />
                      </Button>
                    </div>
                  )}
                  <Modal
                    open={this.state.showModal}
                    onClose={this.handleClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    // disableScrollLock={true}
                  >
                    <div className={styles.modalCard}>
                      <div style={{ justifyContent: "left" }}>
                        <FormattedMessage id="termsAndConditions1" defaultMessage={defaultMessage.termsAndConditions1} />{" "}
                        <a title="Privacy and Security" href="https://www.dealercreditresources.com/privacy/" target="_blank">
                          https://www.dealercreditresources.com/privacy/
                        </a>{" "}
                        <FormattedMessage id="termsAndConditions2" defaultMessage={defaultMessage.termsAndConditions2} />
                      </div>
                      {this.props.isConsumerCreditApplication ? (
                        <div>
                          <br />
                          Additionally, by signing you agree to the following statement: <br />
                          <br />
                          I authorize Trnsact and/or Dealer, as my agent, to: (i) submit information from this Application to The Lenders on my behalf; (ii) certify to The Lenders the accuracy of the
                          information; and (iii) convey to The Lenders my consent to any policies, notices, agreements or other terms disclosed by The Lenders to applicants as part of The Lenders’
                          application process. I have read this Application and everything stated in it is true to the best of my ability. I authorize The Lenders to check my credit, employment
                          history, or any other information, and to report such information, and its credit experience with me, to others. I am at least 18 years of age. Alimony, child support or
                          separate maintenance income need not be revealed if you do not wish to have it considered as a basis for repaying this obligation.
                          <br />
                          I hereby certify that the property purchased pursuant to this application is for my personal and/or business use; that I am fully responsible for making all payments for such
                          property; that such property will be in my possession or under my control until the amount financed and all finance charges have been paid in full; and that I am not
                          purchasing any property financed through The Lenders for the benefit or use of another without the prior written approval of The Lenders.
                          <br />
                          IMPORTANT INFORMATION ABOUT ACCOUNT OPENING PROCEDURES: Federal law requires all financial institutions to obtain, verify, and record information that identifies each person
                          who requests to open an account prior to account opening.
                          <br />
                          WHAT THIS MEANS TO YOU: When you apply for credit, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also
                          ask to see your driver's license or other identifying documents. Failure to provide the required information may result in denial of your request to open an account.
                        </div>
                      ) : null}
                      <br />
                      {<WaiverComponent text={this.props.dealerTermsConditions} />}
                      <br />
                      <Button color="primary" onClick={this.handleClose}>
                        <FormattedMessage id="close" defaultMessage={defaultMessage.close} />
                      </Button>
                      <br />
                    </div>
                  </Modal>
                </div>
              </div>
            </form>
          );
        }}
      </Form>
    );
  }
}

const WaiverComponent = ({ text }) => {
  return <>{ReactHtmlParser(text)}</>;
};

// const rawApplicationNotice = `
// <br/>
// <div>Application is hereby made to Quinn Company, Quinn Power Systems, Quinn Rental Services, Quinn Truck Centers, Quinn Lift, Inc., Quinn Used Parts (for the purposes of this document collectively referred to as QUINN) for open account credit and/or equipment financing, and the following is submitted in consideration thereof, for the confidential use of QUINN and Caterpillar Financial Services Corporation.</div>
// `

// const rawAcceptance = `
// <br/>
// <div>I certify that all statements in this application are true and complete and made for the purpose of obtaining credit from QUINN and CATERPILLAR FINANCIAL SERVICES CORPORATION for the business listed above. I certify that the extension of credit is not for personal, family, or household purposes. I authorize QUINN and CATERPILLAR FINANCIAL SERVICES CORPORATION to investigate my credit which may include, but not limited to, investigating the references herein listed, to obtain credit bureau reports on the business and principals listed above, to obtain from banks and other creditors of mine credit and financial information, or to investigate statements and other data obtained from me pertaining to my credit and financial responsibility. I authorize such creditors to furnish such information to QUINN and CATERPILLAR FINANCIAL SERVICES CORPORATION. I understand and agree that QUINN and CATERPILLAR FINANCIAL SERVICES CORPORATION may furnish such information to any party to whom QUINN and CATERPILLAR FINANCIAL SERVICES CORPORATION may refer my request for credit.</div>
// <br/>
// <div>TERMS ARE CASH UNLESS CREDIT IS APPROVED. With CREDIT APPROVAL, terms are: Parts and Service – thirty (30) days from invoice date; Equipment Sales – ten (10) days from invoice date; Rentals – thirty (30) days from invoice date. A FINANCE CHARGE OF 1.5% per month (18% PER ANNUM) will be charged on the past due balance. The past due balance represents all charges remaining unpaid on the closing date of the month following invoice date. In the event, of default in the payment of any Amount due, and if the account is placed in the hands of any agency or attorney for collection or legal action, the purchaser agrees to pay finance charges equal to the cost of collection (as permitted by laws governing these transactions). Acceptance by customer of the parts, service or equipment, listed above is the customer’s agreement to be bound by the credit and collection terms set forth above. A $25.00 service charge applies to each dishonored check returned.</div>
// <br/>
// <div>I/We hereby agree that in the event of default of payment of any amount due, or in the event of any controversy or dispute arising out of the extension of credit or applicant’s payment for services and/or equipment supplied to applicant by QUINN, that QUINN shall be entitled to recover from the applicant their expenses including, without limitation, attorney’s fees and actual costs incurred.</div>
// <br/>
// <div>I/We, the undersigned, signing on behalf of a corporation, limited liability company or any other entity, hereby agrees to be personally, jointly and severally, liable to all such charges incurred on the account of such entity.</div>
// <br/>
// <div>I/We, the undersigned, agree that in the event suit is brought to collect this account that it is subject to the jurisdiction of the state and federal courts within California and that the venue of such suit shall lie in Los Angeles County or Fresno County.</div>
// <br/>
// `

// const rawDisclosure = `
// <div>QUINN COMPANY:</div>
// <div>In consideration of hiring of the vehicles and/or equipment (herein "the equipment") described on the front of this contract it is agreed as follows:</div>
// <div>1.	DEPOSIT. Customer acknowledges that one of the purposes and intent of the deposit is to secure and guarantee complete performance of customer's obligation under the contract.</div>
// <br/>
// <div>2.	WARNING. The use of false or fictitious identification to obtain the equipment or the failure to return the equipment upon the expiration of this contract may be considered a theft, resulting in criminal prosecution. Your attention is directed to California Penal Code Section 484, California Penal Code Section 476A (relating to bad checks) and Vehicle Code Section 10855 (relating to failure to return leased or rented vehicles upon contract termination). For purposes of Penal Code Section 484 and Vehicle Code Section</div>
// 10855, expiration date shall mean, "return date”</div>
// <br/>
// <div>3.	POSSESSION/TITLE. A customer's right to possession of the equipment terminates on the expiration date indicated on the front of this contract. Retention of Possession after this date constitutes a material breach of this agreement. Time is of the essence of this agreement. Any extension of this agreement must be agreed upon in writing. Title to the equipment is and shall remain Quinn. If the equipment is not returned and/or levied upon for any reason whatsoever, Quinn may retake said items without further notice or legal process and use whatever force or action that is reasonably necessary to do so. Customer hereby agrees to indemnify, defend and hold Quinn harmless from any and all claims and costs arising from such retaking including reasonable attorney's fees. If equipment is levied upon, customer shall notify Quinn immediately.</div>
// <br/>
// <div>4.	RECEIPT/INSPECTION OF EQUIPMENT. Customer hires the equipment on an "as is" basis.  Customer acknowledges that he has personally inspected the equipment prior to its leaving Quinn (regardless of point of delivery) and finds it suitable for his needs. Customer acknowledges receipt of all items listed in this agreement in good working order and repair and that he understands its proper operation an use without further instructions rega ding operation and use from Quinn. Customer acknowledges that he has had an opportunity to inspect all hitches, bolts, safety chains, hauling tongues and other devices and materials used to connect the equipment to customer's vehicle, if any. Customer declares that he has received the equipment in a secure and operative condition.</div>
// <br/>
// <div>5.	SOLVENCY. Customer represents to Quinn that he is not insolvent and should he become insolvent, that, he will return all equipment to Quinn immediately.</div>
// <br/>
// <div>6.	RENTAL PERIOD/RATE/PAYMENT. Rental period is for a maximum of twenty-four (24) hours unless a longer term is specified in the "expiration date". Rental charges begin immediately upon delivery of  the equipment to the location directed by the customer or upon equipment leaving the premises, whichever happens first.  Rental charges end upon return of the equipment to Quinn in an acceptable condition. If the equipment is returned prior to the end of the minimum rental period, the rental due shall be the entire minimum rental period. Quinn may terminate rental at any time and retake the equipment without further notice in case of violation by customer of any terms or conditions of this agreement. Customer agrees to pay any collection costs and attorney's fees incurred in collection of this account or any di pute arising under this agreement. Rental rates are based upon single shift usage (8 hours per day, 5 days per week). If customer makes greater use of the equipment, it is agreed that the additional usage will be
// charged. A late payment charge of 1.5% (annual percentage rate of 18 ) will be charged on past due amounts. The person signing this agreement for Customer warrants that he also has all nece sary
// authority to bind Customer to this agreement and her by does so. The person signing this agreement for Customer agrees that he is jointly and severally liable with Customer for all charges and other liabilities arising under this agreement.</div>
// <br/>
// <div>7.	ORDINARY WEAR AND TEAR. "Ordinary wear and tear" shall mean only the normal deterioration of the equipment caused by ordinary, reasonable and proper use of the equipment on a one-shift basis.</div>
// Damage which is not "ordinary ear and tear" includes but is not limited to: damage resulting from lack of fuel or lubrication, failure to maintain proper oil, water, hydraulic or air pressure levels, damage due to
// overturning, overloading or exceeding rated capacities, improper use, abuse, lack of cleaning, or tire damages. Customer shall be responsible for all damage not caused from ordinary wear and tear.</div>
// <br/>
// <div>8.	COMPLIANCE WITH LAWS/USE OF EQUIPMENT. Customer agrees not to use or allow anyone to use the equipment for any illegal purpose or in any illegal manner. Customer acknowledges that Quinn  has no physical control over the use of the equipment. Customer agrees at his sole cost and expense to comply with all municipal, county, state and federal laws, ordinances and regulations (incl  ding  O.S.H.A.) that may apply to the use of the equipment during the rental period. Customer further agrees to pay all licenses, fees, permits or taxes arising from his use of the equipment, including any subsequently determined to be due as a result of an audit. Customer shall not allow any person who is not qualified to operate the equipment or use the equipment. Customer shall not allow any person t  use  or operate the equipment when it is in need of repair or when it is in an unsafe condition or situation; modify, misuse, harm or abuse the equipment; permit any repairs to the equipment without Quinn written permission, or, allow lien to be placed upon the equipment. Customer agrees to check filters, oil, fluid levels, and air pressure, clean and visually inspect the equipment at least daily and to discontinue use and immediately notify Quinn when equipment is found to need repair or maintenance. Customer acknowledges that Quinn has no responsibility to inspect the equipment while it is in customer's possession. If the equipment becomes unsafe or requires repair, customer shall discontinue using it and notify Quinn immediately at the phone number listed on the face of this contract.</div>
// <br/>
// <div>9.	RETURN OF EQUIPMENT. Customer agrees to return the equipment to Quinn during regular business hours upon "expiration date" in as good condition as when received, ordinary wear and tear accepted.</div>
// <br/>
// <div>10.	DISCLAIMER OF WARRANTIES. Quinn makes no warranty of merchantability or fitness for any particular use or purpose, either expressed or implied. There is no warranty or representation that the equipment is fit for customer's particular intended use, or that it is free of latent defects. Quinn shall not be responsible to customer or any third party for any loss, damage or injury caused by, resulting from, or in any way attributable to the operation of, use of, or any failure of the equipment. Quinn shall not be responsible for any defect or failure unknown to Quinn. Customer sole remedy for any failure of or def ct in the equipment shall be termination of the rental charges at the time of failure provided that customer notifies Quinn immediately of such failure and returns the equipment to Quinn within twenty-four (24) hours  of such failure.</div>
// <br/>
// <div>11.	IF Fire, Theft, Vandalism waiver has been REJECTED on this agreement then this paragraph applies. Customer shall be responsible for, and promptly notify Quinn of any loss or damage to or destruction of the equipment, and customer agrees to reimburse Quinn immediately upon demand for the full value of any such loss, damage or destruction, together with interest at the highest rate permitted by law until said sum is paid. Customer shall insure the equipment against all risk of damage loss or destruction o the equipment, regardless of the cause. See paragraph titled “Insurance".</div>
// <div>>>> IF Fire, Theft, Vandalism waiver has been ACCEPTED on this agreement, then this paragraph applies. Customer shall be responsible for and promptly shall notify Quinn of any loss or damage to, or destruction of the equipment. Quinn waives any claim against customer for direct physical loss of or damage to the equipment except as follows:</div>
// <div>(a)	All damage or loss resulting from use of the equipment in violation of any provision of this agreement, violation of any law, ordinance or regulation.</div>
// <div>(b)	Damage or loss as a result of overloading or exceeding the rated capacity of the equipment.</div>
// <div>(c)	Damage or loss caused by negligence or willful neglect of customer.</div>
// <div>(d)	Damage to tires, tubes and wheels caused by blowouts, bruises, cuts and other causes inherent in the use of the equipment.</div>
// <div>(e)	Damage or loss resulting from misuse, abuse, failure to maintain cleanliness, proper oil, fuel, hydraulic, coolant or pressure levels, lack of lubrication or other normal servicing of equipment.</div>
// <div>(f)	Any engine or drive train damage, which results from use of improper fuels, fluids or lubricants.</div>
// <div>(g)	Disappearance of equipment or theft not documented with the applicable public authorities (such as by police report), with a copy of the same promptly supplied to Quinn, and any other failure of customer to promptly notify Quinn of any damage or loss; and to cooperate fully with the Quinn in supplying all information necessary to document said claim.</div>
// <div>(h)	If customer has insurance covering all or any part of the loss or damage to which this Fire, Theft, Vandalism waiver relates, this Fire, Theft, Vandalism waiver shall be deemed secondary to such insurance and shall only apply to the extent that such damage is not covered by insur nce of customer. Customer further agrees to assign said claim and any and all proceeds from such insurance to Quinn.</div>
// <br/>
// <div>12.	INSURANCE. Customer, at customer's own expense, shall procure and at all times during the rental period shall maintain a policy or policies of insurance:</div>
// <div>(a)	Insuring customer and naming Quinn as an additional insured, against public liability and property damage in amounts satisfactory to Quinn.</div>
// <div>(b)	IF Fire, Theft, Vandalism waiver has been REJECTED on the face of th s agreement, insuring the equipment against all risk of loss or damage from any cause in an amount not less than its full replacement value.</div>
// <br/>
// <div>13.	PURCHASE ORDERS. The use of customer's purchase order number on this agreement is for customer's convenience and identification only. Absence of purchase order number shall not constitute grounds for non-payment.</div>
// <br/>
// <div>14.	LOCATION OF EQUIPMENT. Customer shall not move the equipment from the location at which Customer represented it was to be used without prior notification to Quinn.</div>
// <br/>
// <div>15.	RETAKING OF EQUIPMENT. If for any reason it becomes necessary for Quinn to retake the equipment, Quinn may retake without further notice or further legal process.</div>
// <br/>
// <div>16.	DEFAULT. Should customer in any way fail to observe or comply with any provision of this agreement, Quinn may, at its sole option, exercise any and all of the following remedies:</div>
// <div>(a)	Termination of this agreement;</div>
// <div>(b)	Retake the equipment;</div>
// <div>(c)	Declare any outstanding rent and charges due and payable and initiate legal process to recover the monies;</div>
// <div>(d)	Pursue any of the remedies available to Quinn (exercise of any remedy available to Quinn shall not constitute an election of remedies or a waiver of any additional remedies to which Quinn may be entitled)</div>
// <br/>
// <div>17.	ACCIDENTS, REPORTING AND INDEMNIFICATION. IN THE EVENT OF ANY ACCIDENT RESULTING IN PROPERTY DAMAGE OR BODILY INJURY ARISING FROM USE OF THE</div>
// EQUIPMENT WHILE IT IS IN CUSTOMER'S POSSESSION, CUSTOMER HEREBY EXPRESSLY AGREES TO ASSUME RESPONSIBILITY FOR HIMSELF, HIS OWN EMPLOYEES, AGENTS AND ASSIGNS NEGLIGENCE AND AGREES TO INDEMNIFY, DEFEND AND HOLD QUINN HARMLESS FROM ANY CLAIM OR ACTION ARISING THEREFROM, INCLUDING ANY COSTS AND ATTORNEY'S FEES INCURRED IN CONNECTION THEREWITH. CUSTOMER AGREES TO NOTIFY QUINN IMMEDIATELY IN CASE OF ANY ACCIDENT AND TO OBTAIN THE NAMES, ADDRESS, PHONE NUMBERS AND OTHER PERTINENT INFORMATION FROM ALL PARTIES INVOLVED AND ALL WITNESSES, AND TO PROMPTLY SUBMIT ALL APPLICABLE POLICE REPORTS.</div>
// <br/>
// <div>18.	NOTICE OF NON-WAIVER/SEVERABILITY. Any failure of Quinn to insist upon strict performance by customer as regards any provision of this agreement shall not be interpreted as a waiver of Quinn's right to demand strict compliance with all other provisions of this agreement against customer or any other person. The provisions of this agreement shall be severable so that the unenforceability, invalidity or waiver of any provision shall not affect any other provision.</div>
// <br/>
// <div>19.	JOB INFORMATION. Customer shall provide Quinn the correct information necessary to file a Preliminary Notice as provided for by the California Civil Code, Section 3097, 3098. Customer also grants Quinn permission to contact owners, prime contractors, financial institutions and any persons necessary to obtain this information. ( V4.7)</div>
// <br/>
// <div>20.	DISPUTED INVOICE(S). To dispute an invoice, you must contact your sales representative, credit coordinator, or in writing within 30 days of the invoice date. You must pay any undisputed portion of the invoice while your dispute is being investigated. Disputes which exceed 30 days of the invoice date are at the discretion of Quinn Company and can be denied and acted upon as a default of payment under the terms of the application.</div>
// `

export default WizardFormHandler;

import React, { useContext } from "react";
import styles from "./HeaderCard.module.css";
import fonts from "../../fonts.module.css";
import VendorContext from "../../vendorContext";
import { formatCurrency } from "../../utils/stringFormatters";
import Grid from "@material-ui/core/Grid";

const HeaderCard = ({ loanAmount, customerBusiness, type, maxApproved, textAmount }) => {
  const { color: vendorColor } = useContext(VendorContext);
  return (
    <>
      {!type ? (
        <div className={styles.card} style={{ backgroundColor: vendorColor }}>
          <Grid container>
            <Grid item xs={12} md={6} lg={6}>
              <div className={styles.flexColumn}>
                <p className={styles.labelFont}>Company Name</p>
                <p className={[fonts.h4, styles.bigInputs].join(" ")}>{customerBusiness}</p>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <div className={styles.flexColumn}>
                <p className={styles.labelFont}>{textAmount ? textAmount : "Loan Amount"}</p>
                <p className={[fonts.h4, styles.bigInputs].join(" ")}>{formatCurrency(loanAmount)}</p>
              </div>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className={styles.card} style={{ backgroundColor: vendorColor }}>
          <Grid container>
            <Grid item xs={12} md={maxApproved ? 6 : 12} lg={maxApproved ? 6 : 12}>
              <div className={styles.flexColumn}>
                <p className={styles.labelFont}>Requested Financing Amount</p>
                <p className={[fonts.h4, styles.bigInputs].join(" ")}>{formatCurrency(loanAmount)}</p>
              </div>
            </Grid>
            {maxApproved && (
              <Grid item xs={12} md={6} lg={6}>
                <div className={styles.flexColumn}>
                  <p className={styles.labelFont}>Max Approved Amount</p>
                  <p className={[fonts.h4, styles.bigInputs].join(" ")}>{formatCurrency(maxApproved.toFixed(2))}</p>
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </>
  );
};

export default HeaderCard;

import axios from "axios";
import { apiURL } from "../config";

const queryPartnerLink = async partnerDealerId => {
  return axios
    .post(apiURL, {
      operationName: null,
      variables: {},
      query: `{
  partnerLink(partnerDealerId: "${partnerDealerId}") {
    partnerLinkId
    dynamicsPartnerId
    name
    partnerAccountId
    dynamicsPartnerAccountId
    partnerProfileId
    dynamicsPartnerProfileId
    partnerContactId
    dynamicsPartnerContactId
    psChannel
      contact {
        fullName
        phoneNumber
        email
        mugshot
        availability
        id
      }
    dealerContact {
      fullName
      phoneNumber
      email
      mugshot
      availability
      id
    }
  }
}
`,
    })
    .then(async response => {
      const dynamicsId = response.data.data.partnerLink ? response.data.data.partnerLink.dynamicsPartnerAccountId : null;
      const publicAccountData = await axios.post(apiURL, {
        operationName: null,
        variables: {},
        query: `{
            publicAccount(dynamicsId: "${dynamicsId}") {
              name
              dynamicsAccountId
              vendorProfile {
                id
                paymentSelectorAutomatic
                industryGroupType
                logo
                styles
                ocaForm
                disclosureLanguage
                ownershipRequirement
                dlRequired
                dlUploadRequired
                dcrDisclosureLanguage
                dcrDisclosureTerms
                dcrDisclosureLanguageSpanish
                dcrDisclosureTermsSpanish
                amountRequested
                altLanguageOca
                showConsumerCreditApp
                ocaDocUpload
                reqDocsLanguage
                showIndustryOptionset
                
              }
              primaryContact {
                fullName
                phoneNumber
                email
                mugshot
                availability
              }
              salesReps {
                fullName
                dynamicsContactId
                phoneNumber
                email
                availability
                mugshot
                title
              }
            }
          }
          `,
      });
      return { ...response.data.data.partnerLink, ...publicAccountData };
    });
};

export default queryPartnerLink;
